

export const userData=[
    {
      "practitionerId": "840282c7-4246-44db-a1f6-d753c1913547",
      "data": {
        "userDetailData": {
          "userId": "840282c7-4246-44db-a1f6-d753c1913547",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Yj",
          "phoneNumber": "9717822298",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.974002",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.613458",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:24:47.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T09:07:24.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:25:58.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:23.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:26:12.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T08:04:52.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:30:00.000+0000"
            },
            {
              "topicName": "Image composition and layouts LL3",
              "issuedDate": "2022-03-29T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:30:00.000+0000"
            },
            {
              "topicName": "Feedback and next steps LL2",
              "issuedDate": "2022-03-08T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:30:00.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:30:00.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T07:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "912a7c4d-2482-4a7a-a506-3cd7c3d8b886",
      "data": {
        "userDetailData": {
          "userId": "912a7c4d-2482-4a7a-a506-3cd7c3d8b886",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Patel mukeshbhai",
          "phoneNumber": "7016250363",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:16.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:01:09.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c74c08a8-921e-433d-a1fd-917e9b1c4c34",
      "data": {
        "userDetailData": {
          "userId": "c74c08a8-921e-433d-a1fd-917e9b1c4c34",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Cherlapalle",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fc74c08a8-921e-433d-a1fd-917e9b1c4c34.jpg",
          "name": "Shiva",
          "phoneNumber": "9398057573",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "17.1017311",
          "userRole": ["TRAINEE"],
          "longitude": "79.2463456",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:05:28.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ad439a79-65f3-4629-9523-27ae55fae348",
      "data": {
        "userDetailData": {
          "userId": "ad439a79-65f3-4629-9523-27ae55fae348",
          "emailId": "",
          "country": "India",
          "state": "Tamil Nadu",
          "city": "Venkatakulam",
          "photo": "",
          "name": "Yalinikulendhran",
          "phoneNumber": "6379076154",
          "district": "Pudukkottai District",
          "programId": 27,
          "latitude": "10.3247315",
          "userRole": ["TRAINEE"],
          "longitude": "78.9265878",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "3 Folds Concepts",
              "issuedDate": "2020-09-26T06:34:17.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c7407356-dbe6-4e58-aada-90773bb4201c",
      "data": {
        "userDetailData": {
          "userId": "c7407356-dbe6-4e58-aada-90773bb4201c",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Himatnagar",
          "photo": "",
          "name": "Kanaiyalal Vankar",
          "phoneNumber": "9687316393",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.5968857",
          "userRole": ["TRAINEE"],
          "longitude": "72.9630378",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:00:04.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "eafa5769-776e-421f-bdce-03326a065f7e",
      "data": {
        "userDetailData": {
          "userId": "eafa5769-776e-421f-bdce-03326a065f7e",
          "emailId": "ashish@samerth.org",
          "country": "India",
          "state": "Gujarat",
          "city": "Gagodar",
          "photo": "",
          "name": "Ashish Mehta",
          "phoneNumber": "9825339841",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.409592",
          "userRole": ["TRAINEE"],
          "longitude": "70.81348179999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:39:12.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:30:59.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:46:51.000+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:16.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-08T07:48:14.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:39:06.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:18:08.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:05:07.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:34:22.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:36:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9b7cd3e3-5fdd-423f-8fb1-07790ab820fa",
      "data": {
        "userDetailData": {
          "userId": "9b7cd3e3-5fdd-423f-8fb1-07790ab820fa",
          "emailId": "",
          "country": "India",
          "state": "Odisha",
          "city": "Bargarh",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F9b7cd3e3-5fdd-423f-8fb1-07790ab820fa.jpg",
          "name": "Ashutosh",
          "phoneNumber": "8895239527",
          "district": "Bargarh District",
          "programId": 27,
          "latitude": "21.3462194",
          "userRole": ["TRAINEE"],
          "longitude": "83.6293407",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T10:15:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "90df11b4-79fd-41c0-8d83-b3ba0d76bbde",
      "data": {
        "userDetailData": {
          "userId": "90df11b4-79fd-41c0-8d83-b3ba0d76bbde",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Shaileshbhai",
          "phoneNumber": "9909600297",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T08:43:31.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "2fce8054-838b-4511-9de4-85c828516405",
      "data": {
        "userDetailData": {
          "userId": "2fce8054-838b-4511-9de4-85c828516405",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Nani Khakhar",
          "photo": "",
          "name": "Meena parmar",
          "phoneNumber": "7567143738",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8842835",
          "userRole": ["TRAINEE"],
          "longitude": "69.5192718",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T12:03:00.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "98427f84-0e6b-4c1e-9bc7-580049d9e3ad",
      "data": {
        "userDetailData": {
          "userId": "98427f84-0e6b-4c1e-9bc7-580049d9e3ad",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Gadhada",
          "photo": "",
          "name": "Zala Anilsinh",
          "phoneNumber": "7990260912",
          "district": "Bhavnagar District",
          "programId": 27,
          "latitude": "21.9681716",
          "userRole": ["TRAINEE"],
          "longitude": "71.57536259999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:20.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "a53273d5-0418-4e44-9160-d2747f67d825",
      "data": {
        "userDetailData": {
          "userId": "a53273d5-0418-4e44-9160-d2747f67d825",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vondh",
          "photo": "",
          "name": "Raj bharatbhai pandya",
          "phoneNumber": "8980223638",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.2995554",
          "userRole": ["TRAINEE"],
          "longitude": "70.4003217",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:30:12.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "de5fa352-ea4d-4bb8-8d72-9ad3dce253f5",
      "data": {
        "userDetailData": {
          "userId": "de5fa352-ea4d-4bb8-8d72-9ad3dce253f5",
          "emailId": "manupious@gmail.com",
          "country": "India",
          "state": "Kerala",
          "city": "Muthalamada 1",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fde5fa352-ea4d-4bb8-8d72-9ad3dce253f5.jpg",
          "name": "Manu Panjikaran",
          "phoneNumber": "7042733089",
          "district": "Palakkad District",
          "programId": 27,
          "latitude": "10.5617297",
          "userRole": ["TRAINEE"],
          "longitude": "76.8320351",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2021-04-05T10:41:01.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": ["Analyst"]
        }
      }
    },
    {
      "practitionerId": "db52891b-977c-429d-ad2c-9258b81a7ba4",
      "data": {
        "userDetailData": {
          "userId": "db52891b-977c-429d-ad2c-9258b81a7ba4",
          "emailId": "kishanchandore@yuvamitra.org",
          "country": "India",
          "state": "Maharashtra",
          "city": "Harsule",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2Fdb52891b-977c-429d-ad2c-9258b81a7ba4.jpg",
          "name": "Kishan ",
          "phoneNumber": "9767346346",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "19.8163698",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "73.9628086",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Midterm Measurement of Desilting(Jalsamruddhi)",
              "issuedDate": "2021-02-20T11:50:14.000+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:05.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:39:51.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-02T06:56:21.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:18:51.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:08:12.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:34:39.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-05T11:30:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-21T06:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-13T05:30:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-06T10:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "5bfd7f81-ec88-422a-9f71-fbc75f3ace63",
      "data": {
        "userDetailData": {
          "userId": "5bfd7f81-ec88-422a-9f71-fbc75f3ace63",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Kidiyanagar",
          "photo": "",
          "name": "Ravi somani",
          "phoneNumber": "6351514416",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.4784328",
          "userRole": ["TRAINEE"],
          "longitude": "70.77491549999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:47:53.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:31:59.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:49:34.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:07:30.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:37:03.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "94047112-9581-4ea9-a7b2-5532bd496584",
      "data": {
        "userDetailData": {
          "userId": "94047112-9581-4ea9-a7b2-5532bd496584",
          "emailId": "",
          "country": "India",
          "state": "Tamil Nadu",
          "city": "Pudukkottai",
          "photo": "",
          "name": "Prasanth",
          "phoneNumber": "8344478178",
          "district": "Pudukkottai District",
          "programId": 27,
          "latitude": "10.3832867",
          "userRole": ["TRAINEE"],
          "longitude": "78.800129",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "3 Folds Concepts",
              "issuedDate": "2020-09-26T06:32:45.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "a7c83a7f-e4bd-4fa8-9c2a-914c38352ec0",
      "data": {
        "userDetailData": {
          "userId": "a7c83a7f-e4bd-4fa8-9c2a-914c38352ec0",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Nandurbar",
          "photo": "",
          "name": "Tejashree Sadhana Balaso",
          "phoneNumber": "9767196944",
          "district": "Nandurbar District",
          "programId": 27,
          "latitude": "21.3595721",
          "userRole": ["TRAINEE"],
          "longitude": "74.2573982",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:23:48.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T06:57:55.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:38:15.000+0000"
            },
            {
              "topicName": "Feedback and next steps LL2",
              "issuedDate": "2022-03-08T06:49:15.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "3c872b16-ee10-4de9-a28b-992b5a11ce01",
      "data": {
        "userDetailData": {
          "userId": "3c872b16-ee10-4de9-a28b-992b5a11ce01",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Goswami nisha narendrabharathi",
          "phoneNumber": "7984480125",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.702044",
          "userRole": ["TRAINEE"],
          "longitude": "72.5388881",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:53:43.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "85bce1bf-2a8b-4862-8a13-80758d68c6e0",
      "data": {
        "userDetailData": {
          "userId": "85bce1bf-2a8b-4862-8a13-80758d68c6e0",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bidada",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F85bce1bf-2a8b-4862-8a13-80758d68c6e0.jpg",
          "name": "Sahdev",
          "phoneNumber": "9429815725",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "22.9024616",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "69.4724952",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo and Deployment Training for Trainers | ACT",
              "issuedDate": "2020-12-11T12:41:39.000+0000"
            },
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:23:45.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:15:47.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:30:00.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T08:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d3988f95-3458-49bf-bc97-a81ab0108569",
      "data": {
        "userDetailData": {
          "userId": "d3988f95-3458-49bf-bc97-a81ab0108569",
          "emailId": "subrat@fes.org.in",
          "country": "India",
          "state": "Gujarat",
          "city": "Anand",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fd3988f95-3458-49bf-bc97-a81ab0108569.jpg",
          "name": "Subrata Singh",
          "phoneNumber": "9879545013",
          "district": "Anand District",
          "programId": 27,
          "latitude": "22.5339364",
          "userRole": ["TRAINEE"],
          "longitude": "72.9868283",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-04-07T09:20:52.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:48:17+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": ["Plus"]
        }
      }
    },
    {
      "practitionerId": "321375b5-ae91-4d01-8378-5eb311e2ff02",
      "data": {
        "userDetailData": {
          "userId": "321375b5-ae91-4d01-8378-5eb311e2ff02",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Chalsa",
          "photo": "",
          "name": "Paramita Roy",
          "phoneNumber": "8906067022",
          "district": "Jalpaiguri District",
          "programId": 27,
          "latitude": "26.8822609",
          "userRole": ["TRAINEE"],
          "longitude": "88.79837119999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T10:07:50.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-04T10:07:52.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-11-04T12:41:42.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "44a1ef88-ba82-4c2e-bf2d-67d6f7b9e350",
      "data": {
        "userDetailData": {
          "userId": "44a1ef88-ba82-4c2e-bf2d-67d6f7b9e350",
          "emailId": "",
          "country": "India",
          "state": "Odisha",
          "city": "Bhubaneswar",
          "photo": "",
          "name": "Nirmal Chandra Mohanty ",
          "phoneNumber": "7326027082",
          "district": "Khordha District",
          "programId": 27,
          "latitude": "20.2568482",
          "userRole": ["TRAINEE"],
          "longitude": "85.82827429999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T10:17:17.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "267a7622-9c4b-4f7b-be36-c44170b402e7",
      "data": {
        "userDetailData": {
          "userId": "267a7622-9c4b-4f7b-be36-c44170b402e7",
          "emailId": "",
          "country": "India",
          "state": "Jharkhand",
          "city": "Phara Kusum",
          "photo": "",
          "name": "Jiten Malpahariya",
          "phoneNumber": "9905199117",
          "district": "Jamtara District",
          "programId": 27,
          "latitude": "23.9782445",
          "userRole": ["TRAINEE"],
          "longitude": "87.25312629999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Hands-on",
              "issuedDate": "2021-03-16T08:05:43.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c9e258a0-5300-4461-b505-2ac5ff98636e",
      "data": {
        "userDetailData": {
          "userId": "c9e258a0-5300-4461-b505-2ac5ff98636e",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Faradi",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fc9e258a0-5300-4461-b505-2ac5ff98636e.jpg",
          "name": "Savan",
          "phoneNumber": "9586092569",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "22.9405451",
          "userRole": ["TRAINEE"],
          "longitude": "69.512717",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:24:09.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:53:28.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:40:20.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6ded97db-dc70-49eb-8b39-bd50173538d6",
      "data": {
        "userDetailData": {
          "userId": "6ded97db-dc70-49eb-8b39-bd50173538d6",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Kalva Palle",
          "photo": "",
          "name": "Farida supervisor",
          "phoneNumber": "9963439478",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.9025636",
          "userRole": ["TRAINEE"],
          "longitude": "79.6110804",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:05:34.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "8866902d-32f0-4d36-a6d3-eeef2072405c",
      "data": {
        "userDetailData": {
          "userId": "8866902d-32f0-4d36-a6d3-eeef2072405c",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F8866902d-32f0-4d36-a6d3-eeef2072405c.jpg",
          "name": "Alpesh Patel ",
          "phoneNumber": "9909588414",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7016303",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "72.53949209999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:51:15.000+0000"
            },
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T09:58:51.000+0000"
            },
            {
              "topicName": "Wheat Crop Training",
              "issuedDate": "2020-12-31T10:15:02.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:38.000+0000"
            },
            {
              "topicName": "PDA - Hands on",
              "issuedDate": "2020-12-28T07:28:59.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:30:00.000+0000"
            },
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:00:00.000+0000"
            },
            {
              "topicName": "Wheat Crop Training",
              "issuedDate": "2020-12-31T10:30:00.000+0000"
            },
            {
              "topicName": "Wheat Crop Training",
              "issuedDate": "2020-12-31T09:30:00.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "941579b3-5b8d-43eb-88b1-80e87fa6a840",
      "data": {
        "userDetailData": {
          "userId": "941579b3-5b8d-43eb-88b1-80e87fa6a840",
          "emailId": "pritambiems@gmail.com",
          "country": "India",
          "state": "West Bengal",
          "city": "Kalimpong",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F941579b3-5b8d-43eb-88b1-80e87fa6a840.jpg",
          "name": "Pritam ghosh",
          "phoneNumber": "6295447404",
          "district": "Darjeeling District",
          "programId": 27,
          "latitude": "27.0593562",
          "userRole": ["TRAINEE"],
          "longitude": "88.46945350000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Zoom for Hubs & Spokes",
              "issuedDate": "2020-12-10T06:26:04.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-04T10:07:51.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-11-04T10:39:39.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T05:57:42.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6b1255b3-c3cf-48bd-aa73-b0dcffaf6951",
      "data": {
        "userDetailData": {
          "userId": "6b1255b3-c3cf-48bd-aa73-b0dcffaf6951",
          "emailId": "ranimpl@gmail.com",
          "country": "India",
          "state": "Andhra Pradesh",
          "city": "Madanapalle",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F6b1255b3-c3cf-48bd-aa73-b0dcffaf6951.jpg",
          "name": "Rani Reddy",
          "phoneNumber": "7338849150",
          "district": "Chittoor District",
          "programId": 27,
          "latitude": "13.5560496",
          "userRole": ["TRAINEE"],
          "longitude": "78.50100069999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-04-07T09:20:45.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:49:03+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "3b26d9ed-f52c-4a3d-960e-963c1225b9cc",
      "data": {
        "userDetailData": {
          "userId": "3b26d9ed-f52c-4a3d-960e-963c1225b9cc",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Rayan Moti",
          "photo": "",
          "name": "Hiralal miting",
          "phoneNumber": "9428080271",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8841134",
          "userRole": ["TRAINEE"],
          "longitude": "69.355231",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:27:22.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "98d93e00-4864-48aa-9f6c-06068ffc4074",
      "data": {
        "userDetailData": {
          "userId": "98d93e00-4864-48aa-9f6c-06068ffc4074",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Kharod",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F98d93e00-4864-48aa-9f6c-06068ffc4074.jpg",
          "name": "Desai Haresh kumar Ramesh Bhai ",
          "phoneNumber": "7575817775",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.6649433",
          "userRole": ["TRAINEE"],
          "longitude": "72.6830913",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:06.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "384f86d2-2fa2-4b51-8527-506c4e191ee8",
      "data": {
        "userDetailData": {
          "userId": "384f86d2-2fa2-4b51-8527-506c4e191ee8",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Kolmunthal Pahad",
          "photo": "",
          "name": "T Radha KMpally ",
          "phoneNumber": "9491661990",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.7124153",
          "userRole": ["TRAINEE"],
          "longitude": "78.9671806",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:05:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c64dca7e-f9df-453c-a6f1-7a57c142b6ab",
      "data": {
        "userDetailData": {
          "userId": "c64dca7e-f9df-453c-a6f1-7a57c142b6ab",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Miryalaguda",
          "photo": "",
          "name": "Shaik Saida begum",
          "phoneNumber": "9490690588",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.8817659",
          "userRole": ["TRAINEE"],
          "longitude": "79.572157",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:22:23.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "7b00fe4a-0677-4735-8674-870d3b9bfe95",
      "data": {
        "userDetailData": {
          "userId": "7b00fe4a-0677-4735-8674-870d3b9bfe95",
          "emailId": "",
          "country": "India",
          "state": "Tamil Nadu",
          "city": "Venkatakulam",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F7b00fe4a-0677-4735-8674-870d3b9bfe95.jpg",
          "name": "Chitra",
          "phoneNumber": "9751654593",
          "district": "Pudukkottai District",
          "programId": 27,
          "latitude": "10.3239989",
          "userRole": ["TRAINEE"],
          "longitude": "78.92599550000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "3 Folds Concepts",
              "issuedDate": "2020-09-26T06:29:40.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "c919ec69-7f36-4a98-a6e7-a7733764d137",
      "data": {
        "userDetailData": {
          "userId": "c919ec69-7f36-4a98-a6e7-a7733764d137",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Govindpar",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2Fc919ec69-7f36-4a98-a6e7-a7733764d137.jpg",
          "name": "Vaghela Ramesh B",
          "phoneNumber": "9265368414",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.4316846",
          "userRole": ["TRAINEE"],
          "longitude": "70.6395247",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:30:48.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:46:56.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:06:36.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:37:05.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9c782be5-e423-49f9-8b28-613ccc87fc3a",
      "data": {
        "userDetailData": {
          "userId": "9c782be5-e423-49f9-8b28-613ccc87fc3a",
          "emailId": "j.kumar@akdn.org",
          "country": "India",
          "state": "Bihar",
          "city": "Sujawalpur",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F9c782be5-e423-49f9-8b28-613ccc87fc3a.jpg",
          "name": "Jitendra Kumar ",
          "phoneNumber": "9430836384",
          "district": "Muzaffarpur District",
          "programId": 27,
          "latitude": "25.9655284",
          "userRole": ["TRAINEE"],
          "longitude": "85.5609008",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:25:53.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T06:57:58.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:24:13.000+0000"
            },
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:46:45.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:26:23.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T06:44:35.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:32:07.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9ca87890-0726-4909-9ebd-92fab6ce4a20",
      "data": {
        "userDetailData": {
          "userId": "9ca87890-0726-4909-9ebd-92fab6ce4a20",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Izra Nawas",
          "phoneNumber": "8111946177",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9715987",
          "userRole": ["TRAINEE"],
          "longitude": "77.5945627",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Virtual Interactions - Best Practices - Smitha Murthy",
              "issuedDate": "2020-10-14T06:59:31.000+0000"
            },
            {
              "topicName": "Virtual Training Format",
              "issuedDate": "2020-10-14T06:56:54.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-10-14T05:52:44.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-10-14T09:46:33.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-10-14T06:49:59.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-04T10:07:13.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "5bdb6895-9c24-4df3-afc3-bb1924171fea",
      "data": {
        "userDetailData": {
          "userId": "5bdb6895-9c24-4df3-afc3-bb1924171fea",
          "emailId": "dpmuckbp@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "Chikkaballapur",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F5bdb6895-9c24-4df3-afc3-bb1924171fea.jpg",
          "name": "Harish kumar",
          "phoneNumber": "9964142814",
          "district": "Chikballapur District",
          "programId": 27,
          "latitude": "13.4354985",
          "userRole": ["TRAINEE"],
          "longitude": "77.7315344",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ಅಟಲ್ ಭೂಜಲ್ ಯೋಜನೆಯ ಅವಲೋಕನ",
              "issuedDate": "2021-05-25T11:27:09.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "242a3167-2079-4477-a496-a5068c491541",
      "data": {
        "userDetailData": {
          "userId": "242a3167-2079-4477-a496-a5068c491541",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Nalgonda",
          "photo": "",
          "name": "Adavath parvathi",
          "phoneNumber": "7702962034",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "17.05069",
          "userRole": ["TRAINEE"],
          "longitude": "79.2675130000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:22:24.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "024316cc-7fe7-4f44-9ea8-c925726ed1bf",
      "data": {
        "userDetailData": {
          "userId": "024316cc-7fe7-4f44-9ea8-c925726ed1bf",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vadnagar",
          "photo": "",
          "name": "Ranchhodji Ajamelji Thakor",
          "phoneNumber": "8980409913",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7757259",
          "userRole": ["TRAINEE"],
          "longitude": "72.6165643",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:37.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:44.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "5a7898e7-5779-4b5b-b345-0e67cd8ea9c2",
      "data": {
        "userDetailData": {
          "userId": "5a7898e7-5779-4b5b-b345-0e67cd8ea9c2",
          "emailId": "nrusinghsutar@pradan.net",
          "country": "India",
          "state": "Jharkhand",
          "city": "Ranchi",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F5a7898e7-5779-4b5b-b345-0e67cd8ea9c2.jpg",
          "name": "Nrusingh Charan Sutar",
          "phoneNumber": "9608309593",
          "district": "Ranchi District",
          "programId": 27,
          "latitude": "23.349547",
          "userRole": ["TRAINEE"],
          "longitude": "85.335785",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:44:46.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:26:00.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:22.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "5db4d93e-0440-4889-aede-7c3540cc5831",
      "data": {
        "userDetailData": {
          "userId": "5db4d93e-0440-4889-aede-7c3540cc5831",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Ahmedabad",
          "photo": "",
          "name": "Gordhan Kantariya",
          "phoneNumber": "9601281137",
          "district": "Ahmedabad District",
          "programId": 27,
          "latitude": "23.029761",
          "userRole": ["TRAINEE"],
          "longitude": "72.473677",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:19:39.000+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:15.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:40:38.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:18:05.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:04:34.000+0000"
            },
            {
              "topicName": "Wheat Crop Training",
              "issuedDate": "2020-12-31T11:11:04.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:34:29.000+0000"
            },
            {
              "topicName": "PDA - Hands on",
              "issuedDate": "2020-12-28T07:29:03.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b47e89bd-a4e4-4809-972e-db2e7eae8843",
      "data": {
        "userDetailData": {
          "userId": "b47e89bd-a4e4-4809-972e-db2e7eae8843",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Rajavaram",
          "photo": "",
          "name": "Kathi Vijayalaxmi",
          "phoneNumber": "9121450716",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.7084354",
          "userRole": ["TRAINEE"],
          "longitude": "79.4198629",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:05:32.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b59d4a46-f33b-4b08-ac4d-374948839c8c",
      "data": {
        "userDetailData": {
          "userId": "b59d4a46-f33b-4b08-ac4d-374948839c8c",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Charada",
          "photo": "",
          "name": "Raval Alpeshkumar laxmanbhai",
          "phoneNumber": "9574516482",
          "district": "Gandhinagar District",
          "programId": 27,
          "latitude": "23.5078177",
          "userRole": ["TRAINEE"],
          "longitude": "72.63877",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:57:13.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "cbec1c8c-7914-43b5-b7f6-a3e131c9fdff",
      "data": {
        "userDetailData": {
          "userId": "cbec1c8c-7914-43b5-b7f6-a3e131c9fdff",
          "emailId": "",
          "country": "India",
          "state": "Tamil Nadu",
          "city": "K Therkutheru",
          "photo": "",
          "name": "Elavazhagan",
          "phoneNumber": "7868901558",
          "district": "Pudukkottai District",
          "programId": 27,
          "latitude": "10.3769131",
          "userRole": ["TRAINEE"],
          "longitude": "79.098396",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "3 Folds Concepts",
              "issuedDate": "2020-09-26T06:34:19.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4752ee9a-d3d1-496b-b101-7d5dbd3194d6",
      "data": {
        "userDetailData": {
          "userId": "4752ee9a-d3d1-496b-b101-7d5dbd3194d6",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Tenepalle",
          "photo": "",
          "name": "MANJULA",
          "phoneNumber": "9949681858",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.8722499",
          "userRole": ["TRAINEE"],
          "longitude": "79.0714913",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:05:31.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "a1261bb9-8ef1-4b6b-94b6-1ae65102057f",
      "data": {
        "userDetailData": {
          "userId": "a1261bb9-8ef1-4b6b-94b6-1ae65102057f",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bidada",
          "photo": "",
          "name": "Bhati savan",
          "phoneNumber": "9825792569",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8975064",
          "userRole": ["TRAINEE"],
          "longitude": "69.4633706",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:59:23.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:15:55.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "fdb8b1d1-3822-42e2-8e57-7a95cb7297ee",
      "data": {
        "userDetailData": {
          "userId": "fdb8b1d1-3822-42e2-8e57-7a95cb7297ee",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Rathod Nisha keshrsinh",
          "phoneNumber": "9726043883",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T11:04:06.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "53ada3ff-a458-444f-8186-7be0fd8399e8",
      "data": {
        "userDetailData": {
          "userId": "53ada3ff-a458-444f-8186-7be0fd8399e8",
          "emailId": "mritunjay.mahato@wotr.org.in",
          "country": "India",
          "state": "Jharkhand",
          "city": "Chainpur",
          "photo": "",
          "name": "Mritunjay Kumar Mahato",
          "phoneNumber": "9354334670",
          "district": "Gumla District",
          "programId": 27,
          "latitude": "23.1293535",
          "userRole": ["TRAINEE"],
          "longitude": "84.241573",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Hands-on",
              "issuedDate": "2021-03-16T08:00:20.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "2390c7a6-6347-41ef-be70-2eca8bd7e737",
      "data": {
        "userDetailData": {
          "userId": "2390c7a6-6347-41ef-be70-2eca8bd7e737",
          "emailId": "Rathodajaysinh108@gmail.Com",
          "country": "India",
          "state": "Gujarat",
          "city": "Pundhara",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F2390c7a6-6347-41ef-be70-2eca8bd7e737.jpg",
          "name": "Rathod Ajaysinh Fatesinh ",
          "phoneNumber": "8153975214",
          "district": "Gandhinagar District",
          "programId": 27,
          "latitude": "23.4853879",
          "userRole": ["TRAINEE"],
          "longitude": "72.7451817",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:39.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:57:08.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "01f36c57-cf2f-42da-af8f-49576b08677c",
      "data": {
        "userDetailData": {
          "userId": "01f36c57-cf2f-42da-af8f-49576b08677c",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Nani Khakhar",
          "photo": "",
          "name": "Murji Khimji Maheshwari",
          "phoneNumber": "8780354145",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8871866",
          "userRole": ["TRAINEE"],
          "longitude": "69.5162548",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T12:05:56.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    }, 
  
    {
      "practitionerId": "4c86587a-893e-4d75-9881-965d8fdab183",
      "data": {
        "userDetailData": {
          "userId": "4c86587a-893e-4d75-9881-965d8fdab183",
          "emailId": "jayantilalgorsiya@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "",
          "name": "Jayantilal Gorsiya",
          "phoneNumber": "9879574931",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.2250473",
          "userRole": ["TRAINEE"],
          "longitude": "69.6488554",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-17T10:20:48.000+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:13.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:06:21.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:34:26.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:34:21.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "93815ba1-bfba-437e-aee3-bf517173900e",
      "data": {
        "userDetailData": {
          "userId": "93815ba1-bfba-437e-aee3-bf517173900e",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Mohu",
          "photo": "",
          "name": "Sudam Bodake",
          "phoneNumber": "9637971795",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "19.914904",
          "userRole": ["TRAINEE"],
          "longitude": "73.9338259",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-20T06:31:23.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c6d045d9-99bc-4758-a443-a224f20d2784",
      "data": {
        "userDetailData": {
          "userId": "c6d045d9-99bc-4758-a443-a224f20d2784",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Mansa",
          "photo": "",
          "name": "Bhavnaben satishbhai oad",
          "phoneNumber": "9773204627",
          "district": "Gandhinagar District",
          "programId": 27,
          "latitude": "23.4349854",
          "userRole": ["TRAINEE"],
          "longitude": "72.65650839999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:47:25.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:59:19.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4a91748d-c3d6-4cc2-8487-c54728c64902",
      "data": {
        "userDetailData": {
          "userId": "4a91748d-c3d6-4cc2-8487-c54728c64902",
          "emailId": "simham@wassan.org",
          "country": "India",
          "state": "Andhra Pradesh",
          "city": "Rajampet",
          "photo": "",
          "name": "Narasimha Rao S",
          "phoneNumber": "9440605016",
          "district": "YSR District",
          "programId": 27,
          "latitude": "14.1961485",
          "userRole": ["TRAINEE"],
          "longitude": "79.1592625",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PMT Functions & CBP Training Calendar",
              "issuedDate": "2020-10-13T10:35:44.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9afe6d06-fc0c-42c3-983e-e64a33935587",
      "data": {
        "userDetailData": {
          "userId": "9afe6d06-fc0c-42c3-983e-e64a33935587",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Himatnagar",
          "photo": "",
          "name": "Rajput swarupsinh",
          "phoneNumber": "9016991012",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.5968857",
          "userRole": ["TRAINEE"],
          "longitude": "72.9630378",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:00.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:42.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "3142c5e5-e6b4-467c-8bc7-37c4d62b0601",
      "data": {
        "userDetailData": {
          "userId": "3142c5e5-e6b4-467c-8bc7-37c4d62b0601",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Miryalaguda",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F3142c5e5-e6b4-467c-8bc7-37c4d62b0601.jpg",
          "name": "K Nagamani Damarcherla project",
          "phoneNumber": "7989045211",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.8688457",
          "userRole": ["TRAINEE"],
          "longitude": "79.5643679",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:22:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "8ed9ff54-4090-4a47-9bfb-443b3dafc139",
      "data": {
        "userDetailData": {
          "userId": "8ed9ff54-4090-4a47-9bfb-443b3dafc139",
          "emailId": "",
          "country": "India",
          "state": "Odisha",
          "city": "Alarpur",
          "photo": "",
          "name": "Sankarsan Behera",
          "phoneNumber": "7008816875",
          "district": "Khordha District",
          "programId": 27,
          "latitude": "20.2425745",
          "userRole": ["TRAINEE"],
          "longitude": "85.8895144",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:45:27.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:26:35.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T06:45:26.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6dbddad7-a9ef-472b-8e22-5652e4533b35",
      "data": {
        "userDetailData": {
          "userId": "6dbddad7-a9ef-472b-8e22-5652e4533b35",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Kanubhai patel",
          "phoneNumber": "8849615365",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T08:35:47.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6afb2b3b-3e1c-4e13-9bd9-b1e2e77810c9",
      "data": {
        "userDetailData": {
          "userId": "6afb2b3b-3e1c-4e13-9bd9-b1e2e77810c9",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Rajendra patel ",
          "phoneNumber": "9601281156",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.704764",
          "userRole": ["TRAINEE"],
          "longitude": "72.54030890000001",
          "badgeDetails": [
            {
              "badgeId": 242,
              "badgeName": "District Resource Person"
            }
          ],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T09:04:56.000+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:10.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:40:35.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:18:15.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:03:30.000+0000"
            },
            {
              "topicName": "Wheat Crop Training",
              "issuedDate": "2020-12-31T10:14:55.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:06:04.000+0000"
            },
            {
              "topicName": "PDA - Hands on",
              "issuedDate": "2020-12-28T07:29:02.000+0000"
            },
            {
              "topicName": "PDA Refresher Training for ACT and DSC",
              "issuedDate": "2021-10-07T11:59:18.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9bb07b4d-1f9a-46ff-abff-29ec483b7763",
      "data": {
        "userDetailData": {
          "userId": "9bb07b4d-1f9a-46ff-abff-29ec483b7763",
          "emailId": "sheetal@catalysts.org",
          "country": "India",
          "state": "West Bengal",
          "city": "Bidhan Nagar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F9bb07b4d-1f9a-46ff-abff-29ec483b7763.jpg",
          "name": "Sheetal Joshi",
          "phoneNumber": "9903219434",
          "district": "North Twenty Four Parganas District",
          "programId": 27,
          "latitude": "22.5833887",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "88.4078253",
          "badgeDetails": [
            {
              "badgeId": 34,
              "badgeName": "Nepali Trainers"
            },
            {
              "badgeId": 241,
              "badgeName": "Test Badge"
            },
            {
              "badgeId": 16,
              "badgeName": "PDA Champion"
            },
            {
              "badgeId": 19,
              "badgeName": "Support Solution Team"
            },
            {
              "badgeId": 52,
              "badgeName": "Master Trainers in Bengali"
            }
          ],
          "traineeData": [
            {
              "topicName": "Test topic in new UI",
              "issuedDate": "2022-03-10T09:18:25.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T03:29:05.000+0000"
            },
            {
              "topicName": "test",
              "issuedDate": "2022-02-24T11:24:35.000+0000"
            },
            {
              "topicName": "Test for Content",
              "issuedDate": "2022-02-24T11:16:31.000+0000"
            },
            {
              "topicName": "test",
              "issuedDate": "2022-02-25T04:41:29.000+0000"
            },
            {
              "topicName": "Testing Topic lock in time",
              "issuedDate": "2021-04-24T12:28:31.000+0000"
            },
            {
              "topicName": "Sign in through codes",
              "issuedDate": "2021-02-17T11:36:56.000+0000"
            },
            {
              "topicName": "Test for Content",
              "issuedDate": "2021-05-23T07:41:17.000+0000"
            },
            {
              "topicName": "How to Scan QR code test",
              "issuedDate": "2021-02-08T16:53:31.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T05:16:21.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T05:16:56.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T05:30:55.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T03:19:21.000+0000"
            },
            {
              "topicName": "Topic Lock-in time testing",
              "issuedDate": "2021-04-14T08:17:31.000+0000"
            },
            {
              "topicName": "Topic Lock-in time testing",
              "issuedDate": "2021-04-14T12:29:16.000+0000"
            },
            {
              "topicName": "PDA - Assisted Scan",
              "issuedDate": "2021-03-08T07:23:06.000+0000"
            },
            {
              "topicName": "Test for Content",
              "issuedDate": "2021-05-22T12:09:55.000+0000"
            },
            {
              "topicName": "Test for file size",
              "issuedDate": "2021-05-10T09:31:21.000+0000"
            },
            {
              "topicName": "Test for Content",
              "issuedDate": "2021-05-23T07:34:53.000+0000"
            },
            {
              "topicName": "Test 1",
              "issuedDate": "2021-04-05T07:35:07.000+0000"
            },
            {
              "topicName": "PDA  Dashboard Hindi",
              "issuedDate": "2021-04-29T12:27:21.000+0000"
            },
            {
              "topicName": "PDA Dashboard english",
              "issuedDate": "2021-04-29T12:29:18.000+0000"
            },
            {
              "topicName": "PDA Additional features Tamil",
              "issuedDate": "2021-04-29T12:26:06.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-09-02T06:49:22.000+0000"
            },
            {
              "topicName": "Technology and development",
              "issuedDate": "2021-04-30T12:33:31.000+0000"
            },
            {
              "topicName": "Data analysis techniques for VCF",
              "issuedDate": "2021-04-30T12:34:14.000+0000"
            },
            {
              "topicName": "Techniques of Irrigation",
              "issuedDate": "2021-05-03T03:50:38.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-25T06:11:48.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-19T03:43:24.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Best Practices - Smitha Murthy",
              "issuedDate": "2020-10-14T08:50:38.000+0000"
            },
            {
              "topicName": "Virtual Training Format",
              "issuedDate": "2020-10-14T06:57:01.000+0000"
            },
            {
              "topicName": "3 Fold Program",
              "issuedDate": "2020-10-01T02:52:59.000+0000"
            },
            {
              "topicName": "Zoom for Hubs & Spokes",
              "issuedDate": "2020-10-14T08:48:50.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training for Trainers | ACT",
              "issuedDate": "2020-12-11T12:42:33.000+0000"
            },
            {
              "topicName": "Zoom for Hubs & Spokes",
              "issuedDate": "2020-12-10T05:59:29.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T09:39:41.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-10-14T05:52:51.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-10-14T08:46:27.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-10-14T06:49:35.000+0000"
            },
            {
              "topicName": "3 Fold Program",
              "issuedDate": "2020-09-30T14:45:37.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:18:23.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-06-17T11:11:20.000+0000"
            },
            {
              "topicName": "PDA Demo Test fr content",
              "issuedDate": "2021-06-17T11:16:30.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:35:34.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T05:57:32.000+0000"
            },
            {
              "topicName": "PDA - Hands on",
              "issuedDate": "2020-12-28T07:30:37.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-19T09:41:40.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Test topic in new UI",
              "issuedDate": "2022-03-10T09:30:00.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T03:15:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-02-16T15:15:00.000+0000"
            },
            {
              "topicName": "test",
              "issuedDate": "2022-02-24T13:30:00.000+0000"
            },
            {
              "topicName": "Test for Content",
              "issuedDate": "2022-02-24T11:30:00.000+0000"
            },
            {
              "topicName": "test",
              "issuedDate": "2022-02-25T04:45:00.000+0000"
            },
            {
              "topicName": "Testing Topic lock in time",
              "issuedDate": "2021-04-24T12:15:00.000+0000"
            },
            {
              "topicName": "Sign in through codes",
              "issuedDate": "2021-02-17T12:30:00.000+0000"
            },
            {
              "topicName": "Test for Content",
              "issuedDate": "2021-05-23T07:45:00.000+0000"
            },
            {
              "topicName": "How to Scan QR code test",
              "issuedDate": "2021-02-08T16:48:00.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T03:45:00.000+0000"
            },
            {
              "topicName": "PDA Hands-on",
              "issuedDate": "2021-03-16T04:45:00.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T05:15:00.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T05:15:00.000+0000"
            },
            {
              "topicName": "Improved Hands on Test 2",
              "issuedDate": "2021-04-14T05:30:00.000+0000"
            },
            {
              "topicName": "Topic Lock-in time testing",
              "issuedDate": "2021-04-14T08:30:00.000+0000"
            },
            {
              "topicName": "Topic Lock-in time testing",
              "issuedDate": "2021-04-14T08:45:00.000+0000"
            },
            {
              "topicName": "How to Scan QR code test",
              "issuedDate": "2021-02-16T07:05:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-04-28T06:30:00.000+0000"
            },
            {
              "topicName": "Test for Content",
              "issuedDate": "2021-05-22T12:15:00.000+0000"
            },
            {
              "topicName": "Test for file size",
              "issuedDate": "2021-05-10T09:30:00.000+0000"
            },
            {
              "topicName": "Test for Content",
              "issuedDate": "2021-05-23T07:45:00.000+0000"
            },
            {
              "topicName": "Test 1",
              "issuedDate": "2021-04-05T07:20:00.000+0000"
            },
            {
              "topicName": "PDA DEMO english",
              "issuedDate": "2021-04-29T11:30:00.000+0000"
            },
            {
              "topicName": "PDA  Dashboard Hindi",
              "issuedDate": "2021-04-29T11:30:00.000+0000"
            },
            {
              "topicName": "PDA Demo Hindi",
              "issuedDate": "2021-04-29T11:30:00.000+0000"
            },
            {
              "topicName": "PDA Demo Tamil",
              "issuedDate": "2021-04-29T12:30:00.000+0000"
            },
            {
              "topicName": "PDA Dashboard english",
              "issuedDate": "2021-04-29T12:30:00.000+0000"
            },
            {
              "topicName": "PDA Dash Board Tamil",
              "issuedDate": "2021-04-29T12:30:00.000+0000"
            },
            {
              "topicName": "PDA Additional features Tamil",
              "issuedDate": "2021-04-29T12:30:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-09-02T09:00:00.000+0000"
            },
            {
              "topicName": "Techniques of Irrigation",
              "issuedDate": "2021-05-19T08:40:00.000+0000"
            },
            {
              "topicName": "Data analysis techniques for VCF",
              "issuedDate": "2021-04-30T09:30:00.000+0000"
            },
            {
              "topicName": "PDA Improved Hands on",
              "issuedDate": "2021-04-12T10:00:00.000+0000"
            },
            {
              "topicName": "PDA Improved Hands on",
              "issuedDate": "2021-04-12T10:15:00.000+0000"
            },
            {
              "topicName": "Technology and development",
              "issuedDate": "2021-04-30T09:30:00.000+0000"
            },
            {
              "topicName": "Techniques of Irrigation",
              "issuedDate": "2021-05-03T04:00:00.000+0000"
            },
            {
              "topicName": "Test 1",
              "issuedDate": "2021-04-18T08:15:00.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-25T06:30:00.000+0000"
            },
            {
              "topicName": "Water management",
              "issuedDate": "2020-09-02T10:19:00.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-06T06:05:00.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training for Trainers | ACT",
              "issuedDate": "2020-12-11T13:00:00.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-08T07:00:00.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-26T06:48:00.000+0000"
            },
            {
              "topicName": "pda trial",
              "issuedDate": "2020-11-23T05:01:00.000+0000"
            },
            {
              "topicName": "PDA for Trainers",
              "issuedDate": "2020-11-24T11:56:00.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-19T04:05:00.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | ACT",
              "issuedDate": "2020-12-11T13:00:00.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-02T06:30:00.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-11T05:00:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-08-27T07:00:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-06-17T12:15:00.000+0000"
            },
            {
              "topicName": "PDA Demo Test fr content",
              "issuedDate": "2021-06-17T11:30:00.000+0000"
            },
            {
              "topicName": "PDA Hand-on",
              "issuedDate": "2021-08-30T11:00:00.000+0000"
            },
            {
              "topicName": "PDA  Hands on Hindi",
              "issuedDate": "2021-04-29T11:30:00.000+0000"
            },
            {
              "topicName": "PDA Dash Board checks",
              "issuedDate": "2020-12-02T07:59:00.000+0000"
            },
            {
              "topicName": "PDA - Hands on",
              "issuedDate": "2020-12-28T07:30:00.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-19T10:30:00.000+0000"
            },
            {
              "topicName": "PDA Refresher Training for ACT and DSC",
              "issuedDate": "2021-10-07T11:30:00.000+0000"
            },
            {
              "topicName": "भूजलाची ओळख व भूजल व्यवस्थापनाची गरज | Introduction t   to groundwater -Need for GW management",
              "issuedDate": "2021-09-12T09:45:00.000+0000"
            },
            {
              "topicName": "भूजलाची ओळख व भूजल व्यवस्   थापनाची गरज | Introduction to groundwater -Need for GW management",
              "issuedDate": "2021-09-12T10:45:00.000+0000"
            },
            {
              "topicName": "PDA Dashboard Demo",
              "issuedDate": "2021-11-26T05:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": ["M Phil"]
        }
      }
    },
  
    {
      "practitionerId": "2e400362-1635-47e4-99c8-72b00dd38b19",
      "data": {
        "userDetailData": {
          "userId": "2e400362-1635-47e4-99c8-72b00dd38b19",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Perne",
          "photo": "",
          "name": "Abdul Latheef ",
          "phoneNumber": "9379424674",
          "district": "Dakshina Kannada District",
          "programId": 27,
          "latitude": "12.8230756",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "75.19913129999999",
          "badgeDetails": [
            {
              "badgeId": 53,
              "badgeName": "Nepali Master Trainers"
            },
            {
              "badgeId": 20,
              "badgeName": "Content Administrator"
            },
            {
              "badgeId": 34,
              "badgeName": "Nepali Trainers"
            },
            {
              "badgeId": 16,
              "badgeName": "PDA Champion"
            },
            {
              "badgeId": 51,
              "badgeName": "Bengali Trainer"
            },
            {
              "badgeId": 26,
              "badgeName": "Test for video"
            },
            {
              "badgeId": 47,
              "badgeName": "Master Trainers"
            },
            {
              "badgeId": 19,
              "badgeName": "Support Solution Team"
            },
            {
              "badgeId": 19,
              "badgeName": "Support Solution Team"
            }
          ],
          "traineeData": [
            {
              "topicName": "Data analysis techniques for VCF",
              "issuedDate": "2021-04-30T12:55:11.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-17T10:29:27.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-25T06:11:51.000+0000"
            },
            {
              "topicName": "Water management",
              "issuedDate": "2020-09-02T10:19:53.000+0000"
            },
            {
              "topicName": "3 Fold Program",
              "issuedDate": "2020-09-26T04:40:01.000+0000"
            },
            {
              "topicName": "Virtual Training Format",
              "issuedDate": "2020-10-14T06:57:13.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-06T06:27:14.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-26T06:49:51.000+0000"
            },
            {
              "topicName": "pda trial",
              "issuedDate": "2020-11-23T04:31:26.000+0000"
            },
            {
              "topicName": "PDA for Trainers",
              "issuedDate": "2020-11-23T05:57:37.000+0000"
            },
            {
              "topicName": "PDA for Trainers",
              "issuedDate": "2020-11-24T14:32:37.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T10:47:49.000+0000"
            },
            {
              "topicName": "PDA Video Session",
              "issuedDate": "2020-10-07T08:10:39.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-10-14T05:53:06.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-10-14T06:50:38.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-05T06:46:55.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-19T05:08:56.000+0000"
            },
            {
              "topicName": "PDA Trial",
              "issuedDate": "2020-11-07T09:21:02.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-02T06:57:18.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:03:31.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-11T05:06:48.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:22:12.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-25T06:30:00.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-19T04:00:00.000+0000"
            },
            {
              "topicName": "3 Fold Program",
              "issuedDate": "2020-10-01T02:52:00.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-06T06:05:00.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-26T06:48:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2020-11-20T05:30:00.000+0000"
            },
            {
              "topicName": "PDA session: Training, Capacity Building and Hands on",
              "issuedDate": "2020-09-04T09:25:00.000+0000"
            },
            {
              "topicName": "3 Fold Program",
              "issuedDate": "2020-09-30T14:40:00.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-05T06:30:00.000+0000"
            },
            {
              "topicName": "PDA Trial",
              "issuedDate": "2020-11-07T09:31:00.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:30:00.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:45:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ff97766a-e506-49df-9522-8efa994ff324",
      "data": {
        "userDetailData": {
          "userId": "ff97766a-e506-49df-9522-8efa994ff324",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture/ff97766a-e506-49df-9522-8efa994ff324.JPG",
          "name": "Uzra Sultana",
          "phoneNumber": "9986071430",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9910529",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.61004659999999",
          "badgeDetails": [
            {
              "badgeId": 49,
              "badgeName": "Master Trainer"
            },
            {
              "badgeId": 13,
              "badgeName": "Env. & GIS VCF"
            }
          ],
          "traineeData": [
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-05T07:26:08+0000"
            },
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2020-02-18T07:22:46+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T11:00:00.000+0000"
            },
            {
              "topicName": "Manu Test Topic",
              "issuedDate": "2019-11-27T09:10:00.000+0000"
            },
            {
              "topicName": "Experience of deploying CB solutions in Meghalaya",
              "issuedDate": "2020-03-02T09:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b912e843-b03e-4f84-8133-82016c8dd2a8",
      "data": {
        "userDetailData": {
          "userId": "b912e843-b03e-4f84-8133-82016c8dd2a8",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Hyderabad",
          "photo": "",
          "name": "Sreekanth",
          "phoneNumber": "9246281232",
          "district": "Ranga Reddy District",
          "programId": 27,
          "latitude": "17.3933786",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "78.61436719999999",
          "badgeDetails": [
            {
              "badgeId": 19,
              "badgeName": "Support Solution Team"
            }
          ],
          "traineeData": [
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:08.000+0000"
            },
            {
              "topicName": "PDA for Trainers",
              "issuedDate": "2020-11-23T05:57:29.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:40:32.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:18:14.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:34:23.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:30:00.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:00:00.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T11:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "88acf595-35dd-4ee8-87aa-924166be5859",
      "data": {
        "userDetailData": {
          "userId": "88acf595-35dd-4ee8-87aa-924166be5859",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Harsule",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F88acf595-35dd-4ee8-87aa-924166be5859.jpg",
          "name": "Pritam Lonare",
          "phoneNumber": "9284505860",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "19.8103583",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "73.9447994",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Midterm Measurement of Desilting(Jalsamruddhi)",
              "issuedDate": "2021-02-20T11:42:46.000+0000"
            },
            {
              "topicName": "Water User Association Training",
              "issuedDate": "2021-04-03T06:03:15.000+0000"
            },
            {
              "topicName": "Site Selection of Desilting work Process of Government Approval and data Collection of its work",
              "issuedDate": "2021-02-06T11:51:50.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-02T06:56:35.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-05T12:40:45.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Water User Association Training",
              "issuedDate": "2021-04-07T06:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-23T05:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-20T06:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-05T11:30:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-22T05:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-13T05:30:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-06T10:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c7aaf34b-f478-4809-94b2-38e316f25acc",
      "data": {
        "userDetailData": {
          "userId": "c7aaf34b-f478-4809-94b2-38e316f25acc",
          "emailId": "",
          "country": "India",
          "state": "Jharkhand",
          "city": "Hiranpur",
          "photo": "",
          "name": "Subhabrata Das",
          "phoneNumber": "9635460238",
          "district": "Pakur District",
          "programId": 27,
          "latitude": "24.7057346",
          "userRole": ["TRAINEE"],
          "longitude": "87.70494529999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Hands-on",
              "issuedDate": "2021-03-16T08:02:49.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "025233ee-51b5-443f-af66-4aca4590614d",
      "data": {
        "userDetailData": {
          "userId": "025233ee-51b5-443f-af66-4aca4590614d",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Chalsa",
          "photo": "",
          "name": "Subhash Ghosh",
          "phoneNumber": "7903458389",
          "district": "Jalpaiguri District",
          "programId": 27,
          "latitude": "26.8816342",
          "userRole": ["TRAINEE"],
          "longitude": "88.7984924",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-04T10:06:14.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-11-04T10:38:15.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T05:56:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "7d16789c-684c-442c-b2a1-487b76c24875",
      "data": {
        "userDetailData": {
          "userId": "7d16789c-684c-442c-b2a1-487b76c24875",
          "emailId": "",
          "country": "India",
          "state": "Tamil Nadu",
          "city": "Vettanviduthi",
          "photo": "",
          "name": "Deepa",
          "phoneNumber": "7094733126",
          "district": "Pudukkottai District",
          "programId": 27,
          "latitude": "10.3990274",
          "userRole": ["TRAINEE"],
          "longitude": "79.0401363",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "3 Folds Concepts",
              "issuedDate": "2020-09-26T06:34:38.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f07d7b79-7244-4b9d-92d5-ba1ddcbf4d6f",
      "data": {
        "userDetailData": {
          "userId": "f07d7b79-7244-4b9d-92d5-ba1ddcbf4d6f",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Manaba",
          "photo": "",
          "name": "SAJID RAUMA",
          "phoneNumber": "7984636785",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.2914304",
          "userRole": ["TRAINEE"],
          "longitude": "70.7516443",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:55:03.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:52:44.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:05:34.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "35218eb5-0116-4517-a902-d7b02fb15641",
      "data": {
        "userDetailData": {
          "userId": "35218eb5-0116-4517-a902-d7b02fb15641",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vijapur",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F35218eb5-0116-4517-a902-d7b02fb15641.jpg",
          "name": "Divya Goswami",
          "phoneNumber": "9664727722",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.5734387",
          "userRole": ["TRAINEE"],
          "longitude": "72.7393338",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:45:58.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:57:32.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6e95d2e7-e874-4076-9412-17aeeb8ea5c3",
      "data": {
        "userDetailData": {
          "userId": "6e95d2e7-e874-4076-9412-17aeeb8ea5c3",
          "emailId": "",
          "country": "India",
          "state": "Madhya Pradesh",
          "city": "Badyala Mata",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F6e95d2e7-e874-4076-9412-17aeeb8ea5c3.jpg",
          "name": "Anand shah",
          "phoneNumber": "9773168893",
          "district": "Ratlam District",
          "programId": 27,
          "latitude": "23.5896617",
          "userRole": ["TRAINEE"],
          "longitude": "74.9851678",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:22:12.000+0000"
            },
            {
              "topicName": "Image composition and layouts LL3",
              "issuedDate": "2022-03-29T07:35:08.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:47:55.000+0000"
            },
            {
              "topicName": "Taking good photos and making posters",
              "issuedDate": "2022-04-05T07:37:20.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:24:24.000+0000"
            },
            {
              "topicName": "Feedback and next steps LL2",
              "issuedDate": "2022-03-08T06:49:09.000+0000"
            },
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:45:54.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:53.000+0000"
            },
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:44:49.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:26:05.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T07:15:38.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:14.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "25b9db1a-07dc-4c73-9ead-a9ab493821fb",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "25b9db1a-07dc-4c73-9ead-a9ab493821fb",
            "emailId": "",
            "country": "India",
            "state": "Madhya Pradesh",
            "city": "Bhopal",
            "photo": "",
            "name": "K K Trivedi",
            "phoneNumber": "8959591213",
            "district": "Bhopal District",
            "programId": 27,
            "latitude": "23.1774792",
            "userRole": ["TRAINEE"],
            "longitude": "77.4382237",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Introduction to Learning Objectives",
                "issuedDate": "2022-02-22T07:22:13.000+0000"
              },
              {
                "topicName": "Feedback and next steps",
                "issuedDate": "2022-01-25T07:26:25.000+0000"
              },
              {
                "topicName": "Recap and potential next steps with the learning experience",
                "issuedDate": "2022-02-01T06:43:50.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "891f8b91-199c-4a29-bb5c-59c067279273",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "891f8b91-199c-4a29-bb5c-59c067279273",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Visnagar",
            "photo": "",
            "name": "Jigneshkumar pravinbhai patel",
            "phoneNumber": "9924059198",
            "district": "Mehsana District",
            "programId": 27,
            "latitude": "23.6916781",
            "userRole": ["TRAINEE"],
            "longitude": "72.5474971",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "PDA Rollout Demo",
                "issuedDate": "2021-05-04T05:45:56.000+0000"
              },
              {
                "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
                "issuedDate": "2021-02-24T09:16:51.000+0000"
              },
              {
                "topicName": "Wheat Crop Training",
                "issuedDate": "2020-12-31T10:11:42.000+0000"
              },
              {
                "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI PUs Staff",
                "issuedDate": "2021-05-05T06:56:49.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "8a499587-62a8-4f73-a20d-23cd31ee58f2",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "8a499587-62a8-4f73-a20d-23cd31ee58f2",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Anand",
            "photo": "",
            "name": "Harsh Dave",
            "phoneNumber": "9033430918",
            "district": "Anand District",
            "programId": 27,
            "latitude": "22.5417462",
            "userRole": ["TRAINER", "TRAINEE"],
            "longitude": "72.9703971",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "PDA - Trainer App",
                "issuedDate": "2019-12-26T11:17:10+0000"
              },
              {
                "topicName": "PDA - Participant App",
                "issuedDate": "2019-12-26T10:43:29+0000"
              },
              {
                "topicName": "PDA - Program App",
                "issuedDate": "2019-12-26T11:48:50+0000"
              }
            ],
            "trainerData": [
              {
                "topicName": "PDA mock session - Churu",
                "issuedDate": "2020-12-18T15:25:00.000+0000"
              }
            ],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "b57afa83-caa4-4505-aaba-517e4a20ad5c",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "b57afa83-caa4-4505-aaba-517e4a20ad5c",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Pantiya",
            "photo": "",
            "name": "Tulasi Bhai bhanaji lakhani",
            "phoneNumber": "9099990342",
            "district": "Kachchh District",
            "programId": 27,
            "latitude": "23.0996657",
            "userRole": ["TRAINEE"],
            "longitude": "69.9125952",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Orientation on Abhy and PGWM - II",
                "issuedDate": "2021-08-14T12:06:58.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "14b5f225-43c0-4a97-ac66-52433582208a",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "14b5f225-43c0-4a97-ac66-52433582208a",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Budharmora",
            "photo": "",
            "name": "Pooja Bhavesh sathwara",
            "phoneNumber": "9624373010",
            "district": "Kachchh District",
            "programId": 27,
            "latitude": "23.3271098",
            "userRole": ["TRAINEE"],
            "longitude": "70.1971151",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Orientation on Abhy and PGWM",
                "issuedDate": "2021-08-14T07:14:48.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "83ea2f52-d539-4842-ad29-390a6c1a238e",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "83ea2f52-d539-4842-ad29-390a6c1a238e",
            "emailId": "",
            "country": "India",
            "state": "Telangana",
            "city": "Pillala Marri Part",
            "photo": "",
            "name": "Rashida suparviasor",
            "phoneNumber": "7095557253",
            "district": "Suryapet District",
            "programId": 27,
            "latitude": "17.1777221",
            "userRole": ["TRAINEE"],
            "longitude": "79.6066245",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Demonstration of PDA - ICDS Supervisors Training",
                "issuedDate": "2021-06-09T10:22:25.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "97464daa-7d25-48bf-8d76-21978dc26162",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "97464daa-7d25-48bf-8d76-21978dc26162",
            "emailId": "",
            "country": "India",
            "state": "Telangana",
            "city": "Hyderabad",
            "photo": "",
            "name": "Depa Venkatamma",
            "phoneNumber": "9010099359",
            "district": "Rangareddy District",
            "programId": 27,
            "latitude": "17.3224219",
            "userRole": ["TRAINEE"],
            "longitude": "78.5568862",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Demonstration of PDA - ICDS Supervisors Training",
                "issuedDate": "2021-06-09T10:05:29.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "07c9dc3e-7f5b-44f2-9e77-db2626100a1f",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "07c9dc3e-7f5b-44f2-9e77-db2626100a1f",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Nilpar",
            "photo": "",
            "name": "Dharmendrbhai hothi",
            "phoneNumber": "9979313440",
            "district": "Kutch District",
            "programId": 27,
            "latitude": "23.4607477",
            "userRole": ["TRAINEE"],
            "longitude": "70.41333829999999",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-02-16T06:42:19.000+0000"
              },
              {
                "topicName": "Community Participation-Why",
                "issuedDate": "2021-04-09T06:46:29.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-01-30T09:05:50.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
                "issuedDate": "2021-01-11T07:36:54.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "8c7435c7-ba84-42fa-bbd1-9938eabfdaeb",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "8c7435c7-ba84-42fa-bbd1-9938eabfdaeb",
            "emailId": "shubha.b264@gmail.com",
            "country": "India",
            "state": "Gujarat",
            "city": "Anand",
            "photo": "",
            "name": "Shubhoshree Banerjee",
            "phoneNumber": "7409633707",
            "district": "Anand District",
            "programId": 27,
            "latitude": "22.5645175",
            "userRole": ["TRAINEE"],
            "longitude": "72.928871",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Image composition and layouts LL3",
                "issuedDate": "2022-03-29T07:35:16.000+0000"
              },
              {
                "topicName": "Introduction to Visual Design LL3",
                "issuedDate": "2022-03-22T07:25:47.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "103893bb-851c-4edb-9ed9-045654677f5d",
      "response": {
        "data": {
          "userDetailData": {
            "userId": "103893bb-851c-4edb-9ed9-045654677f5d",
            "emailId": "rajesh@vrutti.org",
            "country": "India",
            "state": "Tamil Nadu",
            "city": "Tiruchirappalli",
            "photo": "",
            "name": "Rajesh",
            "phoneNumber": "9500057216",
            "district": "Tiruchirappalli District",
            "programId": 27,
            "latitude": "10.8353468",
            "userRole": ["TRAINER", "TRAINEE"],
            "longitude": "78.6663932",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "CBP Orientation",
                "issuedDate": "2020-08-25T06:11:18.000+0000"
              }
            ],
            "trainerData": [
              {
                "topicName": "3 Fold Program",
                "issuedDate": "2020-09-26T04:40:00.000+0000"
              },
              {
                "topicName": "3 Folds Concepts",
                "issuedDate": "2020-09-26T06:30:00.000+0000"
              }
            ],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
  
    {
      "id": "2b80d0a9-7767-4f05-9037-719583d95b5c",
      "data": {
        "userDetailData": {
          "userId": "2b80d0a9-7767-4f05-9037-719583d95b5c",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Kolkata",
          "photo": "",
          "name": "Hemant Bothra",
          "phoneNumber": "9831121011",
          "district": "Kolkata District",
          "programId": 27,
          "latitude": "22.572646",
          "userRole": ["TRAINEE"],
          "longitude": "88.36389500000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-06-15T05:15:54.000+0000"
            },
            {
              "topicName": "PDA DEMO english",
              "issuedDate": "2021-04-29T15:03:41.000+0000"
            },
            {
              "topicName": "PDA Demo Hindi",
              "issuedDate": "2021-04-29T15:02:48.000+0000"
            },
            {
              "topicName": "PDA Additional features Tamil",
              "issuedDate": "2021-04-29T15:09:13.000+0000"
            },
            {
              "topicName": "Techniques of Irrigation",
              "issuedDate": "2021-05-19T12:03:05.000+0000"
            },
            {
              "topicName": "Test 1",
              "issuedDate": "2021-04-18T08:06:11.000+0000"
            },
            {
              "topicName": "भूजलाची ओळख व भूजल व्यवस्थापनाची गरज | Introduction to groundwater -Need for GW management",
              "issuedDate": "2021-09-12T09:53:16.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": ["Test1"],
          "qualificationTitle": []
        }
      }
    },
    {
      "id": "d8e99826-8d1a-4abf-8ecf-2e9afb8dc01a",
      "data": {
        "userDetailData": {
          "userId": "d8e99826-8d1a-4abf-8ecf-2e9afb8dc01a",
          "emailId": "",
          "country": "India",
          "state": "Odisha",
          "city": "Bhubaneswar",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2Fd8e99826-8d1a-4abf-8ecf-2e9afb8dc01a.jpg",
          "name": "Bulbuli",
          "phoneNumber": "8917229758",
          "district": "Khordha District",
          "programId": 27,
          "latitude": "20.2785813",
          "userRole": ["TRAINEE"],
          "longitude": "85.856888",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:48:36+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "id": "46c3bc6a-0790-4fd7-b467-1fb0a8a3c1a8",
      "data": {
        "userDetailData": {
          "userId": "46c3bc6a-0790-4fd7-b467-1fb0a8a3c1a8",
          "emailId": "omsinghagri@gmail.com",
          "country": "India",
          "state": "Jharkhand",
          "city": "Kanaslapos",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F46c3bc6a-0790-4fd7-b467-1fb0a8a3c1a8.jpg",
          "name": "Om prakash singh",
          "phoneNumber": "7004022171",
          "district": "West Singhbhum District",
          "programId": 27,
          "latitude": "22.2232052",
          "userRole": ["TRAINEE"],
          "longitude": "85.6319245",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Hands-on",
              "issuedDate": "2021-03-16T08:04:26.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "id": "90ed086b-cf3f-4107-b55d-b893385c105f",
      "data": {
        "userDetailData": {
          "userId": "90ed086b-cf3f-4107-b55d-b893385c105f",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Kolkata",
          "photo": "",
          "name": "Hemant",
          "phoneNumber": "8335081010",
          "district": "North 24 Parganas District",
          "programId": 27,
          "latitude": "22.5831112",
          "userRole": ["TRAINEE"],
          "longitude": "88.40809639999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "How to Scan QR code test",
              "issuedDate": "2021-02-16T01:30:32.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "id": "15834b56-cfac-4d93-ba91-1dcf7785ad19",
      "data": {
        "userDetailData": {
          "userId": "15834b56-cfac-4d93-ba91-1dcf7785ad19",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Aveek",
          "phoneNumber": "6361086839",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.974002",
          "userRole": ["TRAINEE"],
          "longitude": "77.613458",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-25T06:11:13.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Best Practices - Smitha Murthy",
              "issuedDate": "2020-10-14T06:59:29.000+0000"
            },
            {
              "topicName": "Virtual Training Format",
              "issuedDate": "2020-10-14T06:57:06.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-10-14T05:53:30.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-10-14T10:40:39.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-10-14T06:49:45.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "id": "eae4b6b2-f9e0-4738-9d94-c9345173c4cd",
      "data": {
        "userDetailData": {
          "userId": "eae4b6b2-f9e0-4738-9d94-c9345173c4cd",
          "emailId": "poonam25kulshrestha@gmail.com",
          "country": "India",
          "state": "Rajasthan",
          "city": "Jaipur",
          "photo": "",
          "name": "Poonam Kulshrestha",
          "phoneNumber": "9636378190",
          "district": "Jaipur District",
          "programId": 27,
          "latitude": "26.8522340000001",
          "userRole": ["TRAINEE"],
          "longitude": "75.767368",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-04T10:07:10.000+0000"
            },
            {
              "topicName": "PDA Demo Hindi",
              "issuedDate": "2020-11-23T05:57:06.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "efd178d2-ffe6-4fdf-90ac-0df881271fb4",
      "data": {
        "userDetailData": {
          "userId": "efd178d2-ffe6-4fdf-90ac-0df881271fb4",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Sukhpar",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2Fefd178d2-ffe6-4fdf-90ac-0df881271fb4.jpg",
          "name": "JIVANBHAI B AHIR",
          "phoneNumber": "9913506765",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.4899161",
          "userRole": ["TRAINEE"],
          "longitude": "71.07997879999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:43:50.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:31:26.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:06:05.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:39:12.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f571026c-dea6-4292-88a3-d71ffa3296d7",
      "data": {
        "userDetailData": {
          "userId": "f571026c-dea6-4292-88a3-d71ffa3296d7",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Yerkaram",
          "photo": "",
          "name": " Sunitha Gunda ",
          "phoneNumber": "9603699379",
          "district": "Suryapet District",
          "programId": 27,
          "latitude": "17.2264888",
          "userRole": ["TRAINEE"],
          "longitude": "79.5646617",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:14:35.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "0fcdf556-7144-4bdf-a6b6-005b50b00f14",
      "data": {
        "userDetailData": {
          "userId": "0fcdf556-7144-4bdf-a6b6-005b50b00f14",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Pune",
          "photo": "",
          "name": "Simran Sumbre",
          "phoneNumber": "9619020330",
          "district": "Pune District",
          "programId": 27,
          "latitude": "18.4933415",
          "userRole": ["TRAINEE"],
          "longitude": "73.820798",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Image composition and layouts LL3",
              "issuedDate": "2022-03-29T07:34:59.000+0000"
            },
            {
              "topicName": "Taking good photos and making posters",
              "issuedDate": "2022-04-05T07:34:38.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:25:14.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-08-27T06:49:07.000+0000"
            },
            {
              "topicName": "PDA Hand-on",
              "issuedDate": "2021-08-30T11:11:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9c70412d-ebdd-432a-a17a-6c493577ff7d",
      "data": {
        "userDetailData": {
          "userId": "9c70412d-ebdd-432a-a17a-6c493577ff7d",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Chaudhari twinkle prabhudas ",
          "phoneNumber": "7984391858",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:53:59.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "8ec3b98a-9b06-40e1-9029-f3541fe9a640",
      "data": {
        "userDetailData": {
          "userId": "8ec3b98a-9b06-40e1-9029-f3541fe9a640",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Kanmer",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F8ec3b98a-9b06-40e1-9029-f3541fe9a640.jpg",
          "name": "Prem vaghela",
          "phoneNumber": "9537409154",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.3983553",
          "userRole": ["TRAINEE"],
          "longitude": "70.8714528",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:40:15.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:31:00.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:04:51.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:36:50.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "86f32410-0d64-4345-9030-6d3533166fce",
      "data": {
        "userDetailData": {
          "userId": "86f32410-0d64-4345-9030-6d3533166fce",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Mansa",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F86f32410-0d64-4345-9030-6d3533166fce.jpg",
          "name": "Nilamchaudhari",
          "phoneNumber": "7874375947",
          "district": "Gandhinagar District",
          "programId": 27,
          "latitude": "23.4349854",
          "userRole": ["TRAINEE"],
          "longitude": "72.65650839999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:23:49.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e51170d0-7e0f-4b6d-b7de-e0aef621b2a5",
      "data": {
        "userDetailData": {
          "userId": "e51170d0-7e0f-4b6d-b7de-e0aef621b2a5",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Chalsa",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture/e51170d0-7e0f-4b6d-b7de-e0aef621b2a5.jpg",
          "name": "Raj kumar Das",
          "phoneNumber": "9064853385",
          "district": "Jalpaiguri District",
          "programId": 27,
          "latitude": "26.8779424",
          "userRole": ["TRAINEE"],
          "longitude": "88.80806849999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T09:39:58.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T05:58:23.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "805f8115-7fc6-481c-ae59-9a635ed92038",
      "data": {
        "userDetailData": {
          "userId": "805f8115-7fc6-481c-ae59-9a635ed92038",
          "emailId": "shaurya.gupta@sevamandir.org",
          "country": "India",
          "state": "Rajasthan",
          "city": "Udaipur",
          "photo": "",
          "name": "Shaurya Gupta",
          "phoneNumber": "8951272647",
          "district": "Udaipur District",
          "programId": 27,
          "latitude": "24.6086473",
          "userRole": ["TRAINEE"],
          "longitude": "73.6868415",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:22:43.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T06:57:01.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:23:31.000+0000"
            },
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:45:11.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:27:00.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T06:43:40.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:03.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "7d56f878-01cb-4e2d-8001-8d1308260ed2",
      "data": {
        "userDetailData": {
          "userId": "7d56f878-01cb-4e2d-8001-8d1308260ed2",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Himatnagar",
          "photo": "",
          "name": "RAVAL RUPESHKUMAR dilipbhai",
          "phoneNumber": "9023886325",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.5968857",
          "userRole": ["TRAINEE"],
          "longitude": "72.9630378",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:12.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:09.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "2854f4ff-cf55-4f19-b9a6-f61947bbd907",
      "data": {
        "userDetailData": {
          "userId": "2854f4ff-cf55-4f19-b9a6-f61947bbd907",
          "emailId": "",
          "country": "India",
          "state": "Delhi",
          "city": "New Delhi",
          "photo": "",
          "name": "Tanisha chandra",
          "phoneNumber": "8210483450",
          "district": "South District",
          "programId": 27,
          "latitude": "28.5188016",
          "userRole": ["TRAINEE"],
          "longitude": "77.2069306",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:21:52.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T06:56:48.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:25:21.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "399e4133-e3e8-463c-b885-6fb0ca8f2257",
      "data": {
        "userDetailData": {
          "userId": "399e4133-e3e8-463c-b885-6fb0ca8f2257",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Maska",
          "photo": "",
          "name": "Kirti gor",
          "phoneNumber": "9825385585",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8362612",
          "userRole": ["TRAINEE"],
          "longitude": "69.3806552",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T06:58:06.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "bd52bd24-d067-42c5-951d-7fbddef90410",
      "data": {
        "userDetailData": {
          "userId": "bd52bd24-d067-42c5-951d-7fbddef90410",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vadnagar",
          "photo": "",
          "name": "Patel mayur rohitabhai",
          "phoneNumber": "9664790907",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7757259",
          "userRole": ["TRAINEE"],
          "longitude": "72.6165643",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:57:23.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "04d9d78f-9bbe-4cc9-85b2-d9381835154c",
      "data": {
        "userDetailData": {
          "userId": "04d9d78f-9bbe-4cc9-85b2-d9381835154c",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vadnagar",
          "photo": "",
          "name": "Patel Ankushkumar Dahyabhai",
          "phoneNumber": "9727935646",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7757259",
          "userRole": ["TRAINEE"],
          "longitude": "72.6165643",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:09.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:08:47.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "94409f12-5d4a-4efa-b825-2dc5a49051ef",
      "data": {
        "userDetailData": {
          "userId": "94409f12-5d4a-4efa-b825-2dc5a49051ef",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Idar",
          "photo": "",
          "name": "રાજપૂત કિશનસિંહ વિક્રમસિંહ",
          "phoneNumber": "7874701446",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.8524743",
          "userRole": ["TRAINEE"],
          "longitude": "73.005231",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:23.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:00:04.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b2af58c6-41d9-4a76-80c1-203456c861c2",
      "data": {
        "userDetailData": {
          "userId": "b2af58c6-41d9-4a76-80c1-203456c861c2",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Thakor sanjay k",
          "phoneNumber": "7698290878",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:45.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:57:25.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9028cbac-2720-4a86-a3c4-44b61cc48eb5",
      "data": {
        "userDetailData": {
          "userId": "9028cbac-2720-4a86-a3c4-44b61cc48eb5",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Suri",
          "photo": "",
          "name": "Rajdeep Sarkar ",
          "phoneNumber": "7679606635",
          "district": "Birbhum District",
          "programId": 27,
          "latitude": "23.8872714",
          "userRole": ["TRAINEE"],
          "longitude": "87.5232638",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo for  Prasari and BRLF",
              "issuedDate": "2020-10-01T05:58:31.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "7a99e279-a9dd-48ff-9424-a7c478b238b2",
      "data": {
        "userDetailData": {
          "userId": "7a99e279-a9dd-48ff-9424-a7c478b238b2",
          "emailId": "paliwalu75@gmail.com",
          "country": "India",
          "state": "Rajasthan",
          "city": "Bhilwara",
          "photo": "",
          "name": "Umesh",
          "phoneNumber": "7014106443",
          "district": "Bhilwara District",
          "programId": 27,
          "latitude": "25.3437480000001",
          "userRole": ["TRAINEE"],
          "longitude": "74.630977",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-04-07T09:20:44.000+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2020-04-07T10:41:14.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2020-04-07T10:49:42.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "97c83f6a-cc4b-4a2d-9749-2d6784f02c21",
      "data": {
        "userDetailData": {
          "userId": "97c83f6a-cc4b-4a2d-9749-2d6784f02c21",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Anandpar",
          "photo": "",
          "name": "Gorabhai thkor ",
          "phoneNumber": "9023540270",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.2523864",
          "userRole": ["TRAINEE"],
          "longitude": "69.3503307",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:07:07.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:40:08.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "75e35cf1-3a59-4d8a-80de-f594a7b3c086",
      "data": {
        "userDetailData": {
          "userId": "75e35cf1-3a59-4d8a-80de-f594a7b3c086",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Amravati",
          "photo": "",
          "name": "Amit Wajpe",
          "phoneNumber": "7359036664",
          "district": "Amravati District",
          "programId": 27,
          "latitude": "20.9108087",
          "userRole": ["TRAINEE"],
          "longitude": "77.7555931",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-07-13T05:16:07.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "8d96ee1c-383e-4573-84c2-5c4c5cb73b82",
      "data": {
        "userDetailData": {
          "userId": "8d96ee1c-383e-4573-84c2-5c4c5cb73b82",
          "emailId": "bhargav@arghyam.org",
          "country": "India",
          "state": "Telangana",
          "city": "Hyderabad",
          "photo": "",
          "name": "Bhargav",
          "phoneNumber": "8686143500",
          "district": "Ranga Reddy District",
          "programId": 27,
          "latitude": "17.5392623",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "78.43903710000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-10-15T06:19:57.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2020-11-20T05:39:11.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-19T10:12:36.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-04T10:07:28.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-11-04T10:39:19.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Zoom for Hubs & Spokes",
              "issuedDate": "2020-12-10T06:30:00.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-19T11:30:00.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T06:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "1074bfd7-300d-4a92-82b8-d8f2d53d569d",
      "data": {
        "userDetailData": {
          "userId": "1074bfd7-300d-4a92-82b8-d8f2d53d569d",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Miryalaguda",
          "photo": "",
          "name": "PAREPALLI DANALAXMI",
          "phoneNumber": "9391439781",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.8653811",
          "userRole": ["TRAINEE"],
          "longitude": "79.5518031",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:05:31.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b791b39b-25d7-4e24-a280-9cd6d4870c90",
      "data": {
        "userDetailData": {
          "userId": "b791b39b-25d7-4e24-a280-9cd6d4870c90",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Gagodar",
          "photo": "",
          "name": "Narshi Vibha koli",
          "phoneNumber": "7990696924",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.4091517",
          "userRole": ["TRAINEE"],
          "longitude": "70.8091245",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:40:32.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:30:20.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:47:39.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-08T07:14:13.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:03:45.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:04:58.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:36:47.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "fe1b3f7e-975e-4751-9c35-c77fce2da98b",
      "data": {
        "userDetailData": {
          "userId": "fe1b3f7e-975e-4751-9c35-c77fce2da98b",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Maska",
          "photo": "",
          "name": "Vijaysinh",
          "phoneNumber": "7984514905",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8362782",
          "userRole": ["TRAINEE"],
          "longitude": "69.3806784",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T06:57:44.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4d5ee2bb-8d8c-4e20-8c96-f0a6a0b41f29",
      "data": {
        "userDetailData": {
          "userId": "4d5ee2bb-8d8c-4e20-8c96-f0a6a0b41f29",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Hema Prasanna",
          "phoneNumber": "9844370462",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9352094",
          "userRole": ["TRAINEE"],
          "longitude": "77.6309651",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ಅಟಲ್ ಭೂಜಲ್ ಯೋಜನೆಯ ಅವಲೋಕನ",
              "issuedDate": "2021-05-25T11:26:55.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "40a677ce-f0f1-424f-a63d-5cc7a42ce1d2",
      "data": {
        "userDetailData": {
          "userId": "40a677ce-f0f1-424f-a63d-5cc7a42ce1d2",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Mota Bhadiya",
          "photo": "",
          "name": "Valji punsi gadhavi",
          "phoneNumber": "9586021083",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8581318",
          "userRole": ["TRAINEE"],
          "longitude": "69.4823645",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:58:30.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "48cb496f-d8ea-4a2d-ad8f-394c45a57566",
      "data": {
        "userDetailData": {
          "userId": "48cb496f-d8ea-4a2d-ad8f-394c45a57566",
          "emailId": "glverma06@gmail.com",
          "country": "India",
          "state": "Rajasthan",
          "city": "Udaipur",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F48cb496f-d8ea-4a2d-ad8f-394c45a57566.jpg",
          "name": "Girdhari ",
          "phoneNumber": "9664044546",
          "district": "Udaipur District",
          "programId": 27,
          "latitude": "24.585445",
          "userRole": ["TRAINEE"],
          "longitude": "73.712479",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-04-07T09:24:52.000+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2020-04-07T10:41:16.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2020-04-07T10:49:17.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:49:14+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9ab90b6e-0934-4352-95de-1422b8c24f36",
      "data": {
        "userDetailData": {
          "userId": "9ab90b6e-0934-4352-95de-1422b8c24f36",
          "emailId": "aravindathakor@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Mahesana",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F9ab90b6e-0934-4352-95de-1422b8c24f36.jpg",
          "name": "Arvindsinh A",
          "phoneNumber": "9574474872",
          "district": "Mahesana District",
          "programId": 27,
          "latitude": "23.607307",
          "userRole": ["TRAINEE"],
          "longitude": "72.3810300000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:48:14.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:20.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6beec966-3a66-4621-9ff6-6fd5c20e8787",
      "data": {
        "userDetailData": {
          "userId": "6beec966-3a66-4621-9ff6-6fd5c20e8787",
          "emailId": "dimpal@fes.org.in",
          "country": "India",
          "state": "Rajasthan",
          "city": "Bhilwara",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F6beec966-3a66-4621-9ff6-6fd5c20e8787.jpg",
          "name": "Dimpal kumari",
          "phoneNumber": "9413093672",
          "district": "Bhilwara District",
          "programId": 27,
          "latitude": "25.3437480000001",
          "userRole": ["TRAINEE"],
          "longitude": "74.630977",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-04-07T09:21:12.000+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2020-04-07T10:41:12.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2020-04-07T10:49:34.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:48:40+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "578969e2-f544-4c86-8856-accfead4ce68",
      "data": {
        "userDetailData": {
          "userId": "578969e2-f544-4c86-8856-accfead4ce68",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Dantani urmila giradhar bhai ",
          "phoneNumber": "7096300295",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7015532",
          "userRole": ["TRAINEE"],
          "longitude": "72.5395934",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:53:59.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "a592f325-0db2-4c28-a340-17a97748cc7f",
      "data": {
        "userDetailData": {
          "userId": "a592f325-0db2-4c28-a340-17a97748cc7f",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Himatnagar",
          "photo": "",
          "name": "Chandrapalsinh Rathod",
          "phoneNumber": "9870029301",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.5968857",
          "userRole": ["TRAINEE"],
          "longitude": "72.9630378",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:10.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:00:32.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "cc63685b-cf6c-467d-a178-e1ca40b8c0c8",
      "data": {
        "userDetailData": {
          "userId": "cc63685b-cf6c-467d-a178-e1ca40b8c0c8",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Patel Asu Shailesh kumar",
          "phoneNumber": "6359699170",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:53:59.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "2d88780e-c21b-45f2-a2c5-62e6e96784de",
      "data": {
        "userDetailData": {
          "userId": "2d88780e-c21b-45f2-a2c5-62e6e96784de",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Chaudhri grishma maheshbhai",
          "phoneNumber": "9428004647",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:56:00.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "94dbfffa-754e-4b5e-acf9-ba0ca73b4ea1",
      "data": {
        "userDetailData": {
          "userId": "94dbfffa-754e-4b5e-acf9-ba0ca73b4ea1",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "",
          "name": "Nawab lakha",
          "phoneNumber": "7043012799",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.2200508",
          "userRole": ["TRAINEE"],
          "longitude": "69.6503979",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - III",
              "issuedDate": "2021-08-18T11:27:36.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T08:02:26.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "aafc15ca-107f-4826-90cf-1552e978fb55",
      "data": {
        "userDetailData": {
          "userId": "aafc15ca-107f-4826-90cf-1552e978fb55",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Nagarjuna Sagar",
          "photo": "",
          "name": "S Saritha",
          "phoneNumber": "9848423672",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.5906052",
          "userRole": ["TRAINEE"],
          "longitude": "79.3178605",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:22:24.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c10b2749-563d-4edd-807d-0f68a8e37231",
      "data": {
        "userDetailData": {
          "userId": "c10b2749-563d-4edd-807d-0f68a8e37231",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Sundhiya",
          "photo": "",
          "name": "Jayeshkumar Premjiji Thakor",
          "phoneNumber": "8200019161",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.838531",
          "userRole": ["TRAINEE"],
          "longitude": "72.5618894",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:37.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ae945fe6-9a36-4989-8485-0684b9deddec",
      "data": {
        "userDetailData": {
          "userId": "ae945fe6-9a36-4989-8485-0684b9deddec",
          "emailId": "Saumya@bjup.in",
          "country": "India",
          "state": "Bihar",
          "city": "Biharsharif",
          "photo": "",
          "name": "Saumya ",
          "phoneNumber": "7903226963",
          "district": "Nalanda District",
          "programId": 27,
          "latitude": "25.1990900000001",
          "userRole": ["TRAINEE"],
          "longitude": "85.5193800000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:22.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:25:37.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T06:43:31.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f2942501-3755-4fc6-bb7e-3ba77dc15ff4",
      "data": {
        "userDetailData": {
          "userId": "f2942501-3755-4fc6-bb7e-3ba77dc15ff4",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Harsule",
          "photo": "",
          "name": "Dhanu deshmukh",
          "phoneNumber": "9623530707",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "19.8139169",
          "userRole": ["TRAINEE"],
          "longitude": "73.9584332",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-02T06:57:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "dac7dcec-ffe3-4d2a-84be-db90e490c32e",
      "data": {
        "userDetailData": {
          "userId": "dac7dcec-ffe3-4d2a-84be-db90e490c32e",
          "emailId": "arvind@cspc.org.in",
          "country": "India",
          "state": "Gujarat",
          "city": "Gandhinagar",
          "photo": "",
          "name": "Arvind Parmar",
          "phoneNumber": "9727782966",
          "district": "Gandhinagar District",
          "programId": 27,
          "latitude": "23.2120723",
          "userRole": ["TRAINEE"],
          "longitude": "72.6563777",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-07-13T05:18:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6a172e25-60cf-4796-9b03-d93e27e85946",
      "data": {
        "userDetailData": {
          "userId": "6a172e25-60cf-4796-9b03-d93e27e85946",
          "emailId": "",
          "country": "India",
          "state": "Uttar Pradesh",
          "city": "Ghaziabad",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/Stunning .png",
          "name": "Harry",
          "phoneNumber": "9986989229",
          "district": "Ghaziabad District",
          "programId": 27,
          "latitude": "28.6532572",
          "userRole": ["TRAINEE"],
          "longitude": "77.42897219999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2019-12-26T12:05:08+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2020-01-08T10:03:59+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": ["Cordio Qual 1"]
        }
      }
    },
    {
      "practitionerId": "bf4d2b2b-645e-464d-a4c2-3d3bf682a1d6",
      "data": {
        "userDetailData": {
          "userId": "bf4d2b2b-645e-464d-a4c2-3d3bf682a1d6",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Dhabda",
          "photo": "",
          "name": "Deviben Rabari",
          "phoneNumber": "8160830920",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.8704755",
          "userRole": ["TRAINEE"],
          "longitude": "70.7752031",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:31:10.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:47:46.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:06:45.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "beda4a08-6aa1-4acf-a210-bcaa529d520f",
      "data": {
        "userDetailData": {
          "userId": "beda4a08-6aa1-4acf-a210-bcaa529d520f",
          "emailId": "santoshrao@catalysts.org",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Santosh Rao ",
          "phoneNumber": "8884503047",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9715987",
          "userRole": ["TRAINEE"],
          "longitude": "77.5945627",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-02-16T15:03:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "01c4e8b7-6cb2-4f02-9f80-c4240868e4d1",
      "data": {
        "userDetailData": {
          "userId": "01c4e8b7-6cb2-4f02-9f80-c4240868e4d1",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Makhiana",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F01c4e8b7-6cb2-4f02-9f80-c4240868e4d1.jpg",
          "name": "Jadeja narpatsinh",
          "phoneNumber": "9638158627",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.0590935",
          "userRole": ["TRAINEE"],
          "longitude": "69.8286810000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:58:32.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "8e988141-44a5-4270-aef2-f61dcb0f9683",
      "data": {
        "userDetailData": {
          "userId": "8e988141-44a5-4270-aef2-f61dcb0f9683",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Kirtangali",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F8e988141-44a5-4270-aef2-f61dcb0f9683.jpg",
          "name": "Rahul vijay chavanke",
          "phoneNumber": "8766940244",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "19.9032574",
          "userRole": ["TRAINEE"],
          "longitude": "74.1386767",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-13T05:38:17.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "451170d0-83f8-4f99-9adf-db6380aa7c60",
      "data": {
        "userDetailData": {
          "userId": "451170d0-83f8-4f99-9adf-db6380aa7c60",
          "emailId": "balajivharkat@gmail.com",
          "country": "India",
          "state": "Maharashtra",
          "city": "Pune",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F451170d0-83f8-4f99-9adf-db6380aa7c60.jpg",
          "name": "Balaji Vharkat",
          "phoneNumber": "9403343043",
          "district": "Pune District",
          "programId": 27,
          "latitude": "18.5335344",
          "userRole": ["TRAINER"],
          "longitude": "73.8461891",
          "badgeDetails": [],
          "traineeData": [],
          "trainerData": [
            {
              "topicName": "Thermodynamic treatment of Hard Water",
              "issuedDate": "2020-02-20T13:29:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c14ea2c7-bd90-4f00-bfe0-2290c07b9219",
      "data": {
        "userDetailData": {
          "userId": "c14ea2c7-bd90-4f00-bfe0-2290c07b9219",
          "emailId": "",
          "country": "India",
          "state": "Meghalaya",
          "city": "Resubelpara",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fc14ea2c7-bd90-4f00-bfe0-2290c07b9219.jpg",
          "name": "Bhargov Romdung",
          "phoneNumber": "7005224220",
          "district": "North Garo Hills District",
          "programId": 27,
          "latitude": "25.8958699",
          "userRole": ["TRAINEE"],
          "longitude": "90.61318159999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-06T11:35:49.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d17cf6d3-6234-4440-a34a-dfff233b2fef",
      "data": {
        "userDetailData": {
          "userId": "d17cf6d3-6234-4440-a34a-dfff233b2fef",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Patel kamleshbhai shankarbhai ",
          "phoneNumber": "9316936240",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7015532",
          "userRole": ["TRAINEE"],
          "longitude": "72.5395934",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:29:01.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "b98b74e0-478d-4db1-805a-3e8daed1b58f",
      "data": {
        "userDetailData": {
          "userId": "b98b74e0-478d-4db1-805a-3e8daed1b58f",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Sharanya",
          "phoneNumber": "9741498985",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "13.029706",
          "userRole": ["TRAINEE"],
          "longitude": "77.693538",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ಅಟಲ್ ಭೂಜಲ್ ಯೋಜನೆಯ ಅವಲೋಕನ",
              "issuedDate": "2021-05-25T11:29:11.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "54904a4f-f5ce-42a4-84fb-19b44b7a6b04",
      "data": {
        "userDetailData": {
          "userId": "54904a4f-f5ce-42a4-84fb-19b44b7a6b04",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Jagadish k s",
          "phoneNumber": "9740790530",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9753386",
          "userRole": ["TRAINEE"],
          "longitude": "77.5877318",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ಅಂತರ್ಜಲ ಚೇತನ - ಮಾದರಿ ವಿಷಯ (Antarajala Chetana - Example Topic)",
              "issuedDate": "2021-09-02T11:05:54.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c3f8949d-3600-455d-bbd0-0806a7f16ab6",
      "data": {
        "userDetailData": {
          "userId": "c3f8949d-3600-455d-bbd0-0806a7f16ab6",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Adesar",
          "photo": "",
          "name": "Ramesh bhikhabhai",
          "phoneNumber": "9316251576",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.5580562",
          "userRole": ["TRAINEE"],
          "longitude": "70.9814281",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:40:35.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:31:38.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c5d35819-2105-4cef-8c0a-af367a875971",
      "data": {
        "userDetailData": {
          "userId": "c5d35819-2105-4cef-8c0a-af367a875971",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Madhavi Purohit",
          "phoneNumber": "7829523566",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "13.1154662",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.6069977",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-17T10:15:37.000+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:10.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-06T06:26:41.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training for Trainers | ACT",
              "issuedDate": "2020-12-11T12:41:35.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:40:45.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | ACT",
              "issuedDate": "2020-12-11T13:54:29.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:18:07.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:04:32.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-11T05:07:00.000+0000"
            },
            {
              "topicName": "Codes instead of Scans",
              "issuedDate": "2021-02-04T06:06:35.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-07-13T05:15:57.000+0000"
            },
            {
              "topicName": "भूजलाची ओळख व भूजल व्यवस्थापनाची गरज | Introduction to groundwater -Need for GW management   t",
              "issuedDate": "2021-08-30T10:08:55.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:34:43.000+0000"
            },
            {
              "topicName": "PDA - Hands on",
              "issuedDate": "2020-12-28T07:29:01.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:13.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-07-13T06:00:00.000+0000"
            }
          ],
          "roleTitle": ["Manager "],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ce251d08-52fe-4eee-a352-fb41a7503dc4",
      "data": {
        "userDetailData": {
          "userId": "ce251d08-52fe-4eee-a352-fb41a7503dc4",
          "emailId": "ravikumar@arghyam.org",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Ravi Kumar Kasetty",
          "phoneNumber": "9739442900",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.9704211",
          "userRole": ["TRAINEE"],
          "longitude": "77.6435889",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:24.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4dd30b8f-74fe-4c0a-a7aa-234f0a4cd6ac",
      "data": {
        "userDetailData": {
          "userId": "4dd30b8f-74fe-4c0a-a7aa-234f0a4cd6ac",
          "emailId": "arunm@arghyam.org",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Arun Aathreya",
          "phoneNumber": "9035325404",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.97004",
          "userRole": ["TRAINEE"],
          "longitude": "77.641733",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:13.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "387aedcd-c84e-409b-8067-b1d0e5444975",
      "data": {
        "userDetailData": {
          "userId": "387aedcd-c84e-409b-8067-b1d0e5444975",
          "emailId": "rakhi@arghyam.org",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Rakhi mathai",
          "phoneNumber": "9483548228",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9715987",
          "userRole": ["TRAINEE"],
          "longitude": "77.5945627",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-05T06:59:10+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-05T07:26:32+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:16.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-06T06:26:45.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:43.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "950b76fc-6de2-4102-9af7-1df1b415726b",
      "data": {
        "userDetailData": {
          "userId": "950b76fc-6de2-4102-9af7-1df1b415726b",
          "emailId": "",
          "country": "India",
          "state": "Haryana",
          "city": "Gurugram",
          "photo": "",
          "name": "Isha Dash ",
          "phoneNumber": "9979216559",
          "district": "Gurugram District",
          "programId": 27,
          "latitude": "28.4129271",
          "userRole": ["TRAINEE"],
          "longitude": "77.0467824",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:40.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "1b150e3f-f854-4a66-9e73-3e8d856706a1",
      "data": {
        "userDetailData": {
          "userId": "1b150e3f-f854-4a66-9e73-3e8d856706a1",
          "emailId": "",
          "country": "India",
          "state": "Delhi",
          "city": "New Delhi",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/1b150e3f-f854-4a66-9e73-3e8d856706a155a347f6-cb50-4819-ae00-9bfff199e37c",
          "name": "Abhishek srivastava ",
          "phoneNumber": "9999180790",
          "district": "South East Delhi District",
          "programId": 27,
          "latitude": "28.53148148397778",
          "userRole": ["TRAINEE"],
          "longitude": "77.29401170283303",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-12-10T05:33:03.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-12-10T11:35:30.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-12-10T11:37:49.000+0000"
            },
            {
              "topicName": "Codes instead of Scans",
              "issuedDate": "2021-02-04T06:06:15.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:06.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c0a06ec3-5563-40f7-a987-aea8fe29f08b",
      "data": {
        "userDetailData": {
          "userId": "c0a06ec3-5563-40f7-a987-aea8fe29f08b",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "",
          "name": "Nagji bhai",
          "phoneNumber": "9265044356",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.2200502",
          "userRole": ["TRAINEE"],
          "longitude": "69.6503971",
          "badgeDetails": [
            {
              "badgeId": 248,
              "badgeName": "Oct1"
            }
          ],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - III",
              "issuedDate": "2021-08-18T11:27:37.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:34:20.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "60c6a9c3-268e-4b5c-9939-d0efdcaa04cb",
      "data": {
        "userDetailData": {
          "userId": "60c6a9c3-268e-4b5c-9939-d0efdcaa04cb",
          "emailId": "rgjadeja777@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F60c6a9c3-268e-4b5c-9939-d0efdcaa04cb.jpg",
          "name": "Raghuvirsinh Jadeja",
          "phoneNumber": "7990962283",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.237696",
          "userRole": ["TRAINEE"],
          "longitude": "69.6653190000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - III",
              "issuedDate": "2021-08-18T11:27:54.000+0000"
            },
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:24:00.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:53:06.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:49:11.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "971f2670-d929-4a00-a697-f5e19fa95fff",
      "data": {
        "userDetailData": {
          "userId": "971f2670-d929-4a00-a697-f5e19fa95fff",
          "emailId": "yogeshjadeja2129@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "",
          "name": "Yogesh Jadeja",
          "phoneNumber": "9879580931",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.2419997",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "69.6669324",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-17T10:15:12.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:52:36.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:49:05.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T09:07:18.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:30:00.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:30:00.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T12:30:00.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:30:00.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T08:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c3806dc6-ac06-488b-8e75-88e2cae9c1a6",
      "data": {
        "userDetailData": {
          "userId": "c3806dc6-ac06-488b-8e75-88e2cae9c1a6",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bidada",
          "photo": "",
          "name": "Rajesh harji sangar",
          "phoneNumber": "9537815266",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8953953",
          "userRole": ["TRAINEE"],
          "longitude": "69.4774302",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:24:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "64e5cd3d-c6f3-4cfa-9c0f-6116391e6b86",
      "data": {
        "userDetailData": {
          "userId": "64e5cd3d-c6f3-4cfa-9c0f-6116391e6b86",
          "emailId": "Jadejasahdev79@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Bidada",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/64e5cd3d-c6f3-4cfa-9c0f-6116391e6b8631b0dfb7-0968-4b0c-aef9-396f1f0ea0d4",
          "name": "Sahadevsinh",
          "phoneNumber": "9265649418",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8984669",
          "userRole": ["TRAINEE"],
          "longitude": "69.4652327",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:56:52.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:14:18.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f5c71709-8bfc-4fe9-a633-559aeff445e6",
      "data": {
        "userDetailData": {
          "userId": "f5c71709-8bfc-4fe9-a633-559aeff445e6",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/f5c71709-8bfc-4fe9-a633-559aeff445e6f5cdb271-3b78-4641-a8d4-f6d18f403cb2",
          "name": "Nita Khubchandani ",
          "phoneNumber": "8141477361",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.2200511",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "69.6503972",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:24:14.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:16:17.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:15:56.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T12:30:00.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "b57134f1-23ce-48b9-bc6a-1e90a88d144f",
      "data": {
        "userDetailData": {
          "userId": "b57134f1-23ce-48b9-bc6a-1e90a88d144f",
          "emailId": "",
          "country": "India",
          "state": "Uttarakhand",
          "city": "Kokil Bana",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/b57134f1-23ce-48b9-bc6a-1e90a88d144f38158350-136b-40ff-b71e-db0b1fe7f15d",
          "name": "Goutam",
          "phoneNumber": "9994483638",
          "district": "Nainital District",
          "programId": 27,
          "latitude": "29.45626",
          "userRole": ["TRAINEE"],
          "longitude": "79.6233652",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Understanding the Functional Grid",
              "issuedDate": "2022-04-26T07:03:11.000+0000"
            },
            {
              "topicName": "Introduction to mapping of content and learning needs requirements",
              "issuedDate": "2022-05-04T07:26:15.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "2adeac0d-3c9c-4429-9f7f-966c8169483d",
      "data": {
        "userDetailData": {
          "userId": "2adeac0d-3c9c-4429-9f7f-966c8169483d",
          "emailId": "",
          "country": "India",
          "state": "Rajasthan",
          "city": "Udaipur",
          "photo": "",
          "name": "Priyanka sarthi",
          "phoneNumber": "8103407062",
          "district": "Udaipur District",
          "programId": 27,
          "latitude": "24.6099931",
          "userRole": ["TRAINEE"],
          "longitude": "73.6850753",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to mapping of content and learning needs requirements",
              "issuedDate": "2022-05-04T07:27:58.000+0000"
            },
            {
              "topicName": "Introduction to prioritization and planning for content creation",
              "issuedDate": "2022-05-10T06:25:37.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "95cc9688-64d4-4109-925d-47172ca52bbd",
      "data": {
        "userDetailData": {
          "userId": "95cc9688-64d4-4109-925d-47172ca52bbd",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vijapur",
          "photo": "",
          "name": "Pinu ashish Chaudhari ",
          "phoneNumber": "8140909920",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.5609405",
          "userRole": ["TRAINEE"],
          "longitude": "72.7510921",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:57:16.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d91fddd8-9b50-4dff-ac4e-1243c9c5b71f",
      "data": {
        "userDetailData": {
          "userId": "d91fddd8-9b50-4dff-ac4e-1243c9c5b71f",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Amin bhoomi jayantibhai",
          "phoneNumber": "6356017065",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:53:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "675d29ab-9a37-4bb1-9cf2-013077eef6bd",
      "data": {
        "userDetailData": {
          "userId": "675d29ab-9a37-4bb1-9cf2-013077eef6bd",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Vijit",
          "phoneNumber": "9999452004",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9715987",
          "userRole": ["TRAINEE"],
          "longitude": "77.5945627",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Content and PDA - Capacity Building Package",
              "issuedDate": "2020-12-21T11:18:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ffdb4a54-b6d8-4e82-8f3e-b9cce836b67e",
      "data": {
        "userDetailData": {
          "userId": "ffdb4a54-b6d8-4e82-8f3e-b9cce836b67e",
          "emailId": "krushnaindev@gmail.com",
          "country": "India",
          "state": "Odisha",
          "city": "Bhubaneswar",
          "photo": "",
          "name": "Krushna mohan Mishra",
          "phoneNumber": "9437368326",
          "district": "Khordha District",
          "programId": 27,
          "latitude": "20.2533622",
          "userRole": ["TRAINEE"],
          "longitude": "85.8045137",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:48:25+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "084fbd3e-1924-4484-a80f-f46e23e5c0df",
      "data": {
        "userDetailData": {
          "userId": "084fbd3e-1924-4484-a80f-f46e23e5c0df",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Mota Bhadiya",
          "photo": "",
          "name": "Pabu Baha'i gadhavi",
          "phoneNumber": "9904821501",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8552771",
          "userRole": ["TRAINEE"],
          "longitude": "69.4875785",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:59:01.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d654a1c1-ac64-408b-91a1-54d57c883590",
      "data": {
        "userDetailData": {
          "userId": "d654a1c1-ac64-408b-91a1-54d57c883590",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Alwala",
          "photo": "",
          "name": "Basipaka kamalabai",
          "phoneNumber": "9505870239",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.7411756",
          "userRole": ["TRAINEE"],
          "longitude": "79.3035298",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:22:25.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "aa8b852d-d69b-4341-a0fa-b8926901b4e9",
      "data": {
        "userDetailData": {
          "userId": "aa8b852d-d69b-4341-a0fa-b8926901b4e9",
          "emailId": "arvindfes@gmail.com",
          "country": "India",
          "state": "Rajasthan",
          "city": "Basni Silawatan",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2Faa8b852d-d69b-4341-a0fa-b8926901b4e9.jpg",
          "name": "Arvind Agarwal",
          "phoneNumber": "9214665925",
          "district": "Jodhpur District",
          "programId": 27,
          "latitude": "26.1831843",
          "userRole": ["TRAINEE"],
          "longitude": "72.9431038",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-04-07T09:21:17.000+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2020-04-07T10:41:22.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2020-04-07T10:49:28.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:49:10+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "441fc2a7-b992-46e0-951f-2a58ae3bdf32",
      "data": {
        "userDetailData": {
          "userId": "441fc2a7-b992-46e0-951f-2a58ae3bdf32",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Gagodar",
          "photo": "",
          "name": "Pawan Atkari",
          "phoneNumber": "9595924476",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.4115449",
          "userRole": ["TRAINEE"],
          "longitude": "70.8137048",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:39:44.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-17T10:15:25.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-08T07:51:10.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:39:04.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:21:44.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:04:16.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:05:18.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:37:03.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f3c005ad-b9e2-4a45-9900-60bdcced16b3",
      "data": {
        "userDetailData": {
          "userId": "f3c005ad-b9e2-4a45-9900-60bdcced16b3",
          "emailId": "johnson@vrutti.org",
          "country": "India",
          "state": "Tamil Nadu",
          "city": "Aruppukkottai",
          "photo": "",
          "name": "Johnson Thangaraj ",
          "phoneNumber": "9344410890",
          "district": "Virudhunagar District",
          "programId": 27,
          "latitude": "9.5281071",
          "userRole": ["TRAINEE"],
          "longitude": "78.09723249999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-25T06:11:13.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "0b54ff54-02a9-4863-a5a2-c7e9ecf75dfb",
      "data": {
        "userDetailData": {
          "userId": "0b54ff54-02a9-4863-a5a2-c7e9ecf75dfb",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Sardarpur",
          "photo": "",
          "name": "Jigarji Rameshji Chauhan ",
          "phoneNumber": "9979496318",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.6888648",
          "userRole": ["TRAINEE"],
          "longitude": "72.7680838",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T09:36:35.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "3d5cbfd9-a017-48c1-9ba0-a5e40b0ad188",
      "data": {
        "userDetailData": {
          "userId": "3d5cbfd9-a017-48c1-9ba0-a5e40b0ad188",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Chauhan Arati vishnusinh",
          "phoneNumber": "8140337798",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7015532",
          "userRole": ["TRAINEE"],
          "longitude": "72.5395934",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:54:14.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e4fc5fe0-87c6-4077-99be-ed1070f50b56",
      "data": {
        "userDetailData": {
          "userId": "e4fc5fe0-87c6-4077-99be-ed1070f50b56",
          "emailId": "umesh.desai@akdn.org",
          "country": "India",
          "state": "Gujarat",
          "city": "Ahmedabad",
          "photo": "",
          "name": "Umesh Desai ",
          "phoneNumber": "9925239319",
          "district": "Ahmedabad District",
          "programId": 27,
          "latitude": "23.018652",
          "userRole": ["TRAINEE"],
          "longitude": "72.5977130000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity Building @ Scale - Experiences from FES in Odisha",
              "issuedDate": "2021-06-04T12:08:15.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "3a089d0b-0cea-42c8-a60a-dd9fb54e25d4",
      "data": {
        "userDetailData": {
          "userId": "3a089d0b-0cea-42c8-a60a-dd9fb54e25d4",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Gagodar",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F3a089d0b-0cea-42c8-a60a-dd9fb54e25d4.jpg",
          "name": "Dungarbhai Dodiya",
          "phoneNumber": "9979915781",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.4109223",
          "userRole": ["TRAINEE"],
          "longitude": "70.81384109999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:37:19.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:34:50.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:47:29.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-08T07:14:07.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:03:31.000+0000"
            },
            {
              "topicName": "Jaldoots capacity building on Geo-hydrology",
              "issuedDate": "2021-01-08T16:47:54.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:05:07.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:36:51.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f8ac18cb-5476-4655-b1b7-6459e8047e4d",
      "data": {
        "userDetailData": {
          "userId": "f8ac18cb-5476-4655-b1b7-6459e8047e4d",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Badalpar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Ff8ac18cb-5476-4655-b1b7-6459e8047e4d.jpg",
          "name": "Ramesh Gela",
          "phoneNumber": "9327803738",
          "district": "Junagadh District",
          "programId": 27,
          "latitude": "21.3879541",
          "userRole": ["TRAINEE"],
          "longitude": "70.5714859",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:41:32.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:31:50.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:32:01.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:11:08.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:54:01.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d2724a98-724b-452f-8abe-d9a4492b3f21",
      "data": {
        "userDetailData": {
          "userId": "d2724a98-724b-452f-8abe-d9a4492b3f21",
          "emailId": "aditya@srijanindia.org",
          "country": "India",
          "state": "Delhi",
          "city": "New Delhi",
          "photo": "",
          "name": "Aditya Kumar",
          "phoneNumber": "7587429075",
          "district": "West District",
          "programId": 27,
          "latitude": "28.6177911",
          "userRole": ["TRAINEE"],
          "longitude": "77.0464039",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:22:01.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T08:35:16.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "cf25e048-3020-4503-a358-dd9be7c7c117",
      "data": {
        "userDetailData": {
          "userId": "cf25e048-3020-4503-a358-dd9be7c7c117",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Thakor gandaji",
          "phoneNumber": "9313731155",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.6916781",
          "userRole": ["TRAINEE"],
          "longitude": "72.5474971",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:00:28.000+0000"
            },
            {
              "topicName": "Wheat Crop Training",
              "issuedDate": "2020-12-31T10:12:10.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:55:16.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "3834ed59-d53b-4c72-8c36-9b8c146bd2d4": {
        "data": {
          "userDetailData": {
            "userId": "3834ed59-d53b-4c72-8c36-9b8c146bd2d4",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Chandiya",
            "photo": "",
            "name": "Ravubha jadeja",
            "phoneNumber": "9978735955",
            "district": "Kachchh District",
            "programId": 27,
            "latitude": "23.0649887",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "69.846742",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Orientation on Abhy and PGWM - II",
                "issuedDate": "2021-08-14T11:57:45.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "c37080b4-1d4c-4a2c-98fd-8c7d6b78e36a": {
        "data": {
          "userDetailData": {
            "userId": "c37080b4-1d4c-4a2c-98fd-8c7d6b78e36a",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Gagodar",
            "photo": "",
            "name": "Dinesh Oza",
            "phoneNumber": "9687382010",
            "district": "Kutch District",
            "programId": 27,
            "latitude": "23.409592",
            "userRole": [
              "TRAINER",
              "TRAINEE"
            ],
            "longitude": "70.81348179999999",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-02-16T06:41:39.000+0000"
              },
              {
                "topicName": "Community Participation-Why",
                "issuedDate": "2021-04-09T06:46:04.000+0000"
              },
              {
                "topicName": "Jaldoots capacity building on Geo-hydrology",
                "issuedDate": "2021-01-08T16:47:53.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
                "issuedDate": "2021-01-11T07:37:19.000+0000"
              }
            ],
            "trainerData": [
              {
                "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
                "issuedDate": "2021-03-06T10:30:00.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-02-16T06:35:00.000+0000"
              },
              {
                "topicName": "Community Participation-Why",
                "issuedDate": "2021-04-09T06:30:00.000+0000"
              },
              {
                "topicName": "Jaldoots capacity building on Geo-hydrology",
                "issuedDate": "2021-01-08T16:32:00.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-01-30T09:02:00.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
                "issuedDate": "2021-01-11T07:32:00.000+0000"
              }
            ],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "85238df0-66a9-4a1c-bd4e-05f408801f95": {
        "data": {
          "userDetailData": {
            "userId": "85238df0-66a9-4a1c-bd4e-05f408801f95",
            "emailId": "",
            "country": "India",
            "state": "Telangana",
            "city": "Miryalaguda",
            "photo": "",
            "name": "R Vani supervisor Icds Damarcherla ",
            "phoneNumber": "8179076652",
            "district": "Nalgonda District",
            "programId": 27,
            "latitude": "16.8652236",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "79.5777359",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Demonstration of PDA - ICDS Supervisors Training",
                "issuedDate": "2021-06-10T11:17:13.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "32d0595c-a70f-45b4-b100-fa671df6566a": {
        "data": {
          "userDetailData": {
            "userId": "32d0595c-a70f-45b4-b100-fa671df6566a",
            "emailId": "",
            "country": "India",
            "state": "Odisha",
            "city": "Bhubaneswar",
            "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F32d0595c-a70f-45b4-b100-fa671df6566a.jpg",
            "name": "Rakesh singh",
            "phoneNumber": "9963195903",
            "district": "Khordha District",
            "programId": 27,
            "latitude": "20.2960587",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "85.8245398",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "PDA - Participant App",
                "issuedDate": "2020-04-07T09:21:28.000+0000"
              },
              {
                "topicName": "PDA - Participant App",
                "issuedDate": "2020-03-18T06:48:22+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "8100d8fe-7ff4-4706-8fa7-144707e7d3ce": {
        "data": {
          "userDetailData": {
            "userId": "8100d8fe-7ff4-4706-8fa7-144707e7d3ce",
            "emailId": "",
            "country": "India",
            "state": "Maharashtra",
            "city": "Harsule",
            "photo": "",
            "name": "Rhishikesh Dange",
            "phoneNumber": "9975805011",
            "district": "Nashik District",
            "programId": 27,
            "latitude": "19.8103583",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "73.9447994",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Midterm Measurement of Desilting(Jalsamruddhi)",
                "issuedDate": "2021-02-20T11:38:17.000+0000"
              },
              {
                "topicName": "PDA Hands on",
                "issuedDate": "2021-01-02T07:01:32.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "f15c3598-11e2-4c1f-8cda-d714a5c36867": {
        "data": {
          "userDetailData": {
            "userId": "f15c3598-11e2-4c1f-8cda-d714a5c36867",
            "emailId": "Jayprakash.singh@akdn.org",
            "country": "India",
            "state": "Bihar",
            "city": "Muzaffarpur",
            "photo": "",
            "name": "Jay prakash singh",
            "phoneNumber": "9771476639",
            "district": "Muzaffarpur District",
            "programId": 27,
            "latitude": "26.116386",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "85.36658709999999",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Introduction to Learning Objectives",
                "issuedDate": "2022-02-22T07:24:09.000+0000"
              },
              {
                "topicName": "Introduction to atomised content",
                "issuedDate": "2022-02-15T06:26:09.000+0000"
              },
              {
                "topicName": "Capacity Building @ Scale - Experiences from FES in Odisha",
                "issuedDate": "2021-06-04T11:23:52.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "80e5cc2d-48a2-4561-a840-c88ef88bf6ba": {
        "data": {
          "userDetailData": {
            "userId": "80e5cc2d-48a2-4561-a840-c88ef88bf6ba",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Budharmora",
            "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F80e5cc2d-48a2-4561-a840-c88ef88bf6ba.jpg",
            "name": "Dhiraj manji sathawara",
            "phoneNumber": "9925744720",
            "district": "Kachchh District",
            "programId": 27,
            "latitude": "23.3330172",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "70.1878132",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Orientation on Abhy and PGWM",
                "issuedDate": "2021-08-14T07:15:57.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "2307df28-88c9-4a61-a906-3a8448379edf": {
        "data": {
          "userDetailData": {
            "userId": "2307df28-88c9-4a61-a906-3a8448379edf",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Makhiana",
            "photo": "",
            "name": "Rajdipsinh jitubha jadeja",
            "phoneNumber": "9712188358",
            "district": "Kachchh District",
            "programId": 27,
            "latitude": "23.0590935",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "69.8286810000001",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Orientation on Abhy and PGWM - II",
                "issuedDate": "2021-08-14T12:31:42.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "6db52b87-7d08-4b7a-98da-0ab423f15330": {
        "data": {
          "userDetailData": {
            "userId": "6db52b87-7d08-4b7a-98da-0ab423f15330",
            "emailId": "",
            "country": "ભારત",
            "state": "Gujarat",
            "city": "Kidiyanagar",
            "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F6db52b87-7d08-4b7a-98da-0ab423f15330.jpg",
            "name": "Rabari rama soma",
            "phoneNumber": "9574188466",
            "district": "Kutch",
            "programId": 27,
            "latitude": "23.4802404",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "70.780591",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-02-16T06:53:53.000+0000"
              },
              {
                "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
                "issuedDate": "2021-03-06T10:31:22.000+0000"
              },
              {
                "topicName": "Community Participation-Why",
                "issuedDate": "2021-04-09T06:46:26.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-01-30T09:11:52.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
                "issuedDate": "2021-01-11T07:54:35.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "a9dd0338-e5a1-4617-b94d-06b3b66f93d6": {
        "data": {
          "userDetailData": {
            "userId": "a9dd0338-e5a1-4617-b94d-06b3b66f93d6",
            "emailId": "",
            "country": "India",
            "state": "Maharashtra",
            "city": "Pimpri Chinchwad",
            "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fa9dd0338-e5a1-4617-b94d-06b3b66f93d6.jpg",
            "name": "Jairaj Rajguru",
            "phoneNumber": "9960950766",
            "district": "Pune District",
            "programId": 27,
            "latitude": "18.665944",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "73.8014775",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "PDA Demo",
                "issuedDate": "2021-08-27T06:50:19.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "65d158bb-b5e6-47d1-9fcb-fe4c9584b8de": {
        "data": {
          "userDetailData": {
            "userId": "65d158bb-b5e6-47d1-9fcb-fe4c9584b8de",
            "emailId": "",
            "country": "India",
            "state": "Uttar Pradesh",
            "city": "Greater Noida",
            "photo": "",
            "name": "Ashish",
            "phoneNumber": "9711730205",
            "district": "Gautam Buddha Nagar District",
            "programId": 27,
            "latitude": "28.6042202",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "77.4371316",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
                "issuedDate": "2021-06-02T10:03:37.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "8015b555-310f-4896-be19-6801c9d16a40": {
        "data": {
          "userDetailData": {
            "userId": "8015b555-310f-4896-be19-6801c9d16a40",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Gagodar",
            "photo": "",
            "name": "Balavantbhai",
            "phoneNumber": "9879882298",
            "district": "Kutch District",
            "programId": 27,
            "latitude": "23.409592",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "70.81348179999999",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
                "issuedDate": "2021-03-06T10:34:49.000+0000"
              },
              {
                "topicName": "Community Participation-Why",
                "issuedDate": "2021-04-09T06:49:01.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-01-30T09:07:01.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
                "issuedDate": "2021-01-11T07:37:06.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "561f2614-a9a4-4fea-8f74-fd79e0a2854a": {
        "data": {
          "userDetailData": {
            "userId": "561f2614-a9a4-4fea-8f74-fd79e0a2854a",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Davri",
            "photo": "",
            "name": "Umed Makwana ",
            "phoneNumber": "9978731131",
            "district": "Kutch District",
            "programId": 27,
            "latitude": "23.7080056",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "70.6402683",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-02-16T06:47:53.000+0000"
              },
              {
                "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
                "issuedDate": "2021-03-06T10:31:17.000+0000"
              },
              {
                "topicName": "Community Participation-Why",
                "issuedDate": "2021-04-09T06:51:59.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
                "issuedDate": "2021-01-30T09:07:29.000+0000"
              },
              {
                "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
                "issuedDate": "2021-01-11T07:39:52.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      },
      "3ae84d02-8c12-4a0e-bd85-d287c286b91d": {
        "data": {
          "userDetailData": {
            "userId": "3ae84d02-8c12-4a0e-bd85-d287c286b91d",
            "emailId": "bansara_marb@yahoo.co.in",
            "country": "India",
            "state": "Meghalaya",
            "city": "Shillong",
            "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F3ae84d02-8c12-4a0e-bd85-d287c286b91d.jpg",
            "name": "Bansara Marbaniang",
            "phoneNumber": "8794319930",
            "district": "East Khasi Hills District",
            "programId": 27,
            "latitude": "25.565063",
            "userRole": [
              "TRAINER"
            ],
            "longitude": "91.90198099999999",
            "badgeDetails": [],
            "traineeData": [],
            "trainerData": [
              {
                "topicName": "Test run for Assisted Scanning",
                "issuedDate": "2020-08-05T13:10:00.000+0000"
              }
            ],
            "roleTitle": [],
            "qualificationTitle": [
              "Masters in Human Resource Management"
            ]
          }
        }
      },
      "1e7b369b-6485-42d8-b46f-4d93173de143": {
        "data": {
          "userDetailData": {
            "userId": "1e7b369b-6485-42d8-b46f-4d93173de143",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Vadnagar",
            "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F1e7b369b-6485-42d8-b46f-4d93173de143.jpg",
            "name": "Pooja Prajapati ",
            "phoneNumber": "9924168627",
            "district": "Mehsana District",
            "programId": 27,
            "latitude": "23.7757259",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "72.6165643",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "PDA Rollout Demo",
                "issuedDate": "2021-05-04T05:47:58.000+0000"
              },
              {
                "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
                "issuedDate": "2021-05-05T06:57:20.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
  
  
  
  
  
  
  
    {
      "practitionerId": "04d19f6e-f331-4630-a51a-5aa2ea55bdcc",
      "data": {
        "userDetailData": {
          "userId": "04d19f6e-f331-4630-a51a-5aa2ea55bdcc",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vadnagar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F04d19f6e-f331-4630-a51a-5aa2ea55bdcc.jpg",
          "name": "Prajapati Vishal Baldevbhai",
          "phoneNumber": "8140107303",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7757259",
          "userRole": ["TRAINEE"],
          "longitude": "72.6165643",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:16.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:04.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "7047e907-28a3-4cb1-9797-30c04e005740",
      "data": {
        "userDetailData": {
          "userId": "7047e907-28a3-4cb1-9797-30c04e005740",
          "emailId": "",
          "country": "India",
          "state": "Bihar",
          "city": "Bodh Gaya",
          "photo": "",
          "name": "Vidyanshu Kumar",
          "phoneNumber": "9304716453",
          "district": "Gaya District",
          "programId": 27,
          "latitude": "24.6992803",
          "userRole": ["TRAINEE"],
          "longitude": "84.9748469",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity Building @ Scale - Experiences from FES in Odisha",
              "issuedDate": "2021-06-04T11:23:54.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ab701420-82f6-492b-bc21-e0ac11333177",
      "data": {
        "userDetailData": {
          "userId": "ab701420-82f6-492b-bc21-e0ac11333177",
          "emailId": "nitishkeri@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Kiran Keri",
          "phoneNumber": "9886087494",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9120883",
          "userRole": ["TRAINEE"],
          "longitude": "77.5879169",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ಅಟಲ್ ಭೂಜಲ್ ಯೋಜನೆಯ ಅವಲೋಕನ",
              "issuedDate": "2021-05-25T11:26:54.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4f4578dd-db88-4474-8d4a-cb9f922009a9",
      "data": {
        "userDetailData": {
          "userId": "4f4578dd-db88-4474-8d4a-cb9f922009a9",
          "emailId": "NA",
          "country": "India",
          "state": "West Bengal",
          "city": "Chalsa",
          "photo": "",
          "name": "Nabamita Guha",
          "phoneNumber": "NA",
          "district": "Jalpaiguri District",
          "programId": 27,
          "latitude": "26.8812608",
          "userRole": ["TRAINEE"],
          "longitude": "88.80271019999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T10:41:37.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "534ec60b-0deb-41a6-97f8-2b5477f9c3a3",
      "data": {
        "userDetailData": {
          "userId": "534ec60b-0deb-41a6-97f8-2b5477f9c3a3",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Manisha Shah",
          "phoneNumber": "7406646122",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9108754",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.68446449999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T10:15:31.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-05T06:58:53+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-05T07:25:57+0000"
            },
            {
              "topicName": "ಅಟಲ್ ಭೂಜಲ್ ಯೋಜನೆಯ ಅವಲೋಕನ",
              "issuedDate": "2021-05-25T11:26:31.000+0000"
            },
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-07T07:11:07.000+0000"
            },
            {
              "topicName": "PDA Additional features",
              "issuedDate": "2021-01-11T05:07:13.000+0000"
            },
            {
              "topicName": "Codes instead of Scans",
              "issuedDate": "2021-02-04T06:06:42.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:43.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "PDA structure Demo",
              "issuedDate": "2022-03-03T12:26:00.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T11:00:00.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-01-14T10:32:00.000+0000"
            },
            {
              "topicName": "Something went wrong",
              "issuedDate": "2020-02-17T13:22:00.000+0000"
            },
            {
              "topicName": "Topic editing - Test",
              "issuedDate": "2020-05-29T05:43:00.000+0000"
            },
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-07T07:24:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "1945733f-b5d8-4581-bb93-677e47af2892",
      "data": {
        "userDetailData": {
          "userId": "1945733f-b5d8-4581-bb93-677e47af2892",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Katwad",
          "photo": "",
          "name": "Arvindbhai",
          "phoneNumber": "9974827946",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.5290188",
          "userRole": ["TRAINEE"],
          "longitude": "72.90176029999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:59:12.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "827d361a-0594-4274-9856-374c4c2d09ca",
      "data": {
        "userDetailData": {
          "userId": "827d361a-0594-4274-9856-374c4c2d09ca",
          "emailId": "kamlesh@cspc.org.in",
          "country": "India",
          "state": "Gujarat",
          "city": "Mahesana",
          "photo": "",
          "name": "Kamlesh ",
          "phoneNumber": "7069655266",
          "district": "Mahesana District",
          "programId": 27,
          "latitude": "23.6154181",
          "userRole": ["TRAINEE"],
          "longitude": "72.4008476",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-07-13T05:15:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "978c03c9-6857-455f-b955-129c9f5d70e9",
      "data": {
        "userDetailData": {
          "userId": "978c03c9-6857-455f-b955-129c9f5d70e9",
          "emailId": "niteshkumar@srijanindia.org",
          "country": "India",
          "state": "Rajasthan",
          "city": "Sawai Madhopur",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F978c03c9-6857-455f-b955-129c9f5d70e9.jpg",
          "name": "Nitesh Kumar",
          "phoneNumber": "7073449862",
          "district": "Sawai Madhopur District",
          "programId": 27,
          "latitude": "25.99548",
          "userRole": ["TRAINEE"],
          "longitude": "76.3567714",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:45:30.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:26:01.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T06:44:15.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:16.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "45147e6f-3094-4afd-8fc9-57575771296a",
      "data": {
        "userDetailData": {
          "userId": "45147e6f-3094-4afd-8fc9-57575771296a",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Himatnagar",
          "photo": "",
          "name": "Baranda Anupa",
          "phoneNumber": "7984766891",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.5968857",
          "userRole": ["TRAINEE"],
          "longitude": "72.9630378",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:31.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "672fb9f9-8ea1-4cc8-b905-a35e69ce645c",
      "data": {
        "userDetailData": {
          "userId": "672fb9f9-8ea1-4cc8-b905-a35e69ce645c",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Anupama patel",
          "phoneNumber": "9723539024",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7006791",
          "userRole": ["TRAINEE"],
          "longitude": "72.5406323",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Wheat Crop Training",
              "issuedDate": "2020-12-31T10:12:06.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:57:06.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "49762a84-722e-4ae9-be16-ba5123bd74fa",
      "data": {
        "userDetailData": {
          "userId": "49762a84-722e-4ae9-be16-ba5123bd74fa",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vijapur",
          "photo": "",
          "name": "Dashrathsinh Rathod",
          "phoneNumber": "7984813229",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.5609405",
          "userRole": ["TRAINEE"],
          "longitude": "72.7510921",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:58.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:23:33.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c4dd62bb-64be-451a-a6b8-128879daf705",
      "data": {
        "userDetailData": {
          "userId": "c4dd62bb-64be-451a-a6b8-128879daf705",
          "emailId": "",
          "country": "India",
          "state": "Odisha",
          "city": "Bhubaneswar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fc4dd62bb-64be-451a-a6b8-128879daf705.jpg",
          "name": "Biranchi Paikaray ",
          "phoneNumber": "7008781105",
          "district": "Khordha District",
          "programId": 27,
          "latitude": "20.2960587",
          "userRole": ["TRAINEE"],
          "longitude": "85.8245398",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:49:01+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "24fd8100-05dc-4818-ac5f-ae56daae290f",
      "data": {
        "userDetailData": {
          "userId": "24fd8100-05dc-4818-ac5f-ae56daae290f",
          "emailId": "",
          "country": "India",
          "state": "Andhra Pradesh",
          "city": "Kalyandurg",
          "photo": "",
          "name": "Gona venkatamma",
          "phoneNumber": "7658930865",
          "district": "Anantapur District",
          "programId": 27,
          "latitude": "14.5511080000001",
          "userRole": ["TRAINEE"],
          "longitude": "77.111073",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:05:34.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "68d36af3-395e-424e-9f89-2bc8dcec7b40",
      "data": {
        "userDetailData": {
          "userId": "68d36af3-395e-424e-9f89-2bc8dcec7b40",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Jamgaon",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F68d36af3-395e-424e-9f89-2bc8dcec7b40.jpg",
          "name": "Sunita Suresh Ghegadmal",
          "phoneNumber": "8850443566",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "19.8486075",
          "userRole": ["TRAINEE"],
          "longitude": "73.9245925",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Water User Association Training",
              "issuedDate": "2021-04-07T06:27:56.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e07661b3-b906-4829-aa72-c5c2d4b6cff5",
      "data": {
        "userDetailData": {
          "userId": "e07661b3-b906-4829-aa72-c5c2d4b6cff5",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Himatnagar",
          "photo": "",
          "name": "PARMAR mahipa ",
          "phoneNumber": "6354925222",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.5881482",
          "userRole": ["TRAINEE"],
          "longitude": "72.95343849999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:47:39.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:21:08.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "623772f6-e951-4704-ad07-d8e1b852bee3",
      "data": {
        "userDetailData": {
          "userId": "623772f6-e951-4704-ad07-d8e1b852bee3",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Pimparkhed",
          "photo": "",
          "name": "Ajay Satish Mahatme ",
          "phoneNumber": "9209240447",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "20.3093759",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "74.7817795",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Midterm Measurement of Desilting(Jalsamruddhi)",
              "issuedDate": "2021-02-20T11:14:05.000+0000"
            },
            {
              "topicName": "Site Selection of Desilting work Process of Government Approval and data Collection of its work",
              "issuedDate": "2021-02-06T12:41:22.000+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:07.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:39:07.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-02T06:57:06.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:18:20.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:34:31.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Water User Association Training",
              "issuedDate": "2021-04-03T06:30:00.000+0000"
            },
            {
              "topicName": "Site Selection of Desilting work Process of Government Approval and data Collection of its work",
              "issuedDate": "2021-02-06T12:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-23T05:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-20T06:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-05T11:30:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-07T06:30:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-21T06:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-22T05:00:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-13T05:30:00.000+0000"
            },
            {
              "topicName": "Water Literacy Campaign",
              "issuedDate": "2021-01-06T10:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "e91cf5ba-cf27-4a89-8cec-dc7423a1fac5",
      "data": {
        "userDetailData": {
          "userId": "e91cf5ba-cf27-4a89-8cec-dc7423a1fac5",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Gundiyali",
          "photo": "",
          "name": "Ramesh gor",
          "phoneNumber": "7069868878",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8363420855183",
          "userRole": ["TRAINEE"],
          "longitude": "69.4190530559504",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:24:10.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:53:31.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:38:36.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:23:50.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b5796159-916b-4366-8cda-b3bc91f214ec",
      "data": {
        "userDetailData": {
          "userId": "b5796159-916b-4366-8cda-b3bc91f214ec",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Vasim Matadar",
          "phoneNumber": "8000501598",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7063359",
          "userRole": ["TRAINEE"],
          "longitude": "72.54360659999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T09:04:54.000+0000"
            },
            {
              "topicName": "Wheat Crop Training",
              "issuedDate": "2020-12-31T10:28:36.000+0000"
            },
            {
              "topicName": "PDA Refresher Training for ACT and DSC",
              "issuedDate": "2021-10-07T11:59:16.000+0000"
            },
            {
              "topicName": "PDA Dashboard Demo",
              "issuedDate": "2021-11-26T05:29:40.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f64f98b3-8680-4e4f-8978-a94781c6fb94",
      "data": {
        "userDetailData": {
          "userId": "f64f98b3-8680-4e4f-8978-a94781c6fb94",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vadnagar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Ff64f98b3-8680-4e4f-8978-a94781c6fb94.jpg",
          "name": "Rinkal manubhai patel",
          "phoneNumber": "7201023370",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7757259",
          "userRole": ["TRAINEE"],
          "longitude": "72.6165643",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:08.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:57:13.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "8a6f88f1-1dd9-48c3-8e9c-7c2ccc1096d9",
      "data": {
        "userDetailData": {
          "userId": "8a6f88f1-1dd9-48c3-8e9c-7c2ccc1096d9",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Himatnagar",
          "photo": "",
          "name": "Parmar pinakin",
          "phoneNumber": "9016764513",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.5881482",
          "userRole": ["TRAINEE"],
          "longitude": "72.95343849999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:04.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "95a04b38-85ea-4d1a-a65f-cbcd05be181e",
      "data": {
        "userDetailData": {
          "userId": "95a04b38-85ea-4d1a-a65f-cbcd05be181e",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Pune",
          "photo": "",
          "name": "Uma",
          "phoneNumber": "9822861802",
          "district": "Pune District",
          "programId": 27,
          "latitude": "18.4949373",
          "userRole": ["TRAINEE"],
          "longitude": "73.8149136",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-08-27T06:49:04.000+0000"
            },
            {
              "topicName": "PDA Hand-on",
              "issuedDate": "2021-08-30T11:11:55.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "2c4e0071-bcce-448d-b363-6dcb75f84c57",
      "data": {
        "userDetailData": {
          "userId": "2c4e0071-bcce-448d-b363-6dcb75f84c57",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Nalgonda",
          "photo": "",
          "name": "Nakarekanti suneetha",
          "phoneNumber": "7095557939",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "17.0549548",
          "userRole": ["TRAINEE"],
          "longitude": "79.279334",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-10T05:25:32.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "249f141b-3fd0-4d6b-8fdb-c68de7aca819",
      "data": {
        "userDetailData": {
          "userId": "249f141b-3fd0-4d6b-8fdb-c68de7aca819",
          "emailId": "geoscience.service@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F249f141b-3fd0-4d6b-8fdb-c68de7aca819.jpg",
          "name": "Sazina",
          "phoneNumber": "9979856931",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.2240119",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "69.6506869",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:45:10.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training for Trainers | ACT",
              "issuedDate": "2020-12-11T12:41:50.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:39:06.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | ACT",
              "issuedDate": "2020-12-11T13:54:18.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:18:11.000+0000"
            },
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:23:38.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:35:35.000+0000"
            },
            {
              "topicName": "PDA Refresher Training for ACT and DSC",
              "issuedDate": "2021-10-07T12:05:10.000+0000"
            },
            {
              "topicName": "PDA Dashboard Demo",
              "issuedDate": "2021-11-26T05:28:26.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:30:00.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T08:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "7819afc7-9754-4b53-976c-722417249f0f",
      "data": {
        "userDetailData": {
          "userId": "7819afc7-9754-4b53-976c-722417249f0f",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Kolkata",
          "photo": "",
          "name": "Anupriya Banerjee",
          "phoneNumber": "8897311538",
          "district": "Kolkata District",
          "programId": 27,
          "latitude": "22.4843962",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "88.34014239999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T10:16:08.000+0000"
            },
            {
              "topicName": "PMT Functions & CBP Training Calendar",
              "issuedDate": "2020-10-13T10:35:49.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "PDA - Assisted Scan",
              "issuedDate": "2021-03-08T08:12:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "7108b369-0740-47e3-a6c6-ff551de5b1a7",
      "data": {
        "userDetailData": {
          "userId": "7108b369-0740-47e3-a6c6-ff551de5b1a7",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Rajnagar",
          "photo": "",
          "name": "Debarati Mondal",
          "phoneNumber": "9073497618",
          "district": "Birbhum District",
          "programId": 27,
          "latitude": "23.9451456",
          "userRole": ["TRAINEE"],
          "longitude": "87.31403770000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T09:37:25.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T08:24:30.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "1e094070-9b95-4034-92b4-559a6aff57fc",
      "data": {
        "userDetailData": {
          "userId": "1e094070-9b95-4034-92b4-559a6aff57fc",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Gundiyali",
          "photo": "",
          "name": "Lalji japare",
          "phoneNumber": "9898225460",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8370766",
          "userRole": ["TRAINEE"],
          "longitude": "69.4218009",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:23:02.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4084a65c-8c7b-4f94-bf3f-cb5c6f9d7947",
      "data": {
        "userDetailData": {
          "userId": "4084a65c-8c7b-4f94-bf3f-cb5c6f9d7947",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Mangalbari",
          "photo": "",
          "name": "Arpita Das PRASARI",
          "phoneNumber": "9083757679",
          "district": "Jalpaiguri District",
          "programId": 27,
          "latitude": "26.8737195",
          "userRole": ["TRAINEE"],
          "longitude": "88.80498949999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T09:46:14.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T05:59:47.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "eabded96-d1c0-4ec9-a932-4b57b466a34c",
      "data": {
        "userDetailData": {
          "userId": "eabded96-d1c0-4ec9-a932-4b57b466a34c",
          "emailId": "",
          "country": "India",
          "state": "Bihar",
          "city": "Muzaffarpur",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Feabded96-d1c0-4ec9-a932-4b57b466a34c.jpg",
          "name": "Sunil Kumar Pandey",
          "phoneNumber": "9451224882",
          "district": "Muzaffarpur District",
          "programId": 27,
          "latitude": "26.1186106",
          "userRole": ["TRAINEE"],
          "longitude": "85.3703771",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T08:39:52.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:34:42.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T07:29:51.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:28:55.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "8443fea0-a160-4b2e-be83-604f9184622f",
      "data": {
        "userDetailData": {
          "userId": "8443fea0-a160-4b2e-be83-604f9184622f",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Sudeep",
          "phoneNumber": "9739173639",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.9121026",
          "userRole": ["TRAINEE"],
          "longitude": "77.5870105",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-04-28T06:23:25.000+0000"
            },
            {
              "topicName": "PDA Demo Test fr content",
              "issuedDate": "2021-06-17T11:21:09.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b9c083ae-1a2d-41ec-ace2-2459410042a9",
      "data": {
        "userDetailData": {
          "userId": "b9c083ae-1a2d-41ec-ace2-2459410042a9",
          "emailId": "",
          "country": "India",
          "state": "Rajasthan",
          "city": "Sujangarh",
          "photo": "",
          "name": "Shakti soni",
          "phoneNumber": "8107025671",
          "district": "Churu District",
          "programId": 27,
          "latitude": "27.7044756",
          "userRole": ["TRAINEE"],
          "longitude": "74.4642861",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA mock session - Churu",
              "issuedDate": "2020-12-16T06:46:21.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "da44a0db-c91c-4437-8604-8bd05e2703dc",
      "data": {
        "userDetailData": {
          "userId": "da44a0db-c91c-4437-8604-8bd05e2703dc",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "",
          "name": "Kanika Pokhariya",
          "phoneNumber": "7202016474",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.2200289",
          "userRole": ["TRAINEE"],
          "longitude": "69.6503628",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:58:24.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:22:28.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:15:49.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "fd95ee30-3ab4-4c79-a54d-225162900383",
      "data": {
        "userDetailData": {
          "userId": "fd95ee30-3ab4-4c79-a54d-225162900383",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhimdevka",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2Ffd95ee30-3ab4-4c79-a54d-225162900383.jpg",
          "name": "Dinesh tarsi ",
          "phoneNumber": "9510430747",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.3241872",
          "userRole": ["TRAINEE"],
          "longitude": "70.7822635",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:50:35.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:47:24.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:06:33.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:39:28.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "8534ca90-db07-4871-9011-4d41455f192c",
      "data": {
        "userDetailData": {
          "userId": "8534ca90-db07-4871-9011-4d41455f192c",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Taga",
          "photo": "",
          "name": "Vashram desai",
          "phoneNumber": "9687163518",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.5081588",
          "userRole": ["TRAINEE"],
          "longitude": "70.9861706",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:34:57.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:48:22.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:05:00.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:36:48.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "e84e38c5-38bc-47e9-b5f1-a6c47d653c1e",
      "data": {
        "userDetailData": {
          "userId": "e84e38c5-38bc-47e9-b5f1-a6c47d653c1e",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Thoriari",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2Fe84e38c5-38bc-47e9-b5f1-a6c47d653c1e.jpg",
          "name": "Navghan bharvad",
          "phoneNumber": "9925961471",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.3455397",
          "userRole": ["TRAINEE"],
          "longitude": "70.7703703",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:45:34.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T11:18:06.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:08:32.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:56:59.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "04512cf0-6175-4c6f-a757-a792934cc3ae",
      "data": {
        "userDetailData": {
          "userId": "04512cf0-6175-4c6f-a757-a792934cc3ae",
          "emailId": "pintukumargupta@srijanindia.org",
          "country": "India",
          "state": "Uttar Pradesh",
          "city": "Mau Mustkil",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F04512cf0-6175-4c6f-a757-a792934cc3ae.jpg",
          "name": "Pintu Kumar Gupta",
          "phoneNumber": "8826020622",
          "district": "Chitrakoot District",
          "programId": 27,
          "latitude": "25.2730980000001",
          "userRole": ["TRAINEE"],
          "longitude": "81.37476",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:44:35.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T06:44:41.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:23.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "bf0950c4-678f-41ec-a679-f64208fd1dd2",
      "data": {
        "userDetailData": {
          "userId": "bf0950c4-678f-41ec-a679-f64208fd1dd2",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Dahod",
          "photo": "",
          "name": "Shakti",
          "phoneNumber": "8141774872",
          "district": "Dahod District",
          "programId": 27,
          "latitude": "22.844887",
          "userRole": ["TRAINEE"],
          "longitude": "74.2538650000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-07-13T05:16:25.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "0ab69b7d-9e8e-4127-aa10-ed2172d2ce5e",
      "data": {
        "userDetailData": {
          "userId": "0ab69b7d-9e8e-4127-aa10-ed2172d2ce5e",
          "emailId": "g_hp@rediffmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "",
          "name": "Gaurav Parmar",
          "phoneNumber": "9825367374",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.239121",
          "userRole": ["TRAINEE"],
          "longitude": "69.661069",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:15:55.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9c993b60-1289-4c9c-a4cc-5b34e3a4c330",
      "data": {
        "userDetailData": {
          "userId": "9c993b60-1289-4c9c-a4cc-5b34e3a4c330",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Pune",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F9c993b60-1289-4c9c-a4cc-5b34e3a4c330.jpg",
          "name": "Rucha Deshmukh",
          "phoneNumber": "8087877515",
          "district": "Pune District",
          "programId": 27,
          "latitude": "18.493298",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "73.820836",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-08-27T06:49:18.000+0000"
            },
            {
              "topicName": "PDA Hand-on",
              "issuedDate": "2021-08-30T11:11:51.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "भूजलाची ओळख व भूजल व्यवस्थापनाची गरज | Introduction to groundwater -Need for GW management",
              "issuedDate": "2021-08-30T14:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "77f89c51-9282-4033-9a94-daebbc0b515f",
      "data": {
        "userDetailData": {
          "userId": "77f89c51-9282-4033-9a94-daebbc0b515f",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Khatoda",
          "photo": "",
          "name": "Chaudhary Puriben kanjibhai",
          "phoneNumber": "9638899295",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.8012221",
          "userRole": ["TRAINEE"],
          "longitude": "72.59367929999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T07:10:38.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "48567399-eb17-4b8f-89b9-98d3f82e745a",
      "data": {
        "userDetailData": {
          "userId": "48567399-eb17-4b8f-89b9-98d3f82e745a",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Hyderabad",
          "photo": "",
          "name": "lavanya",
          "phoneNumber": "9440814571",
          "district": "Rangareddy District",
          "programId": 27,
          "latitude": "17.3453312",
          "userRole": ["TRAINEE"],
          "longitude": "78.5551488",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:07:01.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e97fd0ba-3afc-46e6-a51a-d46194af9fe2",
      "data": {
        "userDetailData": {
          "userId": "e97fd0ba-3afc-46e6-a51a-d46194af9fe2",
          "emailId": "",
          "country": "India",
          "state": "Andhra Pradesh",
          "city": "Madanapalle",
          "photo": "",
          "name": "Snenesh",
          "phoneNumber": "9963807438",
          "district": "Chittoor District",
          "programId": 27,
          "latitude": "13.5395928",
          "userRole": ["TRAINEE"],
          "longitude": "78.51638940000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-04-07T09:20:44.000+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2020-04-07T10:41:09.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2020-04-07T10:49:18.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9f743472-2191-48cd-81c8-40eee1d96e31",
      "data": {
        "userDetailData": {
          "userId": "9f743472-2191-48cd-81c8-40eee1d96e31",
          "emailId": "",
          "country": "India",
          "state": "Odisha",
          "city": "Bhubaneswar",
          "photo": "",
          "name": "Hrushikesh Mahapatra ",
          "phoneNumber": "9437229894",
          "district": "Khordha District",
          "programId": 27,
          "latitude": "20.2960587",
          "userRole": ["TRAINEE"],
          "longitude": "85.8245398",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:48:47+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d02b6311-6074-4fa1-898d-d9e60793c1a5",
      "data": {
        "userDetailData": {
          "userId": "d02b6311-6074-4fa1-898d-d9e60793c1a5",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Budharmora",
          "photo": "",
          "name": "Dhirenbhai",
          "phoneNumber": "9879935114",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.337201068906",
          "userRole": ["TRAINEE"],
          "longitude": "70.189165510336",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:27:29.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e6afff30-727b-4d6a-9a0f-a21ce2ac628c",
      "data": {
        "userDetailData": {
          "userId": "e6afff30-727b-4d6a-9a0f-a21ce2ac628c",
          "emailId": "",
          "country": "India",
          "state": "Uttarakhand",
          "city": "Simyal Gaon",
          "photo": "",
          "name": "Deepa",
          "phoneNumber": "7500249383",
          "district": "Nainital District",
          "programId": 27,
          "latitude": "29.4802452",
          "userRole": ["TRAINEE"],
          "longitude": "79.6287287",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:40:02.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:38:35.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:40:35.000+0000"
            },
            {
              "topicName": "Feedback and next steps LL2",
              "issuedDate": "2022-03-08T07:10:43.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:51:26.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T07:02:04.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "3da1ae03-ee8f-4739-b245-d477b387772d",
      "data": {
        "userDetailData": {
          "userId": "3da1ae03-ee8f-4739-b245-d477b387772d",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Sundhia",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F3da1ae03-ee8f-4739-b245-d477b387772d.jpg",
          "name": "Thakor mukeshkumar",
          "phoneNumber": "9662898172",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.8342951",
          "userRole": ["TRAINEE"],
          "longitude": "72.58211550000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T06:20:59.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:04:20.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "01f6c773-65a6-4875-a9e1-3c649ba6c333",
      "data": {
        "userDetailData": {
          "userId": "01f6c773-65a6-4875-a9e1-3c649ba6c333",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vadnagar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F01f6c773-65a6-4875-a9e1-3c649ba6c333.jpg",
          "name": "Ratansinh jagusinh chauhan",
          "phoneNumber": "8140102466",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7757259",
          "userRole": ["TRAINEE"],
          "longitude": "72.6165643",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:06.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:55.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "84b658c9-cb9b-4984-8f29-7186c1fa81bb",
      "data": {
        "userDetailData": {
          "userId": "84b658c9-cb9b-4984-8f29-7186c1fa81bb",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Gads b patel",
          "phoneNumber": "9974181497",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T08:42:13.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ead391a6-2c6e-4389-9bf3-f949dcc4b97d",
      "data": {
        "userDetailData": {
          "userId": "ead391a6-2c6e-4389-9bf3-f949dcc4b97d",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Ahmedabad",
          "photo": "",
          "name": "Gazala ",
          "phoneNumber": "9099019370",
          "district": "Ahmedabad District",
          "programId": 27,
          "latitude": "22.9873856",
          "userRole": ["TRAINEE"],
          "longitude": "72.513657",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-17T10:15:40.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d3ad7842-a5a6-462c-8a5d-b693bfd2cf89",
      "data": {
        "userDetailData": {
          "userId": "d3ad7842-a5a6-462c-8a5d-b693bfd2cf89",
          "emailId": "bmanojbhagwat@gmail.com",
          "country": "India",
          "state": "Maharashtra",
          "city": "Pune",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fd3ad7842-a5a6-462c-8a5d-b693bfd2cf89.jpg",
          "name": "Manoj Bhagwat",
          "phoneNumber": "9689942642",
          "district": "Pune District",
          "programId": 27,
          "latitude": "18.539059",
          "userRole": ["TRAINEE"],
          "longitude": "73.872828",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-08-27T06:48:52.000+0000"
            },
            {
              "topicName": "PDA Hand-on",
              "issuedDate": "2021-08-30T11:11:53.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "518c58b9-1081-495d-8c06-520a0f127641",
      "data": {
        "userDetailData": {
          "userId": "518c58b9-1081-495d-8c06-520a0f127641",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vajapur",
          "photo": "",
          "name": "Rathod  Gajendrasinh javansinh ",
          "phoneNumber": "7698820367",
          "district": "Sabarkatha District",
          "programId": 27,
          "latitude": "23.4544995",
          "userRole": ["TRAINEE"],
          "longitude": "73.0213414",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:34.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T07:22:54.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e7ca78b7-9578-4795-ae25-a860c3976649",
      "data": {
        "userDetailData": {
          "userId": "e7ca78b7-9578-4795-ae25-a860c3976649",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Vadnagar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fe7ca78b7-9578-4795-ae25-a860c3976649.jpg",
          "name": "Jagrutiben Thakor",
          "phoneNumber": "8487802127",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7757259",
          "userRole": ["TRAINEE"],
          "longitude": "72.6165643",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:47:09.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:56:51.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "84eef6b9-3acb-40f3-a8b3-f9ad7a2fb257": {
        "data": {
          "userDetailData": {
            "userId": "84eef6b9-3acb-40f3-a8b3-f9ad7a2fb257",
            "emailId": "",
            "country": "India",
            "state": "Gujarat",
            "city": "Vadnagar",
            "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F84eef6b9-3acb-40f3-a8b3-f9ad7a2fb257.jpg",
            "name": "Ashvinkumar Prajapati ",
            "phoneNumber": "8141079997",
            "district": "Mehsana District",
            "programId": 27,
            "latitude": "23.7757259",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "72.6165643",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "PDA Rollout Demo",
                "issuedDate": "2021-05-04T05:46:58.000+0000"
              },
              {
                "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
                "issuedDate": "2021-05-05T06:56:37.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": [],
            "qualificationTitle": []
          }
        }
      }
    },
    {
      "practitionerId": "e96e2ffe-dcae-4be3-b2b9-6066b9969f8b",
      "data": {
        "userDetailData": {
          "userId": "e96e2ffe-dcae-4be3-b2b9-6066b9969f8b",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Erandgaon Kh.",
          "photo": "",
          "name": "Kaniphnath Anna Madhawai",
          "phoneNumber": "7588615905",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "20.048039",
          "userRole": ["TRAINEE"],
          "longitude": "74.422136",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Water User Association Training",
              "issuedDate": "2021-04-03T09:20:15.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "a342759f-5fbb-42dd-804b-f8875e53362f",
      "data": {
        "userDetailData": {
          "userId": "a342759f-5fbb-42dd-804b-f8875e53362f",
          "emailId": "bhattaramtapra@gmail.com",
          "country": "India",
          "state": "Rajasthan",
          "city": "Dungarpur",
          "photo": "",
          "name": "Bhatta Ram",
          "phoneNumber": "9929762999",
          "district": "Dungarpur District",
          "programId": 27,
          "latitude": "23.8416740000001",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "73.7146470000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-19T10:13:51.000+0000"
            },
            {
              "topicName": "PDA mock session - Churu",
              "issuedDate": "2020-12-15T15:10:10.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "PDA mock session - Churu",
              "issuedDate": "2020-12-18T15:25:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f1708eec-aa7b-484e-8e5d-9e066c140cec",
      "data": {
        "userDetailData": {
          "userId": "f1708eec-aa7b-484e-8e5d-9e066c140cec",
          "emailId": "",
          "country": "India",
          "state": "Madhya Pradesh",
          "city": "Bhopal",
          "photo": "",
          "name": "AR Ahirwal",
          "phoneNumber": "9993635623",
          "district": "Bhopal District",
          "programId": 27,
          "latitude": "23.2599333",
          "userRole": ["TRAINEE"],
          "longitude": "77.412615",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Test 1",
              "issuedDate": "2021-04-05T07:55:36.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "1da12f92-cab3-4f8b-b8c7-4c9baf5a32f9",
      "data": {
        "userDetailData": {
          "userId": "1da12f92-cab3-4f8b-b8c7-4c9baf5a32f9",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Adesar",
          "photo": "",
          "name": "DHARAMSHI GOHIL",
          "phoneNumber": "9409858994",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.5580562",
          "userRole": ["TRAINEE"],
          "longitude": "70.9814281",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:50:59.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:31:09.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:55:19.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:05:33.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:37:00.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c8656f2b-d154-4578-935a-bd44c29e35d0",
      "data": {
        "userDetailData": {
          "userId": "c8656f2b-d154-4578-935a-bd44c29e35d0",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Ghattuppal",
          "photo": "",
          "name": "Miryala  padma",
          "phoneNumber": "8096161599",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "17.070690692155",
          "userRole": ["TRAINEE"],
          "longitude": "78.9190747665309",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:22:52.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "aa0791ae-e282-4470-9c63-a4e4c898bf3e",
      "data": {
        "userDetailData": {
          "userId": "aa0791ae-e282-4470-9c63-a4e4c898bf3e",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "",
          "photo": "",
          "name": "Dhanjirathod",
          "phoneNumber": "9265816060",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.1245763",
          "userRole": ["TRAINEE"],
          "longitude": "69.9916784",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:57:50.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "985b18b2-845a-4900-8463-1a8832f9b8a3",
      "data": {
        "userDetailData": {
          "userId": "985b18b2-845a-4900-8463-1a8832f9b8a3",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Mehsana",
          "photo": "",
          "name": "Patel kiran kumar bardevbhai",
          "phoneNumber": "9998529640",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.6129875",
          "userRole": ["TRAINEE"],
          "longitude": "72.40389929999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T08:46:28.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d71b934c-81c3-47d1-8a1b-bfa821e53873",
      "data": {
        "userDetailData": {
          "userId": "d71b934c-81c3-47d1-8a1b-bfa821e53873",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Nandurbar",
          "photo": "",
          "name": "sandip",
          "phoneNumber": "9970105642",
          "district": "Nandurbar District",
          "programId": 27,
          "latitude": "21.3598492",
          "userRole": ["TRAINEE"],
          "longitude": "74.2566308",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:23:32.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T06:58:23.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:38:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "24116eb6-0d95-472a-befc-206e7e1f787c",
      "data": {
        "userDetailData": {
          "userId": "24116eb6-0d95-472a-befc-206e7e1f787c",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Chalsa",
          "photo": "",
          "name": "Binita Chowdhury",
          "phoneNumber": "9474891634",
          "district": "Jalpaiguri District",
          "programId": 27,
          "latitude": "26.8816342",
          "userRole": ["TRAINEE"],
          "longitude": "88.7984924",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Zoom for Hubs & Spokes",
              "issuedDate": "2020-12-10T06:24:56.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T09:59:58.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T05:58:51.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e6a08c80-8a53-4490-a3b4-bd407bfb5558",
      "data": {
        "userDetailData": {
          "userId": "e6a08c80-8a53-4490-a3b4-bd407bfb5558",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Budharmora",
          "photo": "",
          "name": "Rajeshbgai mohanvgai sathvara",
          "phoneNumber": "9879338746",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.3329231",
          "userRole": ["TRAINEE"],
          "longitude": "70.1952547",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:23:42.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "0f021e0f-b508-4fbf-91a2-e256ffde7de3",
      "data": {
        "userDetailData": {
          "userId": "0f021e0f-b508-4fbf-91a2-e256ffde7de3",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Kuvasana",
          "photo": "",
          "name": "Patel vipul",
          "phoneNumber": "7698751455",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.6865916",
          "userRole": ["TRAINEE"],
          "longitude": "72.5914616",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:36:55.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:47:05.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b16b7c59-7de2-41e9-9a24-d63e33c0a825",
      "data": {
        "userDetailData": {
          "userId": "b16b7c59-7de2-41e9-9a24-d63e33c0a825",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Nandurbar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fb16b7c59-7de2-41e9-9a24-d63e33c0a825.jpg",
          "name": "Jitendra Sonawane",
          "phoneNumber": "7720045808",
          "district": "Nandurbar District",
          "programId": 27,
          "latitude": "21.3577785",
          "userRole": ["TRAINEE"],
          "longitude": "74.2593057",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:22:55.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:23:45.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T06:45:28.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:21:12.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f838dac6-0aca-4a3c-a686-f4e012303539",
      "data": {
        "userDetailData": {
          "userId": "f838dac6-0aca-4a3c-a686-f4e012303539",
          "emailId": "",
          "country": "India",
          "state": "Odisha",
          "city": "Sambalpur",
          "photo": "",
          "name": "Amrtha Kasturi Rangan",
          "phoneNumber": "9820204164",
          "district": "Sambalpur District",
          "programId": 27,
          "latitude": "21.4668716",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "83.9811665",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Codes instead of Scans",
              "issuedDate": "2021-02-04T06:06:22.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-07-13T05:16:42.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Manu Test Topic",
              "issuedDate": "2019-12-03T05:19:00.000+0000"
            },
            {
              "topicName": "Content and PDA - Capacity Building Package",
              "issuedDate": "2020-12-21T11:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "5b560155-18f6-4430-9b9c-e4c45333628e",
      "data": {
        "userDetailData": {
          "userId": "5b560155-18f6-4430-9b9c-e4c45333628e",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bagepalli",
          "photo": "",
          "name": "Naveen L ",
          "phoneNumber": "7483858898",
          "district": "Chikkaballapura District",
          "programId": 27,
          "latitude": "13.7806309",
          "userRole": ["TRAINEE"],
          "longitude": "77.7987591",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:09:36.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b93fb22c-4617-4d60-a38e-8718ad7b228f",
      "data": {
        "userDetailData": {
          "userId": "b93fb22c-4617-4d60-a38e-8718ad7b228f",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Charmi modi",
          "phoneNumber": "9427234408",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7006791",
          "userRole": ["TRAINEE"],
          "longitude": "72.5406323",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Rollout Demo",
              "issuedDate": "2021-05-04T05:46:03.000+0000"
            },
            {
              "topicName": "Cotton Pre Sowing Training By KVK Kherava - DSC-BCI  PUs Staff",
              "issuedDate": "2021-05-05T06:58:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ffb16e31-3fd8-4fc3-94e1-63c6b68c451e",
      "data": {
        "userDetailData": {
          "userId": "ffb16e31-3fd8-4fc3-94e1-63c6b68c451e",
          "emailId": "nilay.wankhade@sattva.co.in",
          "country": "India",
          "state": "Maharashtra",
          "city": "Osmanabad",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fffb16e31-3fd8-4fc3-94e1-63c6b68c451e.jpg",
          "name": "Nilay Wankhade",
          "phoneNumber": "7276462408",
          "district": "Osmanabad District",
          "programId": 27,
          "latitude": "18.185332",
          "userRole": ["TRAINEE"],
          "longitude": "76.0419642",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T10:16:05.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-25T06:11:13.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e88b61f8-77ab-4db5-8925-dd7986aedef2",
      "data": {
        "userDetailData": {
          "userId": "e88b61f8-77ab-4db5-8925-dd7986aedef2",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fe88b61f8-77ab-4db5-8925-dd7986aedef2.jpg",
          "name": "Balakrishnan ",
          "phoneNumber": "9480990870",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "13.0300911",
          "userRole": ["TRAINEE"],
          "longitude": "77.51825199999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-25T06:11:13.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "32669155-14ca-462e-b187-55304716c91a",
      "data": {
        "userDetailData": {
          "userId": "32669155-14ca-462e-b187-55304716c91a",
          "emailId": "",
          "country": "India",
          "state": "West Bengal",
          "city": "Chalsa Mahabari",
          "photo": "",
          "name": "Twinkle Mukherjee ",
          "phoneNumber": "7003313423",
          "district": "Jalpaiguri District",
          "programId": 27,
          "latitude": "26.8830603",
          "userRole": ["TRAINEE"],
          "longitude": "88.8046865",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-04T10:07:17.000+0000"
            },
            {
              "topicName": "Guided Mentoring Format",
              "issuedDate": "2020-11-04T10:39:09.000+0000"
            },
            {
              "topicName": "Hub Roles for Virtual Interactions",
              "issuedDate": "2020-12-10T05:58:05.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "16e92e92-b29d-433b-8cff-121f6a631419",
      "data": {
        "userDetailData": {
          "userId": "16e92e92-b29d-433b-8cff-121f6a631419",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Budharmora",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F16e92e92-b29d-433b-8cff-121f6a631419.jpg",
          "name": "Sonal sachin sathawara",
          "phoneNumber": "9819164449",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.3271098",
          "userRole": ["TRAINEE"],
          "longitude": "70.1971151",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:14:40.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "c1f975e4-9644-4b1f-afc4-b7dc270bf848",
      "data": {
        "userDetailData": {
          "userId": "c1f975e4-9644-4b1f-afc4-b7dc270bf848",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Perur",
          "photo": "",
          "name": "Parimi Rajini",
          "phoneNumber": "9948472492",
          "district": "Nalgonda District",
          "programId": 27,
          "latitude": "16.760729",
          "userRole": ["TRAINEE"],
          "longitude": "79.369436",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:51:06.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "743c12ee-242e-452d-88b0-9744cfd55eaa",
      "data": {
        "userDetailData": {
          "userId": "743c12ee-242e-452d-88b0-9744cfd55eaa",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Nagalpar Moti",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F743c12ee-242e-452d-88b0-9744cfd55eaa.jpg",
          "name": "Tushar Jethwa",
          "phoneNumber": "9726650108",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.1103099",
          "userRole": ["TRAINEE"],
          "longitude": "70.0016573",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:31:36.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "88826799-8409-4769-bbfc-4885256b9ec8",
      "data": {
        "userDetailData": {
          "userId": "88826799-8409-4769-bbfc-4885256b9ec8",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Kadiya vidhi kirtibhai",
          "phoneNumber": "9624171806",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7006791",
          "userRole": ["TRAINEE"],
          "longitude": "72.5406323",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T10:53:25.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c55bd79b-042e-46bf-9e18-f4dd8e383e5d",
      "data": {
        "userDetailData": {
          "userId": "c55bd79b-042e-46bf-9e18-f4dd8e383e5d",
          "emailId": "nakarsharad@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2Fc55bd79b-042e-46bf-9e18-f4dd8e383e5d.jpg",
          "name": "Sarad nakar",
          "phoneNumber": "8758745068",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.9345721808112",
          "userRole": ["TRAINEE"],
          "longitude": "69.8143552113209",
          "badgeDetails": [
            {
              "badgeId": 248,
              "badgeName": "Oct1"
            }
          ],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - III",
              "issuedDate": "2021-08-18T11:51:07.000+0000"
            },
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:24:06.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:53:26.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:32:22.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:58:36.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:16:08.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:19:19.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "452450c8-d384-4c61-83f8-4faf11a6d8ef",
      "data": {
        "userDetailData": {
          "userId": "452450c8-d384-4c61-83f8-4faf11a6d8ef",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Anand",
          "photo": "",
          "name": "Sunderrajan",
          "phoneNumber": "9925756469",
          "district": "Anand District",
          "programId": 27,
          "latitude": "22.5418779",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "72.970447",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-26T11:16:36+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2019-12-26T10:41:05+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-26T11:48:16+0000"
            },
            {
              "topicName": "Churu Fluorosis PDA demo",
              "issuedDate": "2020-10-21T09:34:50.000+0000"
            },
            {
              "topicName": "JD test - Assam",
              "issuedDate": "2023-01-10T06:21:33.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "JD test - Assam",
              "issuedDate": "2023-01-24T06:15:00.000+0000"
            },
            {
              "topicName": "JD test - Training of District Coordinators for JalDoot Program Execution",
              "issuedDate": "2023-01-31T10:45:00.000+0000"
            },
            {
              "topicName": "JD test - Training of Block Coordinators for JalDoot Program ",
              "issuedDate": "2023-01-31T10:45:00.000+0000"
            },
            {
              "topicName": "JD test - Monitoring of the Block Plan",
              "issuedDate": "2023-01-31T11:30:00.000+0000"
            },
            {
              "topicName": "JD test - Making of a block plan",
              "issuedDate": "2023-01-31T11:45:00.000+0000"
            },
            {
              "topicName": "JD test - Conducting a block trainers TOT",
              "issuedDate": "2023-01-31T11:45:00.000+0000"
            },
            {
              "topicName": "JD Test - Reporting on block plan",
              "issuedDate": "2023-01-31T11:45:00.000+0000"
            },
            {
              "topicName": "JD test - Report on Progress post JalShala with field work Data",
              "issuedDate": "2023-01-31T12:00:00.000+0000"
            },
            {
              "topicName": "JD test -  Training of Block Trainers for JalDoot Program ",
              "issuedDate": "2023-01-31T12:00:00.000+0000"
            },
            {
              "topicName": "JD test - Completing the self assessment course ",
              "issuedDate": "2023-01-31T12:00:00.000+0000"
            },
            {
              "topicName": "JD test - Making a JalShala Plan",
              "issuedDate": "2023-01-31T12:00:00.000+0000"
            },
            {
              "topicName": "JD test - Executing the JalShala ",
              "issuedDate": "2023-01-31T12:00:00.000+0000"
            },
            {
              "topicName": "JD test - Assist for Jal Doot Journey",
              "issuedDate": "2023-01-31T12:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "2cbe0329-07a4-481a-bf7a-22b814a464e5",
      "data": {
        "userDetailData": {
          "userId": "2cbe0329-07a4-481a-bf7a-22b814a464e5",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Anand",
          "photo": "",
          "name": "Vahidsaiyad",
          "phoneNumber": "9898946884",
          "district": "Anand District",
          "programId": 27,
          "latitude": "22.5645175",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "72.928871",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-26T11:16:40+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2019-12-26T10:41:19+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-26T11:48:30+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-13T06:30:00.000+0000"
            },
            {
              "topicName": "PDA mock session - Churu",
              "issuedDate": "2020-12-18T15:25:00.000+0000"
            },
            {
              "topicName": "JD test - Assam",
              "issuedDate": "2023-01-24T06:15:00.000+0000"
            },
            {
              "topicName": "JD test - Making of a block plan",
              "issuedDate": "2023-01-31T11:45:00.000+0000"
            },
            {
              "topicName": "JD test - Conducting a block trainers TOT",
              "issuedDate": "2023-01-31T11:45:00.000+0000"
            },
            {
              "topicName": "JD test - Assist for Jal Doot Journey",
              "issuedDate": "2023-01-31T12:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "0c28fe58-1af0-44b9-8d42-d25138965205",
      "data": {
        "userDetailData": {
          "userId": "0c28fe58-1af0-44b9-8d42-d25138965205",
          "emailId": "rashikapullamchetti@gmail.com",
          "country": "India",
          "state": "Rajasthan",
          "city": "Jaipur",
          "photo": "",
          "name": "Rashika Pullam Chetti",
          "phoneNumber": "8800895261",
          "district": "Jaipur District",
          "programId": 27,
          "latitude": "26.8910377",
          "userRole": ["TRAINEE"],
          "longitude": "75.8031961",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Taking good photos and making posters",
              "issuedDate": "2022-04-05T07:35:16.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:24:30.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4f3f5e8e-de1b-411a-9454-9038cca34335",
      "data": {
        "userDetailData": {
          "userId": "4f3f5e8e-de1b-411a-9454-9038cca34335",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Gagodar",
          "photo": "",
          "name": "Bharat Dodiya",
          "phoneNumber": "8758924404",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.409592",
          "userRole": ["TRAINEE"],
          "longitude": "70.81348179999999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:48:09.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:30:09.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:33:27.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-08T07:57:01.000+0000"
            },
            {
              "topicName": "Jaldoots capacity building on Geo-hydrology",
              "issuedDate": "2021-01-08T16:52:34.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:07:17.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:36:48.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "94c0c622-52e4-4601-8589-a1abcf398672",
      "data": {
        "userDetailData": {
          "userId": "94c0c622-52e4-4601-8589-a1abcf398672",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Harsule",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F94c0c622-52e4-4601-8589-a1abcf398672.jpg",
          "name": "Akash Chine",
          "phoneNumber": "7767873465",
          "district": "Nashik District",
          "programId": 27,
          "latitude": "19.8103583",
          "userRole": ["TRAINEE"],
          "longitude": "73.9447994",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Midterm Measurement of Desilting(Jalsamruddhi)",
              "issuedDate": "2021-02-20T12:04:09.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "305c306d-64cc-45eb-b51c-6bcb69c00291",
      "data": {
        "userDetailData": {
          "userId": "305c306d-64cc-45eb-b51c-6bcb69c00291",
          "emailId": "rbhat@tatatrusts.org",
          "country": "India",
          "state": "Karnataka",
          "city": "Yadgir",
          "photo": "",
          "name": "Ramachandra Bhat",
          "phoneNumber": "9380072886",
          "district": "Yadgiri District",
          "programId": 27,
          "latitude": "16.7602252",
          "userRole": ["TRAINEE"],
          "longitude": "77.1328617",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:23:47.000+0000"
            },
            {
              "topicName": "Image composition and layouts LL3",
              "issuedDate": "2022-03-29T07:40:23.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T06:59:03.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T06:22:57.000+0000"
            },
            {
              "topicName": "Taking good photos and making posters",
              "issuedDate": "2022-04-05T07:34:54.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:24:21.000+0000"
            },
            {
              "topicName": "Feedback and next steps LL2",
              "issuedDate": "2022-03-08T07:43:48.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T08:57:09.000+0000"
            },
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:47:47.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:28:19.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T06:44:26.000+0000"
            },
            {
              "topicName": "Understanding the Functional Grid",
              "issuedDate": "2022-04-26T07:19:56.000+0000"
            },
            {
              "topicName": "Introduction to mapping of content and learning needs requirements",
              "issuedDate": "2022-05-04T07:25:38.000+0000"
            },
            {
              "topicName": "Introduction to prioritization and planning for content creation",
              "issuedDate": "2022-05-10T06:23:54.000+0000"
            },
            {
              "topicName": "Introduction to Engagement and Check for Understanding",
              "issuedDate": "2022-07-19T06:39:06.000+0000"
            },
            {
              "topicName": "Check for Understanding",
              "issuedDate": "2022-07-26T08:13:55.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "a6241a20-9982-4041-a67d-7e2a2e43f6b9",
      "data": {
        "userDetailData": {
          "userId": "a6241a20-9982-4041-a67d-7e2a2e43f6b9",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Suchee",
          "phoneNumber": "9845815091",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.974002",
          "userRole": ["TRAINER"],
          "longitude": "77.613458",
          "badgeDetails": [],
          "traineeData": [],
          "trainerData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:30:00.000+0000"
            },
            {
              "topicName": "Image composition and layouts LL3",
              "issuedDate": "2022-03-29T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:30:00.000+0000"
            },
            {
              "topicName": "Feedback and next steps LL2",
              "issuedDate": "2022-03-08T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:30:00.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:30:00.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T07:30:00.000+0000"
            },
            {
              "topicName": "Understanding the Functional Grid",
              "issuedDate": "2022-04-26T12:30:00.000+0000"
            },
            {
              "topicName": "Introduction to mapping of content and learning needs requirements",
              "issuedDate": "2022-05-04T12:30:00.000+0000"
            },
            {
              "topicName": "Introduction to prioritization and planning for content creation",
              "issuedDate": "2022-05-10T12:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Engagement and Check for Understanding",
              "issuedDate": "2022-07-19T07:30:00.000+0000"
            },
            {
              "topicName": "Check for Understanding",
              "issuedDate": "2022-07-26T06:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "e6f9c8f7-019e-4d38-b18a-5acf5900c008",
      "data": {
        "userDetailData": {
          "userId": "e6f9c8f7-019e-4d38-b18a-5acf5900c008",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Ahmedabad",
          "photo": "",
          "name": "Saurav",
          "phoneNumber": "9327110002",
          "district": "Ahmedabad District",
          "programId": 27,
          "latitude": "23.0150214",
          "userRole": ["TRAINER"],
          "longitude": "72.5182858",
          "badgeDetails": [],
          "traineeData": [],
          "trainerData": [
            {
              "topicName": "Introduction to Learning Objectives",
              "issuedDate": "2022-02-22T07:30:00.000+0000"
            },
            {
              "topicName": "Image composition and layouts LL3",
              "issuedDate": "2022-03-29T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Process of planning a lesson flow",
              "issuedDate": "2022-03-02T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to atomised content",
              "issuedDate": "2022-02-15T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:30:00.000+0000"
            },
            {
              "topicName": "Feedback and next steps LL2",
              "issuedDate": "2022-03-08T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Simplified Functional Grid Format",
              "issuedDate": "2022-01-11T07:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Learning Experience Framework",
              "issuedDate": "2022-01-18T07:30:00.000+0000"
            },
            {
              "topicName": "Feedback and next steps",
              "issuedDate": "2022-01-25T07:30:00.000+0000"
            },
            {
              "topicName": "Recap and potential next steps with the learning experience",
              "issuedDate": "2022-02-01T07:30:00.000+0000"
            },
            {
              "topicName": "Taking good photos and making posters",
              "issuedDate": "2022-04-05T15:30:00.000+0000"
            },
            {
              "topicName": "Understanding the Functional Grid",
              "issuedDate": "2022-04-26T12:30:00.000+0000"
            },
            {
              "topicName": "Introduction to mapping of content and learning needs requirements",
              "issuedDate": "2022-05-04T12:30:00.000+0000"
            },
            {
              "topicName": "Introduction to prioritization and planning for content creation",
              "issuedDate": "2022-05-10T12:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Engagement and Check for Understanding",
              "issuedDate": "2022-07-19T07:30:00.000+0000"
            },
            {
              "topicName": "Check for Understanding",
              "issuedDate": "2022-07-26T06:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "192c494b-39ec-4cdc-b0b0-e4322d336e69",
      "data": {
        "userDetailData": {
          "userId": "192c494b-39ec-4cdc-b0b0-e4322d336e69",
          "emailId": "",
          "country": "India",
          "state": "Rajasthan",
          "city": "Jaipur",
          "photo": "",
          "name": "Navneet",
          "phoneNumber": "9793510101",
          "district": "Jaipur District",
          "programId": 27,
          "latitude": "26.917858",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "75.801292",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Churu Fluorosis PDA demo",
              "issuedDate": "2020-10-21T10:48:22.000+0000"
            },
            {
              "topicName": "PDA mock session - Churu",
              "issuedDate": "2020-12-15T15:10:05.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "PDA mock session - Churu",
              "issuedDate": "2020-12-18T15:25:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "22920d14-8b63-47ab-aebc-5e2b69deacb3",
      "data": {
        "userDetailData": {
          "userId": "22920d14-8b63-47ab-aebc-5e2b69deacb3",
          "emailId": "",
          "country": "India",
          "state": "Rajasthan",
          "city": "Katiyor",
          "photo": "",
          "name": "Arvind  Singh",
          "phoneNumber": "9928215200",
          "district": "Banswara District",
          "programId": 27,
          "latitude": "23.6256476",
          "userRole": ["TRAINEE"],
          "longitude": "74.5184968",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-19T10:13:05.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "59921a3a-49f8-482e-9cab-dd64ad59a839",
      "data": {
        "userDetailData": {
          "userId": "59921a3a-49f8-482e-9cab-dd64ad59a839",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Priya Sankar",
          "phoneNumber": "7045591091",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9715987",
          "userRole": ["TRAINEE"],
          "longitude": "77.5945627",
          "badgeDetails": [
            {
              "badgeId": 407,
              "badgeName": "BFT"
            }
          ],
          "traineeData": [
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:11.000+0000"
            },
            {
              "topicName": "Understanding the Functional Grid",
              "issuedDate": "2022-09-15T04:47:28.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "945619e0-0d9c-435a-ad20-c4d392b263bd",
      "data": {
        "userDetailData": {
          "userId": "945619e0-0d9c-435a-ad20-c4d392b263bd",
          "emailId": "Harishkerai181@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F945619e0-0d9c-435a-ad20-c4d392b263bd.jpg",
          "name": "Kerai harish",
          "phoneNumber": "9712744033",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.9680485000001",
          "userRole": ["TRAINEE"],
          "longitude": "69.4487735",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:25:46.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:52:58.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:34:34.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:29:20.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "ffe886cb-1150-4c72-8899-fe2c0927826d",
      "data": {
        "userDetailData": {
          "userId": "ffe886cb-1150-4c72-8899-fe2c0927826d",
          "emailId": "hardi.sukhadia96@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Vadnagar",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fffe886cb-1150-4c72-8899-fe2c0927826d.jpg",
          "name": "Hardi Sukhadiya",
          "phoneNumber": "7818970494",
          "district": "Mahesana District",
          "programId": 27,
          "latitude": "23.7834060000001",
          "userRole": ["TRAINEE"],
          "longitude": "72.634913",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA Refresher Training for ACT and DSC",
              "issuedDate": "2021-10-07T11:59:39.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4e526a75-c8d4-436b-8245-b7f7dd485186",
      "data": {
        "userDetailData": {
          "userId": "4e526a75-c8d4-436b-8245-b7f7dd485186",
          "emailId": "girishk97222@gmail.com",
          "country": "India",
          "state": "Gujarat",
          "city": "Bhuj",
          "photo": "",
          "name": "Karathiya Girishkumar Rameshbhai",
          "phoneNumber": "8780909522",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.2200321",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "69.6503381",
          "badgeDetails": [
            {
              "badgeId": 248,
              "badgeName": "Oct1"
            }
          ],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-17T10:15:25.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM - III",
              "issuedDate": "2021-08-18T11:28:31.000+0000"
            },
            {
              "topicName": "Content Development | T1.01 | DG",
              "issuedDate": "2020-11-26T11:44:58.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training for Trainers | ACT",
              "issuedDate": "2020-12-11T12:41:36.000+0000"
            },
            {
              "topicName": "Scripts Review | T1.02 | DG",
              "issuedDate": "2020-12-02T11:39:32.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | ACT",
              "issuedDate": "2020-12-11T13:54:49.000+0000"
            },
            {
              "topicName": "Observations and Feedback | T1.03 | DG",
              "issuedDate": "2020-12-10T12:17:42.000+0000"
            },
            {
              "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
              "issuedDate": "2020-12-23T10:03:24.000+0000"
            },
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:23:42.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:53:00.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:32:04.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:57:51.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:15:44.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-25T09:34:18.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:23:05.000+0000"
            },
            {
              "topicName": "TOT on Crop Water Budgeting",
              "issuedDate": "2020-12-25T06:38:28.000+0000"
            },
            {
              "topicName": "PDA Refresher Training for ACT and DSC",
              "issuedDate": "2021-10-07T11:59:49.000+0000"
            },
            {
              "topicName": "PDA Dashboard Demo",
              "issuedDate": "2021-11-26T05:28:20.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Orientation on Abhy and PGWM - III",
              "issuedDate": "2021-08-18T12:00:00.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting",
              "issuedDate": "2020-12-15T10:20:00.000+0000"
            },
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:30:00.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:30:00.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:30:00.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T12:30:00.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:30:00.000+0000"
            },
            {
              "topicName": "TOT on Crop Water Budgeting",
              "issuedDate": "2020-12-25T07:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "fb03a265-7824-49ee-a49d-fbe0c9e82564",
      "data": {
        "userDetailData": {
          "userId": "fb03a265-7824-49ee-a49d-fbe0c9e82564",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Bidada",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Ffb03a265-7824-49ee-a49d-fbe0c9e82564.jpg",
          "name": "Amrut garva",
          "phoneNumber": "9638404623",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8970853",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "69.4708192",
          "badgeDetails": [
            {
              "badgeId": 247,
              "badgeName": "Sep1 Training batch"
            }
          ],
          "traineeData": [
            {
              "topicName": "PDA Demo and Deployment Training for Trainers | ACT",
              "issuedDate": "2020-12-11T12:41:37.000+0000"
            },
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:23:46.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:52:58.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:32:14.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:56:41.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:20:18.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T12:30:00.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM",
              "issuedDate": "2021-08-14T07:30:00.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T08:00:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "4c9c2ac4-1b68-49f8-811d-2f555eda7f69",
      "data": {
        "userDetailData": {
          "userId": "4c9c2ac4-1b68-49f8-811d-2f555eda7f69",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Chandiya",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F4c9c2ac4-1b68-49f8-811d-2f555eda7f69.jpg",
          "name": "Chandan Chawla",
          "phoneNumber": "9913997107",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "23.0931159998129",
          "userRole": ["TRAINEE"],
          "longitude": "69.8279691856143",
          "badgeDetails": [
            {
              "badgeId": 247,
              "badgeName": "Sep1 Training batch"
            }
          ],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:59:37.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6df4face-5699-4fa1-bd64-0c565ee7440d",
      "data": {
        "userDetailData": {
          "userId": "6df4face-5699-4fa1-bd64-0c565ee7440d",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Khakhar Moti",
          "photo": "",
          "name": "Bharat Gadhavi",
          "phoneNumber": "9737799734",
          "district": "Kachchh District",
          "programId": 27,
          "latitude": "22.8833385",
          "userRole": ["TRAINEE"],
          "longitude": "69.5512612",
          "badgeDetails": [
            {
              "badgeId": 247,
              "badgeName": "Sep1 Training batch"
            }
          ],
          "traineeData": [
            {
              "topicName": "Orientation on Abhy and PGWM - III",
              "issuedDate": "2021-08-18T12:01:31.000+0000"
            },
            {
              "topicName": "TOT of Bhujal Janakar",
              "issuedDate": "2021-02-05T07:24:27.000+0000"
            },
            {
              "topicName": "Data collection and Monitoring",
              "issuedDate": "2021-01-06T07:53:09.000+0000"
            },
            {
              "topicName": "Data collection and Analysis",
              "issuedDate": "2021-01-06T12:32:33.000+0000"
            },
            {
              "topicName": "Orientation on Abhy and PGWM - II",
              "issuedDate": "2021-08-14T11:57:32.000+0000"
            },
            {
              "topicName": "Crop Water Budgeting Overview",
              "issuedDate": "2020-12-16T07:16:03.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d848a85a-5f98-4df7-81a3-118fa56f1780",
      "data": {
        "userDetailData": {
          "userId": "d848a85a-5f98-4df7-81a3-118fa56f1780",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Visnagar",
          "photo": "",
          "name": "Patel SURESHBHAI SADUTHALA ",
          "phoneNumber": "9712933245",
          "district": "Mehsana District",
          "programId": 27,
          "latitude": "23.7023775",
          "userRole": ["TRAINEE"],
          "longitude": "72.5425606",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Post-Harvest Management and Value Addition in Spice Crops.",
              "issuedDate": "2021-02-24T08:42:44.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "5264b284-4789-4883-81a1-0f7233e0c848",
      "data": {
        "userDetailData": {
          "userId": "5264b284-4789-4883-81a1-0f7233e0c848",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Pratap Gadh",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F5264b284-4789-4883-81a1-0f7233e0c848.jpg",
          "name": "Hamir Rana Bhai koli",
          "phoneNumber": "9925328969",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.382517",
          "userRole": ["TRAINEE"],
          "longitude": "70.7246806",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:43:55.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:06:45.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:36:50.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "9731fb01-dfad-4a22-b1f3-b9185873d4ae",
      "data": {
        "userDetailData": {
          "userId": "9731fb01-dfad-4a22-b1f3-b9185873d4ae",
          "emailId": "",
          "country": "India",
          "state": "Telangana",
          "city": "Kesaram Part",
          "photo": "",
          "name": "Dharavath sunitha",
          "phoneNumber": "9701536689",
          "district": "Suryapet District",
          "programId": 27,
          "latitude": "17.1100184",
          "userRole": ["TRAINEE"],
          "longitude": "79.6342446",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Demonstration of PDA - ICDS Supervisors Training",
              "issuedDate": "2021-06-09T10:05:46.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "78fb6721-9eb2-4099-98f7-75340a08c883",
      "data": {
        "userDetailData": {
          "userId": "78fb6721-9eb2-4099-98f7-75340a08c883",
          "emailId": "",
          "country": "India",
          "state": "Gujarat",
          "city": "Balasar",
          "photo": "",
          "name": "Narendra Chavda ",
          "phoneNumber": "9879933704",
          "district": "Kutch District",
          "programId": 27,
          "latitude": "23.8421556",
          "userRole": ["TRAINEE"],
          "longitude": "70.6677785",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-02-16T06:41:03.000+0000"
            },
            {
              "topicName": "Concepts and development of Base map,Landuse map and Geo-morphology maps",
              "issuedDate": "2021-03-06T10:31:04.000+0000"
            },
            {
              "topicName": "Community Participation-Why",
              "issuedDate": "2021-04-09T06:46:24.000+0000"
            },
            {
              "topicName": "PDA Hands on",
              "issuedDate": "2021-01-08T07:15:18.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots and community on Geo-hydrology, PGWM (Participatory ground water management)",
              "issuedDate": "2021-01-30T09:06:32.000+0000"
            },
            {
              "topicName": "Capacity building of Jaldoots on MGNREGA schemes provisions",
              "issuedDate": "2021-01-11T07:36:52.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "fe2cf7fe-c2da-42ff-bc9b-0bd9169b1f37",
      "data": {
        "userDetailData": {
          "userId": "fe2cf7fe-c2da-42ff-bc9b-0bd9169b1f37",
          "emailId": "parul.saklani@sattva.co.in",
          "country": "India",
          "state": "Meghalaya",
          "city": "Shillong",
          "photo": "",
          "name": "ParulSk",
          "phoneNumber": "9205950802",
          "district": "East Khasi Hills District",
          "programId": 27,
          "latitude": "25.5701051",
          "userRole": ["TRAINER"],
          "longitude": "91.90410279999999",
          "badgeDetails": [],
          "traineeData": [],
          "trainerData": [
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-05T13:10:00.000+0000"
            },
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-06T10:55:00.000+0000"
            },
            {
              "topicName": "11",
              "issuedDate": "2020-01-22T09:03:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "c4f487d3-f35f-4aaa-9e17-e8486db834c2",
      "data": {
        "userDetailData": {
          "userId": "c4f487d3-f35f-4aaa-9e17-e8486db834c2",
          "emailId": "pkunj5512@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Partik Kumar",
          "phoneNumber": "9967563707",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.974002",
          "userRole": ["TRAINEE"],
          "longitude": "77.613458",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T10:16:10.000+0000"
            },
            {
              "topicName": "PMT Functions & CBP Training Calendar",
              "issuedDate": "2020-10-13T10:35:42.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "a7756c59-d51a-4cff-af34-75b0403432f1",
      "data": {
        "userDetailData": {
          "userId": "a7756c59-d51a-4cff-af34-75b0403432f1",
          "emailId": "bghose@waterforpeople.org",
          "country": "India",
          "state": "Delhi",
          "city": "New Delhi",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/a7756c59-d51a-4cff-af34-75b0403432f1.JPG",
          "name": "Bishwadeep Ghose",
          "phoneNumber": "9845891295",
          "district": "South East Delhi District",
          "programId": 27,
          "latitude": "28.5515740000001",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.269148",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-05T07:00:28+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-05T07:26:09+0000"
            },
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-07T07:15:13.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Manu Test Topic",
              "issuedDate": "2019-11-27T09:10:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9b6cd454-3a3c-49bf-b0d7-e7d797f03585",
      "data": {
        "userDetailData": {
          "userId": "9b6cd454-3a3c-49bf-b0d7-e7d797f03585",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Pooja",
          "phoneNumber": "9742542513",
          "district": "Bangalore Urban District",
          "programId": 27,
          "latitude": "12.9753386",
          "userRole": ["TRAINEE"],
          "longitude": "77.5877318",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2023-01-02T11:35:10.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "fe52cdee-ac18-4e1b-adbf-746fcbca24a1",
      "data": {
        "userDetailData": {
          "userId": "fe52cdee-ac18-4e1b-adbf-746fcbca24a1",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Vinayak ",
          "phoneNumber": "9986185828",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.9749838",
          "userRole": ["TRAINEE"],
          "longitude": "77.578145",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs         s",
              "issuedDate": "2023-01-02T11:36:14.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "04c32002-e577-43b2-901f-aca2a6004b1e",
      "data": {
        "userDetailData": {
          "userId": "04c32002-e577-43b2-901f-aca2a6004b1e",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Adarsh ",
          "phoneNumber": "7259190321",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.9777236",
          "userRole": ["TRAINEE"],
          "longitude": "77.5507999",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs         s",
              "issuedDate": "2023-01-02T11:35:23.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b2104b7e-50fb-4455-9a78-7e764a56ea9e",
      "data": {
        "userDetailData": {
          "userId": "b2104b7e-50fb-4455-9a78-7e764a56ea9e",
          "emailId": "vsharan@waterforpeople.org",
          "country": "India",
          "state": "Bihar",
          "city": "Patna",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/b2104b7e-50fb-4455-9a78-7e764a56ea9e6db24d99-3e98-497b-92be-d1d213ea24f1",
          "name": "Vivek Sharan",
          "phoneNumber": "9934314254",
          "district": "Patna District",
          "programId": 27,
          "latitude": "25.6363680000001",
          "userRole": ["TRAINEE"],
          "longitude": "85.0391540000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity Building @ Scale - Experiences from FES in Odisha",
              "issuedDate": "2021-06-04T11:23:51.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "2c7ec408-8187-4669-96c5-6aa13608a15d",
      "data": {
        "userDetailData": {
          "userId": "2c7ec408-8187-4669-96c5-6aa13608a15d",
          "emailId": "priteshkumar.lal@akdn.org",
          "country": "India",
          "state": "Bihar",
          "city": "Muzaffarpur",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/pkl.jpg",
          "name": "Pritesh Kumar Lal",
          "phoneNumber": "9801706166",
          "district": "Muzaffarpur District",
          "programId": 27,
          "latitude": "26.1186521",
          "userRole": ["TRAINEE"],
          "longitude": "85.3705043",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity Building @ Scale - Experiences from FES in Odisha",
              "issuedDate": "2021-06-04T11:24:27.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "140e088a-dfb0-4385-8522-3ae556c510c0",
      "data": {
        "userDetailData": {
          "userId": "140e088a-dfb0-4385-8522-3ae556c510c0",
          "emailId": "sali@waterforpeople.org",
          "country": "India",
          "state": "Bihar",
          "city": "Patna",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/140e088a-dfb0-4385-8522-3ae556c510c0d3be5226-3ade-4459-99c1-2f5b0c3456ac",
          "name": "Safdar Ali",
          "phoneNumber": "9905040033",
          "district": "Patna District",
          "programId": 27,
          "latitude": "25.6072438",
          "userRole": ["TRAINEE"],
          "longitude": "85.082642",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Capacity Building @ Scale - Experiences from FES in Odisha",
              "issuedDate": "2021-06-04T11:23:47.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6b9b9f7d-8411-44e8-ac46-817c80c15bc9",
      "data": {
        "userDetailData": {
          "userId": "6b9b9f7d-8411-44e8-ac46-817c80c15bc9",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Chikkaballapur",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F6b9b9f7d-8411-44e8-ac46-817c80c15bc9.jpg",
          "name": "Prasanna kumar ",
          "phoneNumber": "9743016879",
          "district": "Chikkaballapur District",
          "programId": 27,
          "latitude": "13.4352510000001",
          "userRole": ["TRAINEE"],
          "longitude": "77.731252",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗು ುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21T10:00:26.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "07e64d90-36df-4114-bc07-d68fc28b716a",
      "data": {
        "userDetailData": {
          "userId": "07e64d90-36df-4114-bc07-d68fc28b716a",
          "emailId": "shankarfes@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F07e64d90-36df-4114-bc07-d68fc28b716a.jpg",
          "name": "Shankaragoud C",
          "phoneNumber": "9482961357",
          "district": "Raichur District",
          "programId": 27,
          "latitude": "16.2501559168102",
          "userRole": ["TRAINEE"],
          "longitude": "77.3514448452671",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21T10:01:05.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "7050b19c-3082-434e-bef5-6e41056549af",
      "data": {
        "userDetailData": {
          "userId": "7050b19c-3082-434e-bef5-6e41056549af",
          "emailId": "vasudev.murthyfes21@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "",
          "photo": "",
          "name": "Vasudeva murthy",
          "phoneNumber": "8970646819",
          "district": "Koppal District",
          "programId": 27,
          "latitude": "15.351525",
          "userRole": ["TRAINEE"],
          "longitude": "76.1540560000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21T10:00:25.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "1952f99c-0f23-41fb-9093-9005c5449d98",
      "data": {
        "userDetailData": {
          "userId": "1952f99c-0f23-41fb-9093-9005c5449d98",
          "emailId": "soma.ecological@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "Tumakuru",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F1952f99c-0f23-41fb-9093-9005c5449d98.jpg",
          "name": "Soma Kumara K  DC",
          "phoneNumber": "9742574801",
          "district": "Tumkur District",
          "programId": 27,
          "latitude": "13.3378762",
          "userRole": ["TRAINEE"],
          "longitude": "77.117325",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21T10:00:30.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "46032e3f-cc37-4289-aaa5-5c3af326130f",
      "data": {
        "userDetailData": {
          "userId": "46032e3f-cc37-4289-aaa5-5c3af326130f",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Chitradurga",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F46032e3f-cc37-4289-aaa5-5c3af326130f.jpg",
          "name": "BYLAPPA",
          "phoneNumber": "9481690664",
          "district": "Chitradurga District",
          "programId": 27,
          "latitude": "14.2250932",
          "userRole": ["TRAINEE"],
          "longitude": "76.3980464",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ    ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21T10:00:26.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6a23ff23-5a53-4947-a47f-8ea3a8f374c1",
      "data": {
        "userDetailData": {
          "userId": "6a23ff23-5a53-4947-a47f-8ea3a8f374c1",
          "emailId": "bheemanaika@yahoo.com",
          "country": "India",
          "state": "Karnataka",
          "city": "Mandya",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F6a23ff23-5a53-4947-a47f-8ea3a8f374c1.jpg",
          "name": "BHEEMANAIKA B R",
          "phoneNumber": "8105725789",
          "district": "Mandya District",
          "programId": 27,
          "latitude": "12.5218157",
          "userRole": ["TRAINEE"],
          "longitude": "76.89514880000002",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21T10:17:38.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
  
    {
      "data": {
        "userDetailData": {
          "userId": "d2a695e3-5d1c-49ba-adab-671dab6d81fa",
          "emailId": "sureshdr.fes@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/d2a695e3-5d1c-49ba-adab-671dab6d81fa9224443b-e2ad-40aa-a0b4-7e3a11441583",
          "name": "Suresh D R",
          "phoneNumber": "9591477177",
          "district": "Tumakuru District",
          "programId": 27,
          "latitude": "13.394900990412",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.1002568889448",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-04-07T09:22:06.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T06:49:05+0000"
            },
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯ       ಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "4290b0eb-020e-4137-8e54-f8de292d1782",
      "data": {
        "userDetailData": {
          "userId": "4290b0eb-020e-4137-8e54-f8de292d1782",
          "emailId": "",
          "country": "India",
          "state": "Maharashtra",
          "city": "Pune",
          "photo": "",
          "name": "Akash Gaikwad",
          "phoneNumber": "7066270243",
          "district": "Pune District",
          "programId": 27,
          "latitude": "18.5109092",
          "userRole": [null, "TRAINEE"],
          "longitude": "73.7903645",
          "badgeDetails": [
            {
              "badgeId": 407,
              "badgeName": "BFT"
            }
          ],
          "traineeData": [
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-11-29T10:10:23.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "67f6ffd9-0d75-4c92-8909-7c0f78daf8b7",
      "data": {
        "userDetailData": {
          "userId": "67f6ffd9-0d75-4c92-8909-7c0f78daf8b7",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Manu",
          "phoneNumber": "9886496040",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.974002",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.613458",
          "badgeDetails": [
            {
              "badgeId": 211,
              "badgeName": "Expert Hindi Trainers"
            },
            {
              "badgeId": 16,
              "badgeName": "PDA Champion"
            },
            {
              "badgeId": 407,
              "badgeName": "BFT"
            }
          ],
          "traineeData": [
            {
              "topicName": "Community Mobilization",
              "issuedDate": "2020-08-07T07:17:45.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-05T07:39:38+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2020-01-08T08:56:31+0000"
            },
            {
              "topicName": "Manu Test Topic",
              "issuedDate": "2019-11-27T09:11:58+0000"
            },
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-07T07:11:07.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-06T06:26:36.000+0000"
            },
            {
              "topicName": "Panchayat and Governance of Commons",
              "issuedDate": "2020-07-27T06:11:29.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Best Practices - Smitha Murthy",
              "issuedDate": "2020-10-21T04:53:19.000+0000"
            },
            {
              "topicName": "Codes instead of Scans",
              "issuedDate": "2021-02-04T06:06:24.000+0000"
            },
            {
              "topicName": "Test topic",
              "issuedDate": "2019-11-29T11:35:37+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2022-06-16T04:31:31.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:22.000+0000"
            },
            {
              "topicName": "Understanding the Functional Grid",
              "issuedDate": "2022-09-15T04:35:55.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-09-15T04:51:13.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Community Mobilization",
              "issuedDate": "2020-08-07T08:06:00.000+0000"
            },
            {
              "topicName": "स्वच्छ पानी को पाने की जंग",
              "issuedDate": "2019-12-02T11:46:00.000+0000"
            },
            {
              "topicName": "Introduction to Fluorosis",
              "issuedDate": "2020-01-07T07:43:00.000+0000"
            },
            {
              "topicName": "Manu Test Topic",
              "issuedDate": "2019-11-27T09:10:00.000+0000"
            },
            {
              "topicName": "Test topic",
              "issuedDate": "2019-11-30T11:27:00.000+0000"
            },
            {
              "topicName": "Arghyam's Journey and the need for Re-imagining CB",
              "issuedDate": "2020-03-02T06:30:00.000+0000"
            },
            {
              "topicName": "Test topic",
              "issuedDate": "2019-11-29T11:41:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2022-06-16T12:30:00.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-11-29T11:00:00.000+0000"
            }
          ],
          "roleTitle": ["V C F"],
          "qualificationTitle": ["Water recharge"]
        }
      }
    },
    {
      "practitionerId": "f10b75e8-8d1c-4591-be30-7ba8a9e0de33",
      "data": {
        "userDetailData": {
          "userId": "f10b75e8-8d1c-4591-be30-7ba8a9e0de33",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/Roopa .jpg",
          "name": "Roopa Anand Rao",
          "phoneNumber": "9845465324",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.9361721",
          "userRole": [null, "TRAINEE"],
          "longitude": "77.5878605",
          "badgeDetails": [
            {
              "badgeId": 407,
              "badgeName": "BFT"
            }
          ],
          "traineeData": [
            {
              "topicName": "Orientation on Jala Sanjeevini /ಓರಿಯೆಂಟೇಷನ್ ಓನ್ ಜಲ ಸಂಜೀವಿನೀ/Elected members/ಚುನಾಯಿತ ಸದಸ್ಯರು",
              "issuedDate": "2022-12-02T07:011:15.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "fe428fb5-8dbb-463f-af7a-c37857745007",
      "data": {
        "userDetailData": {
          "userId": "fe428fb5-8dbb-463f-af7a-c37857745007",
          "emailId": "sowmyaganesh92@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "sowmya lg",
          "phoneNumber": "9481216292",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.9745016",
          "userRole": ["TRAINEE"],
          "longitude": "77.577996",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for d         different groups_BFTs_GKMs",
              "issuedDate": "2023-01-02T11:34:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "e931ea86-989b-4188-aa8b-8052253f14ce",
      "data": {
        "userDetailData": {
          "userId": "e931ea86-989b-4188-aa8b-8052253f14ce",
          "emailId": "lokeshtrainer@outlook.in",
          "country": "India",
          "state": "Karnataka",
          "city": "",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2Fe931ea86-989b-4188-aa8b-8052253f14ce.jpg",
          "name": "Lokesh C",
          "phoneNumber": "9980023070",
          "district": "Chikkaballapur District",
          "programId": 27,
          "latitude": "13.400853",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "78.053478",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ಅಂತರ್ಜಲ ಚೇತನ - ಮಾದರಿ ವಿಷಯ (Antarajala Chetana - Example Topic)",
              "issuedDate": "2021-09-02T11:06:05.000+0000"
            },
            {
              "topicName": "ವಿ   ವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2023-01-02T11:35:23.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Orientation on Jala Sanjeevini /ಓರಿಯೆಂಟೇಷನ್ ಓನ್ ಜಲ     ಸಂಜೀವಿನೀ/Elected members/ಚುನಾಯಿತ ಸದಸ್ಯರು",
              "issuedDate": "2022-12-02T09:00:00.000+0000"
            },
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂ      ಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2023-01-002T12:34:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "00191047-642a-4678-aff6-22162d924cf5",
      "data": {
        "userDetailData": {
          "userId": "00191047-642a-4678-aff6-22162d924cf5",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "",
          "photo": "",
          "name": "Gangamani.HR, tumkur District coordinator ,FES.",
          "phoneNumber": "8884008788",
          "district": "Tumakuru District",
          "programId": 27,
          "latitude": "13.341262",
          "userRole": ["TRAINEE"],
          "longitude": "77.1020520000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini f         for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21T10:00:26.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "e1fe48c5-1f99-4815-9720-ef0f32dbcaab",
      "data": {
        "userDetailData": {
          "userId": "e1fe48c5-1f99-4815-9720-ef0f32dbcaab",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Venugopal",
          "phoneNumber": "9448738869",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.9749756",
          "userRole": ["TRAINEE"],
          "longitude": "77.5781368",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GK         KMs",
              "issuedDate": "2023-01-02T11:35:18.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "919b177f-2a3d-4883-8060-149bf84012b4",
      "data": {
        "userDetailData": {
          "userId": "919b177f-2a3d-4883-8060-149bf84012b4",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Niz-Bahjani",
          "photo": "",
          "name": "Atikur",
          "phoneNumber": "6000415919",
          "district": "Nalbari District",
          "programId": 27,
          "latitude": "26.3857105",
          "userRole": [null, "TRAINEE"],
          "longitude": "91.431389",
          "badgeDetails": [
            {
              "badgeId": 407,
              "badgeName": "BFT"
            }
          ],
          "traineeData": [
            {
              "topicName": "JD test - Assam",
              "issuedDate": "2022-12-12T12:33:21.000+0000"
            },
            {
              "topicName": "JD test - Completing the self assessment course ",
              "issuedDate": "2023-01-21T07:40:38.000+0000"
            },
            {
              "topicName": "JD test - Making a JalShala Plan",
              "issuedDate": "2023-01-21T07:44:47.000+0000"
            },
            {
              "topicName": "JD test - Executing the JalShala ",
              "issuedDate": "2023-01-21T07:50:15.000+0000"
            },
            {
              "topicName": "District Workshop training for block team",
              "issuedDate": "2023-04-20T12:25:54.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-04-20T12:28:09.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "91b3214a-3f2c-4424-8112-1b73d35e8e74",
      "data": {
        "userDetailData": {
          "userId": "91b3214a-3f2c-4424-8112-1b73d35e8e74",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Guwahati",
          "photo": "",
          "name": "Jenifer Yasmin ",
          "phoneNumber": "9864363630",
          "district": "Kamrup Metropolitan District",
          "programId": 27,
          "latitude": "26.151840209960938",
          "userRole": ["TRAINEE"],
          "longitude": "91.79286737971188",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "JD - Making the District Plan",
              "issuedDate": "2023-03-09T11:16:25.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b8901b46-902f-4142-bec0-09b4d326dc9f",
      "data": {
        "userDetailData": {
          "userId": "b8901b46-902f-4142-bec0-09b4d326dc9f",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Guwahati",
          "photo": "",
          "name": "Arnab Bharali",
          "phoneNumber": "9864080467",
          "district": "Kamrup Metropolitan District",
          "programId": 27,
          "latitude": "26.152005",
          "userRole": ["TRAINEE"],
          "longitude": "91.7984530000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "JD - Making the District Plan",
              "issuedDate": "2023-03-18T12:18:16.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "74852ff7-d5bb-4ceb-bf41-a7e8b9dede53",
      "data": {
        "userDetailData": {
          "userId": "74852ff7-d5bb-4ceb-bf41-a7e8b9dede53",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Tejas Mahajan",
          "phoneNumber": "9686077046",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.9247368",
          "userRole": ["TRAINEE"],
          "longitude": "77.5905686",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 1",
              "issuedDate": "2023-03-20T09:55:55.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "d7e2fcef-65df-409c-9bb2-5d08080aa9d3",
      "data": {
        "userDetailData": {
          "userId": "d7e2fcef-65df-409c-9bb2-5d08080aa9d3",
          "emailId": "rameshmchitradurga@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "Chitradurga",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/d7e2fcef-65df-409c-9bb2-5d08080aa9d39e20538e-2947-400f-a345-baf2c3e9266b",
          "name": "Ramesh M District Coordinator",
          "phoneNumber": "9449128416",
          "district": "Chitradurga District",
          "programId": 27,
          "latitude": "14.2273300000001",
          "userRole": ["TRAINEE"],
          "longitude": "76.4027100000001",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್         _BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
              "issuedDate": "2022-12-21T10:00:30.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "0c193e5f-d189-464b-82cf-443317306d87",
      "data": {
        "userDetailData": {
          "userId": "0c193e5f-d189-464b-82cf-443317306d87",
          "emailId": "Gurudutt@arghyam.org",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F0c193e5f-d189-464b-82cf-443317306d87.jpg",
          "name": "Gurudutt Ramachandra ",
          "phoneNumber": "9008722271",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.96774",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.588913",
          "badgeDetails": [
            {
              "badgeId": 49,
              "badgeName": "Master Trainer"
            },
            {
              "badgeId": 12,
              "badgeName": "Solutions"
            },
            {
              "badgeId": 17,
              "badgeName": "Arghyam employees"
            },
            {
              "badgeId": 211,
              "badgeName": "Expert Hindi Trainers"
            },
            {
              "badgeId": 408,
              "badgeName": "GKM"
            },
            {
              "badgeId": 16,
              "badgeName": "PDA Champion"
            },
            {
              "badgeId": 1706,
              "badgeName": "Man"
            },
            {
              "badgeId": 1672,
              "badgeName": "aztec1"
            },
            {
              "badgeId": 1674,
              "badgeName": "Block Trainer SSA"
            },
            {
              "badgeId": 1671,
              "badgeName": "Assam Trainer"
            }
          ],
          "traineeData": [
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2021-02-10T08:46:47.000+0000"
            },
            {
              "topicName": "स्वच्छ पानी को पाने की जंग",
              "issuedDate": "2019-12-02T10:50:15+0000"
            },
            {
              "topicName": "Manu Test Topic",
              "issuedDate": "2019-12-03T18:09:31+0000"
            },
            {
              "topicName": "ಇದು ಒಂದು ಮಾದರಿ",
              "issuedDate": "2019-12-02T005:27:20+0000"
            },
            {
              "topicName": "Content sharing on PDA",
              "issuedDate": "2019-12-02T10:59:02+0000"
            },
            {
              "topicName": "ಅಂತರ್ಜಲ ಚೇತನ -  ಮಾದರಿ ವಿಷಯ (Antarajala Chetana - Example Topic)",
              "issuedDate": "2021-09-02T11:05:55.000+0000"
            },
            {
              "topicName": "PDA Demo Tamil ",
              "issuedDate": "2021-04-29T12:20:12.000+0000"
            },
            {
              "topicName": "PDA Dash Board Tamil",
              "issuedDate": "2021-04-29T12:18:39.000+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-13T10:30:45+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2021-04-05T10:41:01.000+0000"
            },
            {
              "topicName": "Testing new topic for a training mock run.",
              "issuedDate": "2019-12-12T10:29:23+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2019-12-08T18:28:41+0000"
            },
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2020-02-18T07:20:55+0000"
            },
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2020-01-06T07:35:42+0000"
            },
            {
              "topicName": "नळ पाणी पुरवठा सुविधांची देखभाल आणि दुरुस्ती । प्रशिक्षक",
              "issuedDate": "2020-03-06T06:18:08+00000"
            },
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-07T07:14:48.000+0000"
            },
            {
              "topicName": "Panchayat and Governance of Commons",
              "issuedDate": "2020-08-13T06:24:14.000+0000"
            },
            {
              "topicName": "Basics of PDA",
              "issuedDate": "2020-09-28T07:28:43.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-06T06:26:42.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T04:40:40+0000"
            },
            {
              "topicName": "Panchayat and Governance of Commons",
              "issuedDate": "2020-05-11T06:08:45.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-11T11:08:05+0000"
            },
            {
              "topicName": "Panchayat and Governance of Commons",
              "issuedDate": "2020-07-27T05:46:13.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T09:48:28.000+0000"
            },
            {
              "topicName": "PDA session: Training, Capacity Building and Hands on",
              "issuedDate": "2020-09-04T09:14:33.000+0000"
            },
            {
              "topicName": "Codes instead of Scans",
              "issuedDate": "2021-02-04T06:06:54.000+0000"
            },
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2020-12-04T06:23:15.000+0000"
            },
            {
              "topicName": "ಅಂತರ್ಜಲ ಚೇತನ - ಮಾದರಿ ವಿಷಯ (Antarajala Chetana - Example Topic)",
              "issuedDate": "2021-12-30T10:30:15.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2022-01-21T12:23:07.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:27:30.000+0000"
            },
            {
              "topicName": "ಜಲ ಸಂಜೀವಿನಿ   ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್ (ಜಾಗೃತಿ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ದಾಖಲಿಸುವುದು) _ ಬಿಎಫ್ ಟಿ _ ಜಿಕೆಎಂ /  Orientation on Jala Sanjeevini (Capturing Awareness programs)_ BFTs _ GKMs",
              "issuedDate": "2023-02-28T05:09:46.000+0000"
            },
            {
              "topicName": "ಜನರ ಸಹಭಾಗಿತ್ವದಲ್ಲಿ ಗ್ರಾಮೀಣ ವಿಶ್ಲ      ಲೇಷಣೆಯಡಿ ಕಾಲ್ನಡಿಗೆ  ಕುರಿತು _ GKM_BFT / Transect walk_ GKM_BFT",
              "issuedDate": "2023-08-25T06:54:23.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-26T11:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2021-02-10T08:41:00.000+0000"
            },
            {
              "topicName": "Test run for Assisted Scanning",
              "issuedDate": "2020-08-05T13:10:00.000+0000"
            },
            {
              "topicName": "ಇದು ಒಂದು ಮಾದರಿ",
              "issuedDate": "2019-12-02T06:24:00.000+0000"
            },
            {
              "topicName": "Capacity Building @ SScale - Experiences from FES in Odisha",
              "issuedDate": "2021-06-04T11:30:00.000+0000"
            },
            {
              "topicName": "ಅಂತರ್ಜಲ ಚೇತನ - ಮಾದರಿ ವಿಷಯ  ಯ (Antarajala Chetana - Example Topic)",
              "issuedDate": "2021-09-02T09:30:00.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-05T07:30:00.000+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-05T07:30:00.000+0000"
            },
            {
              "topicName": "ಅಟಲ್ ಭೂಜಲ್ ಯೋಜನೆಯ ಅವಲೋಕನ",
              "issuedDate": "2021-05-25T12:30:00.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2019-12-26T11:30:00.000+0000"
            },
            {
              "topicName": "Testing new topic for a training mock run.",
              "issuedDate": "2019-12-12T10:34:00.000+0000"
            },
            {
              "topicName": "Testing Again",
              "issuedDate": "2019-12-13T13:23:00.000+0000"
            },
            {
              "topicName": "PDA - Trainer App",
              "issuedDate": "2019-12-13T11:00:00.000+0000"
            },
            {
              "topicName": "Introduction to Fluorosis",
              "issuedDate": "2020-01-07T07:43:00.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2019-12-08T18:32:00.000+0000"
            },
            {
              "topicName": "Something went wrong",
              "issuedDate": "2020-02-17T13:22:00.000+0000"
            },
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2020-02-18T08:10:00.000+0000"
            },
            {
              "topicName": "Capacity Building Solutions",
              "issuedDate": "2020-03-02T08:00:00.000+0000"
            },
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2020-01-06T07:30:00.000+0000"
            },
            {
              "topicName": "जल जीवन मिशन । प्रशिक्षक",
              "issuedDate": "2020-02-11T17:35:00.000+0000"
            },
            {
              "topicName": "नळ पाणी पुरवठा सुविधांची देखभाल आणि दुरुस्ती । प्रशिक्षक",
              "issuedDate": "2020-03-06T07:15:00.000+0000"
            },
            {
              "topicName": "Panchayat and Governance of Commons",
              "issuedDate": "2020-08-13T06:30:00.000+0000"
            },
            {
              "topicName": "Basics of PDA",
              "issuedDate": "2020-09-28T07:28:00.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-10-15T06:50:00.000+0000"
            },
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-12-10T08:05:00.000+0000"
            },
            {
              "topicName": "Getting Started with PDA",
              "issuedDate": "2020-11-26T06:48:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2021-08-19T07:30:00.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-03-18T08:00:00.000+0000"
            },
            {
              "topicName": "Panchayat and Governance of Commons",
              "issuedDate": "2020-05-11T06:57:00.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-26T11:30:00.000+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-11T11:15:00.000+0000"
            },
            {
              "topicName": "Panchayat and Governance of Commons",
              "issuedDate": "2020-07-27T06:44:00.000+0000"
            },
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2020-08-19T11:30:00.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Best Practices - Smitha Murthy",
              "issuedDate": "2020-10-21T12:30:00.000+0000"
            },
            {
              "topicName": "Introduction to Springs | MTs",
              "issuedDate": "2020-12-04T07:00:00.000+0000"
            },
            {
              "topicName": "ಅಂತರ್ಜಲ ಚೇತನ - ಮಾದರಿ ವಿಷಯ (Antarajala Chetana - Example Topic)",
              "issuedDate": "2021-12-30T11:00:00.000+0000"
            },
            {
              "topicName": "PDA Demo",
              "issuedDate": "2022-01-21T12:45:00.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T11:30:00.000+0000"
            },
            {
              "topicName": "ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್ (ಜಾಗೃತಿ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ದಾಖಲಿಸುವುದು) _ ಬಿಎಫ್ ಟಿ _ ಜಿಕೆಎಂ               ಂ /  Orientation on Jala Sanjeevini (Capturing Awareness programs)_ BFTs _ GKMs",
              "issuedDate": "2023-02-28T06:08:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f7e93313-2909-4493-9a53-fadfd033201b",
      "data": {
        "userDetailData": {
          "userId": "f7e93313-2909-4493-9a53-fadfd033201b",
          "emailId": "RK.kalluri@gmail.com",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "RK kalluri",
          "phoneNumber": "9663369033",
          "district": "Bengaluru Urban District",
          "programId": 27,
          "latitude": "12.8743821",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.5905451",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2021-04-05T10:40:58.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "ಅಂತರ್ಜಲ ಚೇತನ - ಮಾದರಿ ವಿಷಯ (Antarajala Chetana - Example Topic)",
              "issuedDate": "2021-09-02T09:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
  
    {
      "practitionerId": "7dd8cae2-8102-44ca-b042-6a1d51b97504",
      "data": {
        "userDetailData": {
          "userId": "7dd8cae2-8102-44ca-b042-6a1d51b97504",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Joy Mangla",
          "photo": "",
          "name": "Sumanjita Barman",
          "phoneNumber": "8404001059",
          "district": "Nalbari District",
          "programId": 27,
          "latitude": "26.4387671",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "91.4362206",
          "badgeDetails": [
            {
              "badgeId": 408,
              "badgeName": "GKM"
            }
          ],
          "traineeData": [
            {
              "topicName": "Image composition and layouts LL3",
              "issuedDate": "2022-03-29T07:37:51.000+0000"
            },
            {
              "topicName": "Taking good photos and making posters",
              "issuedDate": "2022-04-05T07:38:37.000+0000"
            },
            {
              "topicName": "Introduction to Visual Design LL3",
              "issuedDate": "2022-03-22T07:25:39.000+0000"
            },
            {
              "topicName": "JD test - Assam",
              "issuedDate": "2022-12-12T12:21:46.000+0000"
            },
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 2 ",
              "issuedDate": "2023-03-09T03:38:36.000+0000"
            },
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 1",
              "issuedDate": "2023-03-09T03:32:14.000+0000"
            },
            {
              "topicName": "JD - Making the District Plan",
              "issuedDate": "2023-03-09T03:06:19.000+0000"
            },
            {
              "topicName": "Planning the Jalshala",
              "issuedDate": "2023-04-28T05:57:52.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-05-26T11:31:10.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "JD - Making the District Plan",
              "issuedDate": "2023-03-31T13:32:24.000+0000"
            },
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 1",
              "issuedDate": "2023-03-31T13:45:00.000+0000"
            },
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 2 ",
              "issuedDate": "2023-03-31T13:45:00.000+0000"
            },
            {
              "topicName": "District Workshop training for block team",
              "issuedDate": "2023-04-30T10:48:58.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-04-30T11:00:00.000+0000"
            },
            {
              "topicName": "Planning the Jalshala",
              "issuedDate": "2023-05-05T04:20:18.000+0000"
            },
            {
              "topicName": "Planning of the JalShala",
              "issuedDate": "2023-07-31T16:30:00.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-07-31T17:15:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "bf9b2448-f718-4224-96d4-06bdb59034a3",
      "data": {
        "userDetailData": {
          "userId": "bf9b2448-f718-4224-96d4-06bdb59034a3",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Guwahati",
          "photo": "",
          "name": "Arnav Kr Baruah",
          "phoneNumber": "8011009466",
          "district": "Kamrup Metropolitan District",
          "programId": 27,
          "latitude": "26.1518904",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "91.793075",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 1",
              "issuedDate": "2023-03-18T12:23:39.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-04-25T07:46:36.000+0000"
            },
            {
              "topicName": "Planning the Jalshala",
              "issuedDate": "2023-04-28T05:58:40.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-05-22T13:53:07.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Planning of the JalShala",
              "issuedDate": "2023-07-31T16:30:00.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-07-31T17:15:00.000+0000"
            }
          ],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "5a9679fe-6c56-4e22-aa0a-14adb9703410",
      "data": {
        "userDetailData": {
          "userId": "5a9679fe-6c56-4e22-aa0a-14adb9703410",
          "emailId": "",
          "country": "India",
          "state": "Karnataka",
          "city": "Bengaluru",
          "photo": "",
          "name": "Kiran Kumar Sen",
          "phoneNumber": "7718023736",
          "district": "Bangalore Urban",
          "programId": 27,
          "latitude": "12.9715987",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "77.5945627",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "PDA - Participant App",
              "issuedDate": "2019-12-26T10:42:03+0000"
            },
            {
              "topicName": "PDA - Program App",
              "issuedDate": "2019-12-26T11:49:08+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-19T10:12:20.000+0000"
            },
            {
              "topicName": "PDA mock session - Churu",
              "issuedDate": "2020-12-15T14:35:39.000+0000"
            },
            {
              "topicName": "JD test - Assam",
              "issuedDate": "2023-01-10T06:20:06.000+0000"
            },
            {
              "topicName": "JD test - Completing the self assessment course ",
              "issuedDate": "2023-01-21T08:55:51.000+0000"
            },
            {
              "topicName": "JD test -  Training of Block Trainers for JalDoot Program ",
              "issuedDate": "2023-01-21T10:12:57.000+0000"
            },
            {
              "topicName": "JD - Making the District Plan",
              "issuedDate": "2023-03-09T06:08:37.000+0000"
            },
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 1",
              "issuedDate": "2023-03-20T09:54:33.000+0000"
            },
            {
              "topicName": "District Workshop training for block team",
              "issuedDate": "2023-04-20T10:15:05.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-04-20T10:17:40.000+0000"
            },
            {
              "topicName": "Planning the Jalshala",
              "issuedDate": "2023-04-28T05:56:37.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-05-16T10:56:42.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Churu Fluorosis PDA demo",
              "issuedDate": "2020-10-21T12:06:00.000+0000"
            },
            {
              "topicName": "PDA mock session - Churu",
              "issuedDate": "2020-12-18T15:25:00.000+0000"
            },
            {
              "topicName": "JD test - Assam",
              "issuedDate": "2022-12-12T18:30:00.000+0000"
            }
          ],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "f5bda8c8-9b5d-4f02-aefc-e2ecf01d710c",
      "data": {
        "userDetailData": {
          "userId": "f5bda8c8-9b5d-4f02-aefc-e2ecf01d710c",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Guwahati",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/f5bda8c8-9b5d-4f02-aefc-e2ecf01d710c6055e677-98d6-4f1e-90e3-c53626523f01",
          "name": "Sarmila Kakoti",
          "phoneNumber": "7662020007",
          "district": "Kamrup District",
          "programId": 27,
          "latitude": "26.1227192",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "91.7643362",
          "badgeDetails": [
            {
              "badgeId": 407,
              "badgeName": "BFT"
            }
          ],
          "traineeData": [
            {
              "topicName": "JD test - Assam",
              "issuedDate": "2022-12-12T12:37:26.000+0000"
            },
            {
              "topicName": "JD test -  Training of Block Trainers for JalDoot Program ",
              "issuedDate": "2023-01-19T07:21:39.000+0000"
            },
            {
              "topicName": "JD test - Monitoring of the Block Plan",
              "issuedDate": "2023-01-21T10:28:11.000+0000"
            },
            {
              "topicName": "JD test - Training of Block Coordinators for JalDoot Program ",
              "issuedDate": "2023-01-31T10:32:56.000+0000"
            },
            {
              "topicName": "JD test - Training of District Coordinators for JalDoot Program Execution",
              "issuedDate": "2023-01-31T11:36:14.000+0000"
            },
            {
              "topicName": "District Workshop training for block team",
              "issuedDate": "2023-04-20T12:01:40.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-04-20T12:04:20.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "JD - Making the District Plan",
              "issuedDate": "2023-03-31T13:32:24.000+0000"
            },
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 1",
              "issuedDate": "2023-03-31T13:45:00.000+0000"
            },
            {
              "topicName": "JD - Facilitate two practical JalShalas for training of Block trainers - 2 ",
              "issuedDate": "2023-03-31T13:45:00.000+0000"
            },
            {
              "topicName": "Planning of the JalShala",
              "issuedDate": "2023-07-31T16:30:00.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-07-31T17:15:00.000+0000"
            }
          ],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b7031ba5-8d50-43d4-82db-b63e173db13c",
      "data": {
        "userDetailData": {
          "userId": "b7031ba5-8d50-43d4-82db-b63e173db13c",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Guwahati",
          "photo": "",
          "name": "Sujata Gautam",
          "phoneNumber": "9706373567",
          "district": "Kamrup Metropolitan District",
          "programId": 27,
          "latitude": "26.1362946",
          "userRole": ["TRAINER", "TRAINEE"],
          "longitude": "91.802353",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "JD test - Assist for Jal Doot Journey",
              "issuedDate": "2023-01-19T07:12:54.000+0000"
            },
            {
              "topicName": "JD test - Making of a block plan",
              "issuedDate": "2023-01-27T07:45:51.000+0000"
            },
            {
              "topicName": "JD test - Conducting a block trainers TOT",
              "issuedDate": "2023-01-27T07:49:09.000+0000"
            },
            {
              "topicName": "JD Test - Reporting on block plan",
              "issuedDate": "2023-01-27T07:52:16.000+0000"
            },
            {
              "topicName": "JD test - Report on Progress post JalShala with field work Data",
              "issuedDate": "2023-01-27T07:53:43.000+0000"
            },
            {
              "topicName": "Planning the Jalshala",
              "issuedDate": "2023-04-28T05:57:55.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-05-29T11:00:31.000+0000"
            }
          ],
          "trainerData": [
            {
              "topicName": "Planning of the JalShala",
              "issuedDate": "2023-07-31T16:30:00.000+0000"
            },
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-07-31T17:15:00.000+0000"
            }
          ],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "281eb629-9078-40cf-a6f3-57ee6d3322ea",
      "data": {
        "userDetailData": {
          "userId": "281eb629-9078-40cf-a6f3-57ee6d3322ea",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "No 3 Murkong Selek Part 2",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/281eb629-9078-40cf-a6f3-57ee6d3322ea8953e8eb-7247-4c58-87dd-cf33c7203619",
          "name": "Hemanta pegu",
          "phoneNumber": "6001155042",
          "district": "Dhemaji District",
          "programId": 27,
          "latitude": "27.8205223625558",
          "userRole": ["TRAINEE"],
          "longitude": "95.2144120519937",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-06-30T07:24:18.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "42740f34-6ea7-41e2-be0e-7ea2a846b001",
      "data": {
        "userDetailData": {
          "userId": "42740f34-6ea7-41e2-be0e-7ea2a846b001",
          "emailId": "nehapawar@arghyam.org",
          "country": "India",
          "state": "Maharashtra",
          "city": "Navi Mumbai",
          "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F42740f34-6ea7-41e2-be0e-7ea2a846b001.jpg",
          "name": "Neha Pawar",
          "phoneNumber": "9653190930",
          "district": "Thane District",
          "programId": 27,
          "latitude": "19.0330488",
          "userRole": ["TRAINEE"],
          "longitude": "73.0296625",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "CBP Orientation",
              "issuedDate": "2020-08-28T10:16:16.000+0000"
            },
            {
              "topicName": "Capacity Building @ Scale - Experiences from FES in Odisha",
              "issuedDate": "2021-06-04T11:24:28.000+0000"
            },
            {
              "topicName": "PDA Updates",
              "issuedDate": "2020-10-06T06:27:07.000+0000"
            },
            {
              "topicName": "Virtual Interactions - Needs and Benefits",
              "issuedDate": "2020-11-19T10:13:13.000+0000"
            },
            {
              "topicName": "PDA Dashboard Demo",
              "issuedDate": "2021-11-26T05:27:49.000+0000"
            },
            {
              "topicName": "Well Monitoring - Example",
              "issuedDate": "2022-06-16T09:26:46.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": [],
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "13fc614f-fced-4a20-88d4-13da63c5f701",
      "data": {
        "userDetailData": {
          "userId": "13fc614f-fced-4a20-88d4-13da63c5f701",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Guwahati",
          "photo": "",
          "name": "Rajdeep",
          "phoneNumber": "8474806720",
          "district": "Kamrup Metropolitan District",
          "programId": 27,
          "latitude": "26.1210801",
          "userRole": ["TRAINEE"],
          "longitude": "91.8130694",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-06-15T06:08:12.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "b9d075fd-535e-4931-a9ec-07211bfe43e2",
      "data": {
        "userDetailData": {
          "userId": "b9d075fd-535e-4931-a9ec-07211bfe43e2",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Guwahati",
          "photo": "",
          "name": "WKA",
          "phoneNumber": "8448271790",
          "district": "Kamrup Metropolitan District",
          "programId": 27,
          "latitude": "26.1210678",
          "userRole": ["TRAINEE"],
          "longitude": "91.8130789",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-06-15T06:08:16.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "6bf65efc-f264-43c2-a0be-860d7a2c1ba4",
      "data": {
        "userDetailData": {
          "userId": "6bf65efc-f264-43c2-a0be-860d7a2c1ba4",
          "emailId": "adhikarydulalrte@gmail.com",
          "country": "India",
          "state": "Assam",
          "city": "Dhubri",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/6bf65efc-f264-43c2-a0be-860d7a2c1ba461a6156a-427c-49c5-8a0a-682b3a30a886",
          "name": "Dulal Adhikary ",
          "phoneNumber": "9954791470",
          "district": "Dhubri District",
          "programId": 27,
          "latitude": "26.0204836",
          "userRole": ["TRAINEE"],
          "longitude": "89.994388",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-06-16T09:27:58.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "9fc9a526-f8f1-41a0-9315-90ab1034c015",
      "data": {
        "userDetailData": {
          "userId": "9fc9a526-f8f1-41a0-9315-90ab1034c015",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Dhubri",
          "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/9fc9a526-f8f1-41a0-9315-90ab1034c015afb60c68-723a-48e9-91af-9554e56988b1",
          "name": "Parmeswar Ray",
          "phoneNumber": "9706016463",
          "district": "Dhubri District",
          "programId": 27,
          "latitude": "26.020454",
          "userRole": ["TRAINEE"],
          "longitude": "89.9944097",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-06-16T09:38:15.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    {
      "practitionerId": "29165e83-2d36-44a0-abeb-29671c4c1ed9",
      "data": {
        "userDetailData": {
          "userId": "29165e83-2d36-44a0-abeb-29671c4c1ed9",
          "emailId": "",
          "country": "India",
          "state": "Assam",
          "city": "Dhubri",
          "photo": "",
          "name": "Nilmoni Sutradhar",
          "phoneNumber": "8638143546",
          "district": "Dhubri District",
          "programId": 27,
          "latitude": "26.0204738",
          "userRole": ["TRAINEE"],
          "longitude": "89.9944162",
          "badgeDetails": [],
          "traineeData": [
            {
              "topicName": "Execution of the JalShala",
              "issuedDate": "2023-06-16T09:16:16.000+0000"
            }
          ],
          "trainerData": [],
          "roleTitle": null,
          "qualificationTitle": []
        }
      }
    },
    
      {
        "practitionerId": "9ed66187-2ab1-4850-a46e-6b643d6152c3",
        "data": {
          "userDetailData": {
            "userId": "9ed66187-2ab1-4850-a46e-6b643d6152c3",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Dhubri",
            "photo": "",
            "name": "Sofior Rahman ",
            "phoneNumber": "9101644900",
            "district": "Dhubri District",
            "programId": 27,
            "latitude": "26.0241990000001",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "89.9754380000001",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-16T09:16:21.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "1893c0a9-e5b6-4d84-81e9-fdccb4099495",
        "data": {
          "userDetailData": {
            "userId": "1893c0a9-e5b6-4d84-81e9-fdccb4099495",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Dhubri",
            "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/1893c0a9-e5b6-4d84-81e9-fdccb4099495396fc897-bea8-471d-bf6c-354f68176350",
            "name": "Md Amir Hossain",
            "phoneNumber": "7896386632",
            "district": "Dhubri District",
            "programId": 27,
            "latitude": "26.0204986",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "89.9944233",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-16T09:28:55.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "110ef2a1-b7ef-4492-837a-610b52065d14",
        "data": {
          "userDetailData": {
            "userId": "110ef2a1-b7ef-4492-837a-610b52065d14",
            "emailId": "arunchnath86@gmail.com",
            "country": "India",
            "state": "Assam",
            "city": "Dhubri",
            "photo": "",
            "name": "Arun Chandra nath ",
            "phoneNumber": "",
            "district": "Dhubri District",
            "programId": 27,
            "latitude": "26.0204752",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "89.9943987",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-16T09:33:17.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "ca50e610-790e-4e87-88ae-de258d6f0984",
        "data": {
          "userDetailData": {
            "userId": "ca50e610-790e-4e87-88ae-de258d6f0984",
            "emailId": "nkushal474@gmail.com",
            "country": "India",
            "state": "Assam",
            "city": "Dhubri",
            "photo": "",
            "name": "Kushal Nath",
            "phoneNumber": "8876499838",
            "district": "Dhubri District",
            "programId": 27,
            "latitude": "26.0204611",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "89.9944077",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-16T09:28:06.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "940bad7d-ebab-40dd-8b9c-d03951723ce1",
        "data": {
          "userDetailData": {
            "userId": "940bad7d-ebab-40dd-8b9c-d03951723ce1",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Dhubri",
            "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/940bad7d-ebab-40dd-8b9c-d03951723ce11a647d0d-35e7-4763-b355-3060cc40f597",
            "name": "Mandira Prodhani",
            "phoneNumber": "9707415874",
            "district": "Dhubri District",
            "programId": 27,
            "latitude": "26.0204613",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "89.9944109",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-16T09:30:00.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "d7ff3cb7-f173-4971-a433-471f0aab2add",
        "data": {
          "userDetailData": {
            "userId": "d7ff3cb7-f173-4971-a433-471f0aab2add",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Dhubri",
            "photo": "",
            "name": "Sanjit kumar das",
            "phoneNumber": "9101382542",
            "district": "Dhubri District",
            "programId": 27,
            "latitude": "26.0204587",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "89.9944102",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-16T10:17:48.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "b6ef39c8-f38b-40df-9383-69e0d5eeeb19",
        "data": {
          "userDetailData": {
            "userId": "b6ef39c8-f38b-40df-9383-69e0d5eeeb19",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "",
            "name": "Sankor Jyoti Boruah",
            "phoneNumber": "9577746751",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5046451",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.3307278",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:41:44.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "c3efa9f7-b055-40bf-a1fd-6abfb6dd248f",
        "data": {
          "userDetailData": {
            "userId": "c3efa9f7-b055-40bf-a1fd-6abfb6dd248f",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "",
            "name": "Heman Hazarika",
            "phoneNumber": "9706563396",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5046638",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.3308418",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:41:39.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "42549872-9eea-474a-a594-621890e222e7",
        "data": {
          "userDetailData": {
            "userId": "42549872-9eea-474a-a594-621890e222e7",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/42549872-9eea-474a-a594-621890e222e730683462-2e5e-438e-bd49-19a4baafae5e",
            "name": "Robin kurmi",
            "phoneNumber": "8638770499",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5046538",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.3307853",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:43:56.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "e1ccedd7-f57c-459b-9821-edb85017a0a4",
        "data": {
          "userDetailData": {
            "userId": "e1ccedd7-f57c-459b-9821-edb85017a0a4",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "",
            "name": "Simanta Changmai",
            "phoneNumber": "7002238902",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.487336",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.360486",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:42:59.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "0b55fce6-afbd-4528-84fd-33bf04fda5d6",
        "data": {
          "userDetailData": {
            "userId": "0b55fce6-afbd-4528-84fd-33bf04fda5d6",
            "emailId": "bipul.sadiya@gmail.com",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/0b55fce6-afbd-4528-84fd-33bf04fda5d6c7f84a88-cc32-4d71-8094-884aaa218779",
            "name": "BIPUL UPADHYAY ",
            "phoneNumber": "7896061996",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5046453",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.3307282",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:45:50.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "8d3b7c58-4a3d-400a-bff6-b8dc2bffa831",
        "data": {
          "userDetailData": {
            "userId": "8d3b7c58-4a3d-400a-bff6-b8dc2bffa831",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "",
            "name": "Bhugil Neog",
            "phoneNumber": "9435426136",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5046589",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.3308179",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:45:26.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "a8b89bfc-e3f3-466a-9336-9b1bff1b3ec0",
        "data": {
          "userDetailData": {
            "userId": "a8b89bfc-e3f3-466a-9336-9b1bff1b3ec0",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "",
            "name": "DIGANTA BHAJANI",
            "phoneNumber": "9706778801",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5038240000001",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.3322450000001",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:37:45.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "00a4275f-77c9-4549-a1aa-03f02627ce8e",
        "data": {
          "userDetailData": {
            "userId": "00a4275f-77c9-4549-a1aa-03f02627ce8e",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "",
            "name": "Rupjyoti Gogoi",
            "phoneNumber": "7896649139",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5046449",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.330769",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:38:06.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "18ae1d53-99c6-4838-a0b5-22c2b83b2b35",
        "data": {
          "userDetailData": {
            "userId": "18ae1d53-99c6-4838-a0b5-22c2b83b2b35",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "",
            "name": "Sagar Panika",
            "phoneNumber": "9101328359",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5046565",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.3308069",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:43:47.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "43cbd0c4-ab9e-4f97-b943-d29673283717",
        "data": {
          "userDetailData": {
            "userId": "43cbd0c4-ab9e-4f97-b943-d29673283717",
            "emailId": "kamalborah179@gmail.com",
            "country": "India",
            "state": "Assam",
            "city": "Tinsukia",
            "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/43cbd0c4-ab9e-4f97-b943-d2967328371700a95064-4283-48db-95cd-269dc8dc5452",
            "name": "Kamal Borah ",
            "phoneNumber": "8876618682",
            "district": "Tinsukia District",
            "programId": 27,
            "latitude": "27.5046446",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.3307263",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-23T07:44:49.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "0fa6f373-8a59-43ce-86b4-46d2a5924bb8",
        "data": {
          "userDetailData": {
            "userId": "0fa6f373-8a59-43ce-86b4-46d2a5924bb8",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Dhip Bisara",
            "photo": "",
            "name": "Asraf Ali ",
            "phoneNumber": "6002009842",
            "district": "Udalguri District",
            "programId": 27,
            "latitude": "26.6861733",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "92.1877183",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-06T12:13:02.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "01cf5f0d-2ddc-4cff-9168-78a5ef6a0390",
        "data": {
          "userDetailData": {
            "userId": "01cf5f0d-2ddc-4cff-9168-78a5ef6a0390",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Sonari",
            "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/01cf5f0d-2ddc-4cff-9168-78a5ef6a03907bd5af29-ce16-43d8-b489-3f931ca5ace0",
            "name": "Susanta ",
            "phoneNumber": "7896109500",
            "district": "Charaideo District",
            "programId": 27,
            "latitude": "27.0317938",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "95.0078122",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-06T11:18:57.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "9484cabc-789c-490a-abc0-50cc74dbd4b7",
        "data": {
          "userDetailData": {
            "userId": "9484cabc-789c-490a-abc0-50cc74dbd4b7",
            "emailId": "DELETED BY USER",
            "country": "India",
            "state": "Assam",
            "city": "Silchar",
            "photo": "",
            "name": "DELETED BY USER",
            "phoneNumber": "DELETED BY USER",
            "district": "Cachar District",
            "programId": 27,
            "latitude": "24.8317389",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "92.801075",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-06T16:55:25.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "3dde8fd3-646c-429d-9a62-388274eb125a",
        "data": {
          "userDetailData": {
            "userId": "3dde8fd3-646c-429d-9a62-388274eb125a",
            "emailId": "krishnakanta.in@gmail.com",
            "country": "India",
            "state": "Assam",
            "city": "Silchar",
            "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/3dde8fd3-646c-429d-9a62-388274eb125ad3f01ed1-d13c-4cc2-858b-574ddc07e9ef",
            "name": "Krishna Kanta Das",
            "phoneNumber": "8638450046",
            "district": "Cachar District",
            "programId": 27,
            "latitude": "24.7769114",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "92.7932262",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-08T09:29:57.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      {
        "practitionerId": "704248ea-bdd5-4300-a69e-54264c035207",
        "data": {
          "userDetailData": {
            "userId": "704248ea-bdd5-4300-a69e-54264c035207",
            "emailId": "",
            "country": "India",
            "state": "Assam",
            "city": "Silchar",
            "photo": "",
            "name": "SATYAJIT DAS",
            "phoneNumber": "9435182615",
            "district": "Cachar District",
            "programId": 27,
            "latitude": "24.7769192",
            "userRole": [
              "TRAINEE"
            ],
            "longitude": "92.7931864",
            "badgeDetails": [],
            "traineeData": [
              {
                "topicName": "Execution of the JalShala",
                "issuedDate": "2023-06-08T09:16:10.000+0000"
              }
            ],
            "trainerData": [],
            "roleTitle": null,
            "qualificationTitle": []
          }
        }
      },
      
        {
          "practitionerId": "2d5d097f-c694-4188-bf09-1859357b1515",
          "data": {
            "userDetailData": {
              "userId": "2d5d097f-c694-4188-bf09-1859357b1515",
              "emailId": "darendrojit@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "Silchar",
              "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/2d5d097f-c694-4188-bf09-1859357b15154d6437bc-e2ac-4005-b8c2-be0e9f9460b8",
              "name": "L Darendrojit Singha",
              "phoneNumber": "9435600845",
              "district": "Cachar District",
              "programId": 27,
              "latitude": "24.7769169",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "92.7932158",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-08T09:22:04.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "3028ee3f-39b2-4f0f-89db-7672e037ef63",
          "data": {
            "userDetailData": {
              "userId": "3028ee3f-39b2-4f0f-89db-7672e037ef63",
              "emailId": "dipankarghosh777@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "Silchar",
              "photo": "",
              "name": "Dipankar Ghosh ",
              "phoneNumber": "7002463091",
              "district": "Cachar District",
              "programId": 27,
              "latitude": "24.7769023",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "92.7932117",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-08T09:27:47.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "4033ed96-355e-45b1-91ce-9f1fd8532b47",
          "data": {
            "userDetailData": {
              "userId": "4033ed96-355e-45b1-91ce-9f1fd8532b47",
              "emailId": "mannadas412@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "Silchar",
              "photo": "",
              "name": "Manna Kishore Das",
              "phoneNumber": "9954101470",
              "district": "Cachar District",
              "programId": 27,
              "latitude": "24.7769115",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "92.7932282",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-08T09:30:13.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "e5e059eb-5d83-4fe2-93d7-04ef49a1b047",
          "data": {
            "userDetailData": {
              "userId": "e5e059eb-5d83-4fe2-93d7-04ef49a1b047",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Dibrugarh",
              "photo": "",
              "name": "Niranjan Borah ",
              "phoneNumber": "7002574958",
              "district": "Dibrugarh District",
              "programId": 27,
              "latitude": "27.4682632",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "94.9120847",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-29T11:04:44.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "78ed1d0e-5194-4376-8b3f-f69c1391d3a3",
          "data": {
            "userDetailData": {
              "userId": "78ed1d0e-5194-4376-8b3f-f69c1391d3a3",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Hatsingimari",
              "photo": "",
              "name": "Golap Hussain",
              "phoneNumber": "9954740422",
              "district": "South Salmara Mankachar District",
              "programId": 27,
              "latitude": "25.7213199",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "89.8991823",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-11T09:28:34.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "6ddf90c1-9c9b-4ca1-a369-45db7c480042",
          "data": {
            "userDetailData": {
              "userId": "6ddf90c1-9c9b-4ca1-a369-45db7c480042",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Hatsingimari",
              "photo": "",
              "name": "Rezaul Alom ",
              "phoneNumber": "9957489515",
              "district": "South Salmara Mankachar District",
              "programId": 27,
              "latitude": "25.7213202",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "89.8991862",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-11T09:35:14.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "59652d89-4354-40d8-be71-8b5d15ca4d7d",
          "data": {
            "userDetailData": {
              "userId": "59652d89-4354-40d8-be71-8b5d15ca4d7d",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Hatsingimari",
              "photo": "",
              "name": "Debjit Ghosh",
              "phoneNumber": "9957524622",
              "district": "South Salmara Mankachar District",
              "programId": 27,
              "latitude": "25.7213202",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "89.8991862",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-11T09:33:40.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "eaea65ad-ae30-4949-9fcb-2933a042e796",
          "data": {
            "userDetailData": {
              "userId": "eaea65ad-ae30-4949-9fcb-2933a042e796",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Bajugaon No 1",
              "photo": "",
              "name": "Chhandami Rongpipi",
              "phoneNumber": "8638774385",
              "district": "Kokrajhar District",
              "programId": 27,
              "latitude": "26.4994999",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "89.8948853",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-12T03:30:22.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "b7934ad1-ac0f-4bc5-836e-46944134194a",
          "data": {
            "userDetailData": {
              "userId": "b7934ad1-ac0f-4bc5-836e-46944134194a",
              "emailId": "mazumderabdurrahman@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "Silchar",
              "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/b7934ad1-ac0f-4bc5-836e-46944134194aeb0c44fc-6b4f-44a1-b886-8b2b1c6aac32",
              "name": "Abdur Rahman Mazumder ",
              "phoneNumber": "9435191487",
              "district": "Cachar District",
              "programId": 27,
              "latitude": "24.7769151",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "92.7932165",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-08T09:27:55.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "68d802d6-0ce4-45cb-86d2-2ca69cf9d2a0",
          "data": {
            "userDetailData": {
              "userId": "68d802d6-0ce4-45cb-86d2-2ca69cf9d2a0",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Guwahati",
              "photo": "",
              "name": "D Wary",
              "phoneNumber": "8812914262",
              "district": "Kamrup Metropolitan District",
              "programId": 27,
              "latitude": "26.1211487",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "91.8131132",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-11T03:52:29.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "8784c9b7-1298-4c5b-aaad-082095062d5c",
          "data": {
            "userDetailData": {
              "userId": "8784c9b7-1298-4c5b-aaad-082095062d5c",
              "emailId": "nahoque0@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "Hatsingimari",
              "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/8784c9b7-1298-4c5b-aaad-082095062d5c85b96bf4-0917-4358-9e4e-906608ffbe67",
              "name": "NUR ALOM HOQUE",
              "phoneNumber": "8011970280",
              "district": "South Salmara Mankachar District",
              "programId": 27,
              "latitude": "25.7213222",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "89.8991847",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-11T09:28:46.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "ed78b801-9229-4182-b609-470f1ca00fa6",
          "data": {
            "userDetailData": {
              "userId": "ed78b801-9229-4182-b609-470f1ca00fa6",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Hatsingimari",
              "photo": "",
              "name": "Ariful Hoque Khandokar",
              "phoneNumber": "9678355072",
              "district": "South Salmara Mankachar District",
              "programId": 27,
              "latitude": "25.7213205",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "89.8991836",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-11T09:30:18.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "67544f1b-f881-498b-9bd5-b4044eee7e76",
          "data": {
            "userDetailData": {
              "userId": "67544f1b-f881-498b-9bd5-b4044eee7e76",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Hatsingimari",
              "photo": "",
              "name": "Abdul Awal Khan",
              "phoneNumber": "9954173394",
              "district": "South Salmara Mankachar District",
              "programId": 27,
              "latitude": "25.7213208",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "89.8991824",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-06-11T09:30:40.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "533b6923-355b-4a2d-b88a-802edf884268",
          "data": {
            "userDetailData": {
              "userId": "533b6923-355b-4a2d-b88a-802edf884268",
              "emailId": "nazrulluban@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "Gauripur",
              "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/533b6923-355b-4a2d-b88a-802edf884268dec762aa-9ec5-463b-bf4f-c1a1f43bac95",
              "name": "Nazrul Islam ",
              "phoneNumber": "9435849132",
              "district": "Dhubri District",
              "programId": 27,
              "latitude": "26.07587",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "89.9624284",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-29T13:47:01.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "a7db7bf8-9af9-440d-aab9-ced9b308ead4",
          "data": {
            "userDetailData": {
              "userId": "a7db7bf8-9af9-440d-aab9-ced9b308ead4",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Guwahati",
              "photo": "",
              "name": "Jolly Changmai Kalita ",
              "phoneNumber": "9435551220",
              "district": "Kamrup Metropolitan District",
              "programId": 27,
              "latitude": "26.1140504",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "91.7235627",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Planning of the JalShala",
                  "issuedDate": "2023-05-15T09:59:58.000+0000"
                },
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-15T10:05:54.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "5b3ecf7c-0070-489b-be1d-995dc1829f0a",
          "data": {
            "userDetailData": {
              "userId": "5b3ecf7c-0070-489b-be1d-995dc1829f0a",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Guwahati",
              "photo": "",
              "name": "BHRIGURAJ KASHYAP ",
              "phoneNumber": "8486626888",
              "district": "Kamrup Metropolitan District",
              "programId": 27,
              "latitude": "26.1210688",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "91.8131055",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-15T10:17:34.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "62c01ea6-4c0b-4564-9a72-d7b0c608554d",
          "data": {
            "userDetailData": {
              "userId": "62c01ea6-4c0b-4564-9a72-d7b0c608554d",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Guwahati",
              "photo": "",
              "name": "Dr. Mukut Kalita",
              "phoneNumber": "9365924417",
              "district": "Kamrup Metropolitan District",
              "programId": 27,
              "latitude": "26.1211531",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "91.8130949",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-15T10:12:05.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "5cdc1a8a-cb15-43f3-b601-8e5709a7332d",
          "data": {
            "userDetailData": {
              "userId": "5cdc1a8a-cb15-43f3-b601-8e5709a7332d",
              "emailId": "janardonburagohain97321@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "Guwahati",
              "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/5cdc1a8a-cb15-43f3-b601-8e5709a7332d9238c482-a136-4bef-9402-3cb287b5ad26",
              "name": "Janardon Buragohain",
              "phoneNumber": "9954936715",
              "district": "Kamrup Metropolitan District",
              "programId": 27,
              "latitude": "26.1210981",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "91.8130865",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-26T11:28:11.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "1d26bd1f-53f9-4a4f-872d-75baa4d966a6",
          "data": {
            "userDetailData": {
              "userId": "1d26bd1f-53f9-4a4f-872d-75baa4d966a6",
              "emailId": "diminlp@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "No 2 Lakhi Pathar",
              "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/1d26bd1f-53f9-4a4f-872d-75baa4d966a60e57ae91-8802-470a-9241-6405bf671a37",
              "name": "Dr. Dima Rani Borgohain",
              "phoneNumber": "7002243824",
              "district": "Dhemaji District",
              "programId": 27,
              "latitude": "27.5635342274404",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "94.5704661774647",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-29T10:57:03.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "5362a9d7-d18a-4cd0-9094-b499e05d2c13",
          "data": {
            "userDetailData": {
              "userId": "5362a9d7-d18a-4cd0-9094-b499e05d2c13",
              "emailId": "",
              "country": "India",
              "state": "Assam",
              "city": "Guwahati",
              "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/5362a9d7-d18a-4cd0-9094-b499e05d2c136bc374a0-e5ad-467f-a0f9-c6956d15900c",
              "name": "Bikash Jyoti Hazarika ",
              "phoneNumber": "7002016583",
              "district": "Kamrup Metropolitan District",
              "programId": 27,
              "latitude": "26.1211468",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "91.8130867",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-29T10:57:07.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        {
          "practitionerId": "39577de1-cc39-4e94-9663-8b1479d62ee3",
          "data": {
            "userDetailData": {
              "userId": "39577de1-cc39-4e94-9663-8b1479d62ee3",
              "emailId": "arunabharaja@gmail.com",
              "country": "India",
              "state": "Assam",
              "city": "Guwahati",
              "photo": "",
              "name": "Dr. Arunabha Bhattacharjee ",
              "phoneNumber": "9435074040",
              "district": "Kamrup Metropolitan District",
              "programId": 27,
              "latitude": "26.1409977",
              "userRole": [
                "TRAINEE"
              ],
              "longitude": "91.7867623",
              "badgeDetails": [],
              "traineeData": [
                {
                  "topicName": "Execution of the JalShala",
                  "issuedDate": "2023-05-29T10:57:13.000+0000"
                }
              ],
              "trainerData": [],
              "roleTitle": null,
              "qualificationTitle": []
            }
          }
        },
        
          {
            "practitionerId": "909970da-ceda-452d-a554-3253549b7c34",
            "data": {
              "userDetailData": {
                "userId": "909970da-ceda-452d-a554-3253549b7c34",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "Trinayan Das",
                "phoneNumber": "8486016771",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1808600000001",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.7508540000001",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-29T10:57:03.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "33f2bc0a-4818-4cfd-b1a7-dc96eca03a20",
            "data": {
              "userDetailData": {
                "userId": "33f2bc0a-4818-4cfd-b1a7-dc96eca03a20",
                "emailId": "mohankshirod@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "KSHIROD MOHAN Boruah",
                "phoneNumber": "9954889806",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1210637",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.8130935",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-29T10:57:02.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "1a587d8f-ab0f-41a4-8734-4eb5299de541",
            "data": {
              "userDetailData": {
                "userId": "1a587d8f-ab0f-41a4-8734-4eb5299de541",
                "emailId": "debajit.golaghat@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "Debajit Chakrabarty",
                "phoneNumber": "7086718679",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1211996",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.8131072",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-29T11:08:08.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "008bf78d-3278-40df-ac0b-f61aaad7df00",
            "data": {
              "userDetailData": {
                "userId": "008bf78d-3278-40df-ac0b-f61aaad7df00",
                "emailId": "ranaranjan17@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "RANA RANJAN NATH ",
                "phoneNumber": "7035678575",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1472433",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.7919567",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-29T10:57:31.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "220027ad-44ca-4456-bce1-b7bc320100ca",
            "data": {
              "userDetailData": {
                "userId": "220027ad-44ca-4456-bce1-b7bc320100ca",
                "emailId": "subiradhikari72@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "Subir Adhikari",
                "phoneNumber": "9435566318",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1408585",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.786478",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-29T10:57:14.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "984a0a77-1089-4069-b1b7-31d6653cf39f",
            "data": {
              "userDetailData": {
                "userId": "984a0a77-1089-4069-b1b7-31d6653cf39f",
                "emailId": "saikatdey1177@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "Saikat dey",
                "phoneNumber": "7002196294",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1409385",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.7865843",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-29T10:57:25.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "47dfaaee-20e7-470d-a578-be5efdb6fcbc",
            "data": {
              "userDetailData": {
                "userId": "47dfaaee-20e7-470d-a578-be5efdb6fcbc",
                "emailId": "arnabsaikia420@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Biswanath Chariali",
                "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/47dfaaee-20e7-470d-a578-be5efdb6fcbccabb8665-40e9-4259-9cb6-709d76005415",
                "name": "Arnab Saikia",
                "phoneNumber": "8473018612",
                "district": "Biswanath District",
                "programId": 27,
                "latitude": "26.7354621",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "93.1393747",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-30T11:04:30.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "65b9d878-fa4a-4ffd-bb97-f830475c6b7b",
            "data": {
              "userDetailData": {
                "userId": "65b9d878-fa4a-4ffd-bb97-f830475c6b7b",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "Trisiddha Nath ",
                "phoneNumber": "6002569175",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1210849",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.81306",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-30T11:00:00.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "f33cb0b3-7f13-48d7-b67d-bf42767fcf32",
            "data": {
              "userDetailData": {
                "userId": "f33cb0b3-7f13-48d7-b67d-bf42767fcf32",
                "emailId": "NA",
                "country": "",
                "state": "",
                "city": "",
                "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/f33cb0b3-7f13-48d7-b67d-bf42767fcf320e48962c-d427-4647-af89-5c21e51f83ad",
                "name": "Arnab Jyoti Arya",
                "phoneNumber": "NA",
                "district": "",
                "programId": 27,
                "latitude": "26.0478941",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.3853652",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-30T11:02:15.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "2fd74557-b0c1-4d60-8f7d-47eabf8e6f62",
            "data": {
              "userDetailData": {
                "userId": "2fd74557-b0c1-4d60-8f7d-47eabf8e6f62",
                "emailId": "shahin.ivision@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/2fd74557-b0c1-4d60-8f7d-47eabf8e6f62cf00aeca-0488-495f-84a7-7ecf41d26d53",
                "name": "Shahinur Islam",
                "phoneNumber": "9101127455",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1210991",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.8131051",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-30T11:02:19.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "0fd2789c-d2c1-48de-8e48-0e3b9d3c234c",
            "data": {
              "userDetailData": {
                "userId": "0fd2789c-d2c1-48de-8e48-0e3b9d3c234c",
                "emailId": "shashabindudey73.sd@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Karimganj",
                "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/0fd2789c-d2c1-48de-8e48-0e3b9d3c234c623af047-d776-4ef0-be7e-815e06959563",
                "name": "Shashabindu Dey",
                "phoneNumber": "7636006459",
                "district": "Karimganj District",
                "programId": 27,
                "latitude": "24.8671631",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "92.3481947",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-30T11:02:22.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "35914b29-c7ff-41c7-9b2c-28c4128d66fa",
            "data": {
              "userDetailData": {
                "userId": "35914b29-c7ff-41c7-9b2c-28c4128d66fa",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "Kiyekashyap Pathak",
                "phoneNumber": "8486610915",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1210617",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.8130927",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-30T11:02:24.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "814ff81f-1482-41fa-b34a-97ae266fb085",
            "data": {
              "userDetailData": {
                "userId": "814ff81f-1482-41fa-b34a-97ae266fb085",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "Bhaskaryya Deka",
                "phoneNumber": "6001248216",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1210896",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.8130783",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-30T11:02:16.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "7c236bf0-be29-4c30-8651-f8e88fb6dde9",
            "data": {
              "userDetailData": {
                "userId": "7c236bf0-be29-4c30-8651-f8e88fb6dde9",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "",
                "name": "Roton Ramdey ",
                "phoneNumber": "9678462164",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1210511",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.8130785",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-30T11:02:27.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "a6a3144d-7891-4ea9-ba34-72737cbfdb8c",
            "data": {
              "userDetailData": {
                "userId": "a6a3144d-7891-4ea9-ba34-72737cbfdb8c",
                "emailId": "akhyamuchahary@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Guwahati",
                "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/a6a3144d-7891-4ea9-ba34-72737cbfdb8c81e94a4b-794a-47eb-b67f-eb4e9050b721",
                "name": "Akhya Raj Muchahary",
                "phoneNumber": "9365291558",
                "district": "Kamrup Metropolitan District",
                "programId": 27,
                "latitude": "26.1409424",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "91.7866832",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-05-31T02:16:40.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "e87a3059-a5a4-48ae-aef2-601658bd0e18",
            "data": {
              "userDetailData": {
                "userId": "e87a3059-a5a4-48ae-aef2-601658bd0e18",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Dhemaji",
                "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/e87a3059-a5a4-48ae-aef2-601658bd0e18bb13056e-efd9-418f-b29e-c95fce7cc2c8",
                "name": "Pradip kumar Gogoi",
                "phoneNumber": "7002219545",
                "district": "Dhemaji District",
                "programId": 27,
                "latitude": "27.4705089",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "94.5601795",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-06-30T07:04:24.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "91b483c7-6378-4355-be57-3a69f6e10ba5",
            "data": {
              "userDetailData": {
                "userId": "91b483c7-6378-4355-be57-3a69f6e10ba5",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Dhemaji",
                "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/91b483c7-6378-4355-be57-3a69f6e10ba5534cb606-5574-490d-8aad-59bcbb902706",
                "name": "Dulu Saikia",
                "phoneNumber": "7896203488",
                "district": "Dhemaji District",
                "programId": 27,
                "latitude": "27.470467",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "94.5604092",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-06-30T07:20:08.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "e91e616e-06fa-4748-a1ab-75a15a4cf3c2",
            "data": {
              "userDetailData": {
                "userId": "e91e616e-06fa-4748-a1ab-75a15a4cf3c2",
                "emailId": "amitky115@gmail.com",
                "country": "India",
                "state": "Assam",
                "city": "Dhemaji",
                "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/e91e616e-06fa-4748-a1ab-75a15a4cf3c282ee4f5d-1df4-49b0-8b7f-fd1f37262b7f",
                "name": "Amit Yadav",
                "phoneNumber": "8876110609",
                "district": "Dhemaji District",
                "programId": 27,
                "latitude": "27.470114",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "94.5596891",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-06-30T07:19:55.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "6bbbe571-d71f-4e95-88b7-0483cd3f0555",
            "data": {
              "userDetailData": {
                "userId": "6bbbe571-d71f-4e95-88b7-0483cd3f0555",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Dhemaji",
                "photo": "",
                "name": "Riju moni Saikia",
                "phoneNumber": "7002014605",
                "district": "Dhemaji District",
                "programId": 27,
                "latitude": "27.472631",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "94.558951",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-06-30T07:26:19.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          {
            "practitionerId": "d4afef28-d322-4097-960f-358bdc25343f",
            "data": {
              "userDetailData": {
                "userId": "d4afef28-d322-4097-960f-358bdc25343f",
                "emailId": "",
                "country": "India",
                "state": "Assam",
                "city": "Dhemaji",
                "photo": "",
                "name": "Tanu Sonowal",
                "phoneNumber": "7002409278",
                "district": "Dhemaji District",
                "programId": 27,
                "latitude": "27.4705391",
                "userRole": [
                  "TRAINEE"
                ],
                "longitude": "94.560378",
                "badgeDetails": [],
                "traineeData": [
                  {
                    "topicName": "Execution of the JalShala",
                    "issuedDate": "2023-06-30T07:21:35.000+0000"
                  }
                ],
                "trainerData": [],
                "roleTitle": null,
                "qualificationTitle": []
              }
            }
          },
          
            {
              "practitionerId": "025224e5-00f8-4dbf-adb9-d2a96416a391",
              "data": {
                "userDetailData": {
                  "userId": "025224e5-00f8-4dbf-adb9-d2a96416a391",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Kula Pathar",
                  "photo": "",
                  "name": "Mainu Handique",
                  "phoneNumber": "7002623842",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.4789395321176",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.5337348953838",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:27:09.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "8a45579a-7412-48d0-a7fa-41d9cd88398b",
              "data": {
                "userDetailData": {
                  "userId": "8a45579a-7412-48d0-a7fa-41d9cd88398b",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/8a45579a-7412-48d0-a7fa-41d9cd88398bbc87db43-3da6-4b2c-9840-8c0ab73f2a04",
                  "name": "Nabin chandra Boruah",
                  "phoneNumber": "9954955511",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.4708183",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.5602717",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:19:03.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "1ce1078b-d061-4943-a69d-0179aca0cb67",
              "data": {
                "userDetailData": {
                  "userId": "1ce1078b-d061-4943-a69d-0179aca0cb67",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/1ce1078b-d061-4943-a69d-0179aca0cb67def87bc3-fb28-42be-a7bc-93cc77a76ece",
                  "name": "Dipen Gogoi",
                  "phoneNumber": "9957738304",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.4704285",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.5598973",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:19:15.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "0631b942-dc35-4461-bb0e-6970e84682cd",
              "data": {
                "userDetailData": {
                  "userId": "0631b942-dc35-4461-bb0e-6970e84682cd",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Granta Satra",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/0631b942-dc35-4461-bb0e-6970e84682cd2bd1ad4a-b76b-4ddd-9d57-d91b7f8a4462",
                  "name": "Monunyoti Regon",
                  "phoneNumber": "9101132675",
                  "district": "Majuli District",
                  "programId": 27,
                  "latitude": "26.9535535000001",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.1718355",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-05-29T10:57:10.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "6e6aea1b-4ebc-4ca7-b684-bd44941bf28f",
              "data": {
                "userDetailData": {
                  "userId": "6e6aea1b-4ebc-4ca7-b684-bd44941bf28f",
                  "emailId": "dulalchbhuyan@gmail.com",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/6e6aea1b-4ebc-4ca7-b684-bd44941bf28f19a91b34-79db-4b91-91b0-67e826a71f02",
                  "name": "Dulal Chandra Bhuyan ",
                  "phoneNumber": "7002477054",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.472631",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.558951",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:23:39.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "b2d4299c-604e-4dba-943c-737573b163d2",
              "data": {
                "userDetailData": {
                  "userId": "b2d4299c-604e-4dba-943c-737573b163d2",
                  "emailId": "NA",
                  "country": "India",
                  "state": "Assam",
                  "city": "Nawparia Gohain",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/b2d4299c-604e-4dba-943c-737573b163d2d6903fc6-4829-4607-b144-3c41fd2c2bd6",
                  "name": "Monuj kr Deori",
                  "phoneNumber": "NA",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.6194204135006",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.6449965633409",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:08:38.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "f6165fcf-2289-4399-841d-bc30a889e7f7",
              "data": {
                "userDetailData": {
                  "userId": "f6165fcf-2289-4399-841d-bc30a889e7f7",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/f6165fcf-2289-4399-841d-bc30a889e7f7937286a6-7e16-4053-92ad-cff239e64dfa",
                  "name": "UPEN PEGU",
                  "phoneNumber": "9954738831",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.4705559",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.5603575",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:03:57.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "29787f29-d741-464e-a655-5cbce52de684",
              "data": {
                "userDetailData": {
                  "userId": "29787f29-d741-464e-a655-5cbce52de684",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/29787f29-d741-464e-a655-5cbce52de6842b3b8e60-4c08-4d0d-9e86-22b9a430bb05",
                  "name": "Manuj Mipun",
                  "phoneNumber": "8473058971",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.472631",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.558951",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:33:15.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "72717c70-603e-49a7-97bb-97365ea9be52",
              "data": {
                "userDetailData": {
                  "userId": "72717c70-603e-49a7-97bb-97365ea9be52",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/72717c70-603e-49a7-97bb-97365ea9be527efd629f-14f3-4d8d-8995-4b521979fed4",
                  "name": "Raju Deori",
                  "phoneNumber": "6003198145",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.472631",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.558951",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:09:11.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "4e8f38a1-5e19-4f08-957a-d40414a0d205",
              "data": {
                "userDetailData": {
                  "userId": "4e8f38a1-5e19-4f08-957a-d40414a0d205",
                  "emailId": "joy.deori@gmail.com",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "",
                  "name": "Joyprakash Deori",
                  "phoneNumber": "9864898772",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.472631",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.558951",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:07:33.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "86e7cb28-edb6-4c4f-85d2-97e0f46f1446",
              "data": {
                "userDetailData": {
                  "userId": "86e7cb28-edb6-4c4f-85d2-97e0f46f1446",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Silikhaguri No 2",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/86e7cb28-edb6-4c4f-85d2-97e0f46f14469abb3984-22b2-4141-aaf5-897b60fd1949",
                  "name": "Kuheswar Deori",
                  "phoneNumber": "7002246993",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.5670464247968",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.6411340679944",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:25:46.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "cf3b2a06-a181-4a5d-a11d-fe78e5b66d3c",
              "data": {
                "userDetailData": {
                  "userId": "cf3b2a06-a181-4a5d-a11d-fe78e5b66d3c",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Geruamukh",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/cf3b2a06-a181-4a5d-a11d-fe78e5b66d3cd8f03a49-94d1-4966-b4af-74b0ff000c20",
                  "name": "Suman Das",
                  "phoneNumber": "9101481639",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.5322175484512",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.2673521359771",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:32:15.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "ad866673-aec4-46ff-bc8c-33b59e8d4b80",
              "data": {
                "userDetailData": {
                  "userId": "ad866673-aec4-46ff-bc8c-33b59e8d4b80",
                  "emailId": "labanyalahan69@gmail.com",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/ad866673-aec4-46ff-bc8c-33b59e8d4b80003c804f-2c17-450a-b189-2685d130a511",
                  "name": "Labanya Lahan",
                  "phoneNumber": "9864192517",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.477902",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.564131",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:26:24.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "57864e70-faed-419b-bda1-14b54d0fd4f1",
              "data": {
                "userDetailData": {
                  "userId": "57864e70-faed-419b-bda1-14b54d0fd4f1",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "",
                  "name": "Tapti gogoi mahanta",
                  "phoneNumber": "7002482090",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.485783",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.570094",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:26:16.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "78ffaf20-36b3-4dbe-954e-8bc62734f726",
              "data": {
                "userDetailData": {
                  "userId": "78ffaf20-36b3-4dbe-954e-8bc62734f726",
                  "emailId": "",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/78ffaf20-36b3-4dbe-954e-8bc62734f726f35b70b3-aff3-4caa-a17b-fdad407c989a",
                  "name": "Pinku Pegu",
                  "phoneNumber": "8011970065",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.4704651",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.5604113",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:03:45.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "2c0b2117-d29a-4466-bbce-8f9f09c77486",
              "data": {
                "userDetailData": {
                  "userId": "2c0b2117-d29a-4466-bbce-8f9f09c77486",
                  "emailId": "tullyagogoi99544t@gmail.com",
                  "country": "India",
                  "state": "Assam",
                  "city": "Dhemaji",
                  "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/2c0b2117-d29a-4466-bbce-8f9f09c774864930bad2-aba3-417d-bfbd-16d0248382a5",
                  "name": "Tullya Gogoi",
                  "phoneNumber": "9954489564",
                  "district": "Dhemaji District",
                  "programId": 27,
                  "latitude": "27.4708944",
                  "userRole": [
                    "TRAINEE"
                  ],
                  "longitude": "94.5604313",
                  "badgeDetails": [],
                  "traineeData": [
                    {
                      "topicName": "Execution of the JalShala",
                      "issuedDate": "2023-06-30T07:21:09.000+0000"
                    }
                  ],
                  "trainerData": [],
                  "roleTitle": null,
                  "qualificationTitle": []
                }
              }
            },
            {
              "practitionerId": "5a4310d0-06b8-4b03-aefd-a36d29cc94b4",
              "data": {
                "userDetailData": {
                  "userId": "5a4310d0-06b8-4b03-aefd-a36d29cc94b4",
                  "emailId": "",
                  "country": "India",
                  "state": "Chandigarh",
                  "city": "Chandigarh",
                  "photo": "",
                  "name": "Satyam Parida",
                  "phoneNumber": "9938072620",
                  "district": "Chandigarh District",
                  "programId": 27,
                  "latitude": "30.7014669",
                  "userRole": [
                    "TRAINER",
                    "TRAINEE"
                  ],
                  "longitude": "76.780248",
                  "badgeDetails": [
                    {
                      "badgeId": 66,
                      "badgeName": "VCF - Social & Knowledge Management"
                    },
                    {
                      "badgeId": 64,
                      "badgeName": "VCF (Environment and GIS)"
                    },
                    {
                      "badgeId": 65,
                      "badgeName": "East Khasi Hills District"
                    },
                    {
                      "badgeId": 49,
                      "badgeName": "Master Trainer"
                    }
                  ],
                  "traineeData": [
                    {
                      "topicName": "Test run for Assisted Scanning",
                      "issuedDate": "2020-08-05T13:16:58.000+0000"
                    }
                  ],
                  "trainerData": [
                    {
                      "topicName": "Test run for Assisted Scanning",
                      "issuedDate": "2020-08-06T10:55:00.000+0000"
                    }
                  ],
                  "roleTitle": [],
                  "qualificationTitle": [
                    "B.Tech Electrical Engineering"
                  ]
                }
              }
            },
            
              {
                "practitionerId": "867b20fb-b6bd-418d-91c6-aa5ef686e629",
                "data": {
                  "userDetailData": {
                    "userId": "867b20fb-b6bd-418d-91c6-aa5ef686e629",
                    "emailId": "",
                    "country": "India",
                    "state": "Karnataka",
                    "city": "Bengaluru",
                    "photo": "https://socion-attestation-app.s3.amazonaws.com/profile-picture%2F867b20fb-b6bd-418d-91c6-aa5ef686e629.jpg",
                    "name": "Sreechand Tavva",
                    "phoneNumber": "8884714641",
                    "district": "Bengaluru Urban District",
                    "programId": 27,
                    "latitude": "12.9663405",
                    "userRole": [
                      "TRAINER",
                      "TRAINEE"
                    ],
                    "longitude": "77.6484567",
                    "badgeDetails": [
                      {
                        "badgeId": 16,
                        "badgeName": "PDA Champion"
                      },
                      {
                        "badgeId": 19,
                        "badgeName": "Support Solution Team"
                      },
                      {
                        "badgeId": 20,
                        "badgeName": "Content Administrator"
                      },
                      {
                        "badgeId": 189,
                        "badgeName": "Jal Mirta"
                      },
                      {
                        "badgeId": 17,
                        "badgeName": "Arghyam employees"
                      },
                      {
                        "badgeId": 17,
                        "badgeName": "Arghyam employees"
                      },
                      {
                        "badgeId": 1671,
                        "badgeName": "Assam Trainer"
                      }
                    ],
                    "traineeData": [
                      {
                        "topicName": "PDA structure Demo",
                        "issuedDate": "2022-03-03T12:31:26.000+0000"
                      },
                      {
                        "topicName": "CBP Orientation",
                        "issuedDate": "2020-08-28T10:19:52.000+0000"
                      },
                      {
                        "topicName": "PDA Demo",
                        "issuedDate": "2021-06-15T05:24:01.000+0000"
                      },
                      {
                        "topicName": "PDA - Program App",
                        "issuedDate": "2019-12-05T07:00:11+0000"
                      },
                      {
                        "topicName": "PDA - Trainer App",
                        "issuedDate": "2019-12-05T07:26:08+0000"
                      },
                      {
                        "topicName": "PDA - Participant App",
                        "issuedDate": "2019-12-26T11:14:27+0000"
                      },
                      {
                        "topicName": "PDA DEMO english",
                        "issuedDate": "2021-04-29T11:54:17.000+0000"
                      },
                      {
                        "topicName": "PDA Demo Hindi",
                        "issuedDate": "2021-04-29T11:54:42.000+0000"
                      },
                      {
                        "topicName": "PDA Dashboard english",
                        "issuedDate": "2021-04-29T12:01:56.000+0000"
                      },
                      {
                        "topicName": "PDA - Program App",
                        "issuedDate": "2021-04-05T10:41:00.000+0000"
                      },
                      {
                        "topicName": "Testing Again",
                        "issuedDate": "2019-12-13T13:25:00+0000"
                      },
                      {
                        "topicName": "Compressed Testing Again",
                        "issuedDate": "2019-12-13T16:13:15+0000"
                      },
                      {
                        "topicName": "PDA Improved Hands on",
                        "issuedDate": "2021-04-12T09:54:26.000+0000"
                      },
                      {
                        "topicName": "PDA Improved Hands on",
                        "issuedDate": "2021-04-12T10:02:33.000+0000"
                      },
                      {
                        "topicName": "PDA - Trainer App",
                        "issuedDate": "2020-01-08T08:54:02+0000"
                      },
                      {
                        "topicName": "PDA - Participant App",
                        "issuedDate": "2020-01-14T09:51:35+0000"
                      },
                      {
                        "topicName": "Thermodynamic treatment of Hard Water",
                        "issuedDate": "2020-02-20T11:44:42+0000"
                      },
                      {
                        "topicName": "11",
                        "issuedDate": "2020-01-22T09:02:56+0000"
                      },
                      {
                        "topicName": "CBP Orientation",
                        "issuedDate": "2020-08-25T06:11:47.000+0000"
                      },
                      {
                        "topicName": "Water management",
                        "issuedDate": "2020-09-02T10:19:12.000+0000"
                      },
                      {
                        "topicName": "Virtual Training Format",
                        "issuedDate": "2020-10-14T06:57:00.000+0000"
                      },
                      {
                        "topicName": "PDA Updates",
                        "issuedDate": "2020-10-06T06:26:44.000+0000"
                      },
                      {
                        "topicName": "PDA for Trainers",
                        "issuedDate": "2020-11-23T06:06:32.000+0000"
                      },
                      {
                        "topicName": "PDA for Trainers",
                        "issuedDate": "2020-11-24T11:32:01.000+0000"
                      },
                      {
                        "topicName": "Panchayat and Governance of Commons",
                        "issuedDate": "2020-05-11T06:05:25.000+0000"
                      },
                      {
                        "topicName": "Panchayat and Governance of Commons",
                        "issuedDate": "2020-07-27T06:12:34.000+0000"
                      },
                      {
                        "topicName": "Virtual Interactions - Needs and Benefits",
                        "issuedDate": "2020-11-19T10:14:19.000+0000"
                      },
                      {
                        "topicName": "Virtual Interactions - Needs and Benefits",
                        "issuedDate": "2020-10-14T05:53:51.000+0000"
                      },
                      {
                        "topicName": "Guided Mentoring Format",
                        "issuedDate": "2020-10-14T06:50:04.000+0000"
                      },
                      {
                        "topicName": "3 Fold Program",
                        "issuedDate": "2020-09-30T14:53:37.000+0000"
                      },
                      {
                        "topicName": "PDA Updates",
                        "issuedDate": "2020-10-05T06:46:36.000+0000"
                      },
                      {
                        "topicName": "PDA Additional features",
                        "issuedDate": "2021-01-19T05:08:59.000+0000"
                      },
                      {
                        "topicName": "PDA  Hands on Hindi",
                        "issuedDate": "2021-04-29T11:56:17.000+0000"
                      },
                      {
                        "topicName": "भूजलाची ओळख व भूजल व्यवस्थापनाची गरज | Introduction to groundwater -Need for GW management",
                        "issuedDate": "2021-09-12T10:39:59.000+0000"
                      },
                      {
                        "topicName": "Well Monitoring - Example",
                        "issuedDate": "2022-06-16T09:26:12.000+0000"
                      }
                    ],
                    "trainerData": [
                      {
                        "topicName": "CBP Orientation",
                        "issuedDate": "2020-08-28T11:00:00.000+0000"
                      },
                      {
                        "topicName": "PDA Hands-on",
                        "issuedDate": "2021-03-16T04:45:00.000+0000"
                      },
                      {
                        "topicName": "PDA Demo",
                        "issuedDate": "2021-06-15T05:15:00.000+0000"
                      },
                      {
                        "topicName": "Testing new topic for a training mock run.",
                        "issuedDate": "2019-12-12T10:34:00.000+0000"
                      },
                      {
                        "topicName": "Testing Again",
                        "issuedDate": "2019-12-13T13:23:00.000+0000"
                      },
                      {
                        "topicName": "Compressed Testing Again",
                        "issuedDate": "2019-12-13T16:09:00.000+0000"
                      },
                      {
                        "topicName": "PDA Demo and Deployment Training | DSC, Samerth, Yuva Mitra.",
                        "issuedDate": "2021-06-02T10:15:00.000+0000"
                      },
                      {
                        "topicName": "PDA - Trainer App",
                        "issuedDate": "2019-12-13T11:00:00.000+0000"
                      },
                      {
                        "topicName": "PDA - Participant App",
                        "issuedDate": "2020-01-14T10:32:00.000+0000"
                      },
                      {
                        "topicName": "Something went wrong",
                        "issuedDate": "2020-02-17T13:22:00.000+0000"
                      },
                      {
                        "topicName": "PDA - Participant App",
                        "issuedDate": "2020-04-07T10:00:00.000+0000"
                      },
                      {
                        "topicName": "PDA - Trainer App",
                        "issuedDate": "2020-04-07T10:00:00.000+0000"
                      },
                      {
                        "topicName": "PDA - Program App",
                        "issuedDate": "2020-04-07T10:00:00.000+0000"
                      },
                      {
                        "topicName": "Test run for Assisted Scanning",
                        "issuedDate": "2020-08-07T07:24:00.000+0000"
                      },
                      {
                        "topicName": "Water management",
                        "issuedDate": "2020-09-02T10:19:00.000+0000"
                      },
                      {
                        "topicName": "PDA Demo and Deployment Training for Trainers | ACT",
                        "issuedDate": "2020-12-11T13:00:00.000+0000"
                      },
                      {
                        "topicName": "Getting Started with PDA",
                        "issuedDate": "2020-11-26T06:48:00.000+0000"
                      },
                      {
                        "topicName": "PDA Demo",
                        "issuedDate": "2021-08-19T07:30:00.000+0000"
                      },
                      {
                        "topicName": "PDA for Trainers",
                        "issuedDate": "2020-11-23T06:30:00.000+0000"
                      },
                      {
                        "topicName": "PDA - Participant App",
                        "issuedDate": "2020-08-19T11:30:00.000+0000"
                      },
                      {
                        "topicName": "PDA Video Session",
                        "issuedDate": "2020-10-07T06:52:00.000+0000"
                      },
                      {
                        "topicName": "PDA Demo for  Prasari and BRLF",
                        "issuedDate": "2020-10-01T07:00:00.000+0000"
                      },
                      {
                        "topicName": "Virtual Interactions - Best Practices - Smitha Murthy",
                        "issuedDate": "2020-10-21T12:30:00.000+0000"
                      },
                      {
                        "topicName": "PDA Demo and Deployment Training | ACT",
                        "issuedDate": "2020-12-11T13:00:00.000+0000"
                      },
                      {
                        "topicName": "PDA Trial",
                        "issuedDate": "2020-11-07T09:31:00.000+0000"
                      },
                      {
                        "topicName": "Codes instead of Scans",
                        "issuedDate": "2021-02-04T06:30:00.000+0000"
                      },
                      {
                        "topicName": "PDA Demo",
                        "issuedDate": "2021-08-27T07:00:00.000+0000"
                      },
                      {
                        "topicName": "PDA Demo",
                        "issuedDate": "2021-07-13T06:00:00.000+0000"
                      },
                      {
                        "topicName": "PDA Hand-on",
                        "issuedDate": "2021-08-30T11:00:00.000+0000"
                      },
                      {
                        "topicName": "Getting Started with PDA",
                        "issuedDate": "2020-11-25T09:45:00.000+0000"
                      },
                      {
                        "topicName": "PDA Refresher Training for ACT and DSC",
                        "issuedDate": "2021-10-07T11:30:00.000+0000"
                      },
                      {
                        "topicName": "Well Monitoring - Example",
                        "issuedDate": "2022-06-16T11:30:00.000+0000"
                      },
                      {
                        "topicName": "Understanding the Functional Grid",
                        "issuedDate": "2022-09-15T05:31:00.000+0000"
                      },
                      {
                        "topicName": "Well Monitoring - Example",
                        "issuedDate": "2022-09-15T05:50:00.000+0000"
                      },
                      {
                        "topicName": "PDA Hand-on",
                        "issuedDate": "2023-08-17T12:30:00.000+0000"
                      },
                      {
                        "topicName": "ಜನರ ಸಹಭಾಗಿತ್ವದಲ್ಲಿ ಗ್ರಾಮೀಣ ವಿಶ್ಲೇಷಣೆಯಡಿ ಕಾಲ್ನಡಿಗೆ  ಕುರಿತು _ GKM_BFT / Transect walk_ GKM_BFT",
                        "issuedDate": "2023-08-25T07:30:00.000+0000"
                      }
                    ],
                    "roleTitle": [
                      "Technology Trainer"
                    ],
                    "qualificationTitle": []
                  }
                }
              },
              {
                "practitionerId": "02281e30-0e4c-458d-babd-715fc3c4e388",
                "data": {
                  "userDetailData": {
                    "userId": "02281e30-0e4c-458d-babd-715fc3c4e388",
                    "emailId": "saddam.sarif786@gmail.com",
                    "country": "India",
                    "state": "Assam",
                    "city": "Silchar",
                    "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/02281e30-0e4c-458d-babd-715fc3c4e3881a35a1a3-7f81-4b93-b98c-8b5afcc1a407",
                    "name": "Ahmad Sarif Laskar",
                    "phoneNumber": "7002795427",
                    "district": "Cachar District",
                    "programId": 27,
                    "latitude": "24.777008056640625",
                    "userRole": [
                      "TRAINEE"
                    ],
                    "longitude": "92.7929290643077",
                    "badgeDetails": [
                      {
                        "badgeId": 1674,
                        "badgeName": "Block Trainer SSA"
                      },
                      {
                        "badgeId": 1706,
                        "badgeName": "Man"
                      },
                      {
                        "badgeId": 1672,
                        "badgeName": "aztec1"
                      },
                      {
                        "badgeId": 1671,
                        "badgeName": "Assam Trainer"
                      },
                      {
                        "badgeId": 1673,
                        "badgeName": "aztec2"
                      },
                      {
                        "badgeId": 1672,
                        "badgeName": "aztec1"
                      },
                      {
                        "badgeId": 1673,
                        "badgeName": "aztec2"
                      }
                    ],
                    "traineeData": [
                      {
                        "topicName": "Execution of the JalShala",
                        "issuedDate": "2023-06-08T09:21:56.000+0000"
                      }
                    ],
                    "trainerData": [],
                    "roleTitle": null,
                    "qualificationTitle": []
                  }
                }
              },
              {
                "practitionerId": "2fac0fb6-ae1c-4ac0-a53d-df0a9d98d176",
                "data": {
                  "userDetailData": {
                    "userId": "2fac0fb6-ae1c-4ac0-a53d-df0a9d98d176",
                    "emailId": "",
                    "country": "India",
                    "state": "Karnataka",
                    "city": "Bengaluru",
                    "photo": "",
                    "name": "Aditya Maruvada",
                    "phoneNumber": "8095801505",
                    "district": "Bengaluru Urban District",
                    "programId": 27,
                    "latitude": "12.94336",
                    "userRole": [
                      null,
                      "TRAINEE"
                    ],
                    "longitude": "77.627392",
                    "badgeDetails": [
                      {
                        "badgeId": 1674,
                        "badgeName": "Block Trainer SSA"
                      },
                      {
                        "badgeId": 1672,
                        "badgeName": "aztec1"
                      },
                      {
                        "badgeId": 1673,
                        "badgeName": "aztec2"
                      },
                      {
                        "badgeId": 1671,
                        "badgeName": "Assam Trainer"
                      },
                      {
                        "badgeId": 211,
                        "badgeName": "Expert Hindi Trainers"
                      },
                      {
                        "badgeId": 1706,
                        "badgeName": "Man"
                      }
                    ],
                    "traineeData": [
                      {
                        "topicName": "PDA Hand-on",
                        "issuedDate": "2023-08-17T11:11:22.000+0000"
                      }
                    ],
                    "trainerData": [],
                    "roleTitle": null,
                    "qualificationTitle": []
                  }
                }
              },
              {
                "practitionerId": "4f8fe22c-8dae-468f-b003-f41e1abdf485",
                "data": {
                  "userDetailData": {
                    "userId": "4f8fe22c-8dae-468f-b003-f41e1abdf485",
                    "emailId": "",
                    "country": "India",
                    "state": "Assam",
                    "city": "Karimganj",
                    "photo": "",
                    "name": "APU SUKLA",
                    "phoneNumber": "9859479423",
                    "district": "Karimganj District",
                    "programId": 27,
                    "latitude": "24.8671652",
                    "userRole": [
                      "TRAINEE"
                    ],
                    "longitude": "92.3481865",
                    "badgeDetails": [
                      {
                        "badgeId": 1674,
                        "badgeName": "Block Trainer SSA"
                      },
                      {
                        "badgeId": 1671,
                        "badgeName": "Assam Trainer"
                      },
                      {
                        "badgeId": 1706,
                        "badgeName": "Man"
                      },
                      {
                        "badgeId": 1672,
                        "badgeName": "aztec1"
                      }
                    ],
                    "traineeData": [
                      {
                        "topicName": "Execution of the JalShala",
                        "issuedDate": "2023-05-29T10:57:02.000+0000"
                      }
                    ],
                    "trainerData": [],
                    "roleTitle": null,
                    "qualificationTitle": []
                  }
                }
              },
              {
                "practitionerId": "e348c20d-c7e1-460f-9ec7-15f268dc9d11",
                "data": {
                  "userDetailData": {
                    "userId": "e348c20d-c7e1-460f-9ec7-15f268dc9d11",
                    "emailId": "NA",
                    "country": "India",
                    "state": "Assam",
                    "city": "Binnakandi Part 2",
                    "photo": "",
                    "name": "Sayan Das",
                    "phoneNumber": "NA",
                    "district": "Cachar District",
                    "programId": 27,
                    "latitude": "24.7872127870802",
                    "userRole": [
                      "TRAINEE"
                    ],
                    "longitude": "92.9872071548686",
                    "badgeDetails": [],
                    "traineeData": [
                      {
                        "topicName": "Execution of the JalShala",
                        "issuedDate": "2023-06-08T09:22:57.000+0000"
                      }
                    ],
                    "trainerData": [],
                    "roleTitle": null,
                    "qualificationTitle": []
                  }
                }
              },
              {
                "practitionerId": "7e5a3a66-53b3-4a9e-a4af-da77b6e53614",
                "data": {
                  "userDetailData": {
                    "userId": "7e5a3a66-53b3-4a9e-a4af-da77b6e53614",
                    "emailId": "",
                    "country": "India",
                    "state": "Maharashtra",
                    "city": "Pune",
                    "photo": "",
                    "name": "Sachin",
                    "phoneNumber": "8097098187",
                    "district": "Pune District",
                    "programId": 27,
                    "latitude": "18.5110038",
                    "userRole": [
                      null,
                      "TRAINEE"
                    ],
                    "longitude": "73.7903484",
                    "badgeDetails": [
                      {
                        "badgeId": 407,
                        "badgeName": "BFT"
                      }
                    ],
                    "traineeData": [
                      {
                        "topicName": "Well Monitoring - Example",
                        "issuedDate": "2022-11-29T10:08:33.000+0000"
                      }
                    ],
                    "trainerData": [],
                    "roleTitle": null,
                    "qualificationTitle": []
                  }
                }
              },
              {
                "practitionerId": "fa1e92ec-093e-4b52-b2df-8f16d10203e2",
                "data": {
                  "userDetailData": {
                    "userId": "fa1e92ec-093e-4b52-b2df-8f16d10203e2",
                    "emailId": "jampanitulasidevi@gmail.com",
                    "country": "India",
                    "state": "Karnataka",
                    "city": "Chintamani",
                    "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/WhatsApp Image 2022-06-09 at 11.33.32 AM.jpeg",
                    "name": "J Tulasi Devi",
                    "phoneNumber": "9441941921",
                    "district": "Chikkaballapur District",
                    "programId": 27,
                    "latitude": "13.400853",
                    "userRole": [
                      "TRAINER",
                      "TRAINEE"
                    ],
                    "longitude": "78.053478",
                    "badgeDetails": [],
                    "traineeData": [
                      {
                        "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for         r different groups_BFTs_GKMs",
                        "issuedDate": "2022-12-21T10:00:31.000+0000"
                      }
                    ],
                    "trainerData": [
                      {
                        "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ   ಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
                        "issuedDate": "2022-12-21T12:40:00.000+0000"
                      }
                    ],
                    "roleTitle": null,
                    "qualificationTitle": []
                  }
                }
              },
              {
                "practitionerId": "3ca8d21c-b8f7-412b-85d0-77aa2d49a81a",
                "data": {
                  "userDetailData": {
                    "userId": "3ca8d21c-b8f7-412b-85d0-77aa2d49a81a",
                    "emailId": "prakashlambi24@gmail.com",
                    "country": "India",
                    "state": "Karnataka",
                    "city": "Davanagere",
                    "photo": "",
                    "name": "Prakash Lambi",
                    "phoneNumber": "9620658996",
                    "district": "Davanagere District",
                    "programId": 27,
                    "latitude": "14.4369283",
                    "userRole": [
                      "TRAINEE"
                    ],
                    "longitude": "75.922325",
                    "badgeDetails": [],
                    "traineeData": [
                      {
                        "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for d         different groups_BFTs_GKMs",
                        "issuedDate": "2022-12-21T10:00:38.000+0000"
                      }
                    ],
                    "trainerData": [],
                    "roleTitle": null,
                    "qualificationTitle": []
                  }
                }
              },
              
                {
                  "practitionerId": "2cbf38a3-d533-4451-9b58-f523baba5fe5",
                  "data": {
                    "userDetailData": {
                      "userId": "2cbf38a3-d533-4451-9b58-f523baba5fe5",
                      "emailId": "",
                      "country": "India",
                      "state": "Karnataka",
                      "city": "Bengaluru",
                      "photo": "",
                      "name": "Abhi",
                      "phoneNumber": "9448176887",
                      "district": "Bengaluru Urban District",
                      "programId": 27,
                      "latitude": "13.1044196",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "77.568333",
                      "badgeDetails": [
                        {
                          "badgeId": 46,
                          "badgeName": "Npali Trns"
                        },
                        {
                          "badgeId": 53,
                          "badgeName": "Nepali Master Trainers"
                        },
                        {
                          "badgeId": 154,
                          "badgeName": "Water Expert"
                        },
                        {
                          "badgeId": 19,
                          "badgeName": "Support Solution Team"
                        },
                        {
                          "badgeId": 34,
                          "badgeName": "Nepali Trainers"
                        },
                        {
                          "badgeId": 189,
                          "badgeName": "Jal Mirta"
                        }
                      ],
                      "traineeData": [
                        {
                          "topicName": "Scripts Review | T1.02 | DG",
                          "issuedDate": "2020-12-02T11:40:31.000+0000"
                        },
                        {
                          "topicName": "Crop Water Budgeting",
                          "issuedDate": "2020-12-15T10:36:02.000+0000"
                        },
                        {
                          "topicName": "PDA Dash Board checks",
                          "issuedDate": "2020-12-02T08:57:08.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": [],
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "c0072a31-91df-48a0-b79e-5d19c7d29d27",
                  "data": {
                    "userDetailData": {
                      "userId": "c0072a31-91df-48a0-b79e-5d19c7d29d27",
                      "emailId": "soumya@socion.io",
                      "country": "India",
                      "state": "Chhattisgarh",
                      "city": "Raipur",
                      "photo": "",
                      "name": "Soumya Ranjan",
                      "phoneNumber": "8249993233",
                      "district": "Raipur District",
                      "programId": 27,
                      "latitude": "21.256567",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "81.636232",
                      "badgeDetails": [],
                      "traineeData": [
                        {
                          "topicName": "Virtual Interactions - Best Practices - Smitha Murthy",
                          "issuedDate": "2020-10-14T06:59:28.000+0000"
                        },
                        {
                          "topicName": "Virtual Training Format",
                          "issuedDate": "2020-10-14T06:56:55.000+0000"
                        },
                        {
                          "topicName": "Virtual Interactions - Needs and Benefits",
                          "issuedDate": "2020-10-14T05:52:46.000+0000"
                        },
                        {
                          "topicName": "Guided Mentoring Format",
                          "issuedDate": "2020-10-14T06:49:28.000+0000"
                        },
                        {
                          "topicName": "Virtual Interactions - Needs and Benefits",
                          "issuedDate": "2020-11-04T10:07:06.000+0000"
                        },
                        {
                          "topicName": "Guided Mentoring Format",
                          "issuedDate": "2020-11-04T10:39:04.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": [],
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "0a59ae48-1c58-4c3f-9d94-5339ab7c0944",
                  "data": {
                    "userDetailData": {
                      "userId": "0a59ae48-1c58-4c3f-9d94-5339ab7c0944",
                      "emailId": "",
                      "country": "India",
                      "state": "Jharkhand",
                      "city": "Khuntitola",
                      "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F0a59ae48-1c58-4c3f-9d94-5339ab7c0944.jpg",
                      "name": "Rakesh kumar",
                      "phoneNumber": "9504918417",
                      "district": "Khunti District",
                      "programId": 27,
                      "latitude": "23.0600406",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "85.2707726",
                      "badgeDetails": [],
                      "traineeData": [
                        {
                          "topicName": "PDA Hands-on",
                          "issuedDate": "2021-03-16T08:21:47.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": [],
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "e3867d63-96d5-4a20-9b9a-844063372dd5",
                  "data": {
                    "userDetailData": {
                      "userId": "e3867d63-96d5-4a20-9b9a-844063372dd5",
                      "emailId": "khadar.1686@gmail.com",
                      "country": "India",
                      "state": "Karnataka",
                      "city": "Raichur",
                      "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/e3867d63-96d5-4a20-9b9a-844063372dd55496f1d2-c38f-40ed-a689-7b3236d4c714",
                      "name": "Khadar Basha",
                      "phoneNumber": "8088176313",
                      "district": "Raichur District",
                      "programId": 27,
                      "latitude": "16.2047372",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "77.3412965",
                      "badgeDetails": [
                        {
                          "badgeId": 1674,
                          "badgeName": "Block Trainer SSA"
                        },
                        {
                          "badgeId": 1706,
                          "badgeName": "Man"
                        },
                        {
                          "badgeId": 1671,
                          "badgeName": "Assam Trainer"
                        },
                        {
                          "badgeId": 1672,
                          "badgeName": "aztec1"
                        },
                        {
                          "badgeId": 1673,
                          "badgeName": "aztec2"
                        }
                      ],
                      "traineeData": [
                        {
                          "topicName": "ವಿವಿಧ ಗುಂಪ ಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
                          "issuedDate": "2022-12-21T10:02:57.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": null,
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "0b7a3d60-9092-40ff-9c4b-73daede8e2b9",
                  "data": {
                    "userDetailData": {
                      "userId": "0b7a3d60-9092-40ff-9c4b-73daede8e2b9",
                      "emailId": "nidhi@socion.io",
                      "country": "India",
                      "state": "Telangana",
                      "city": "Hyderabad",
                      "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/0b7a3d60-9092-40ff-9c4b-73daede8e2b98b908d46-8963-4745-b248-a247f9e3b1c8",
                      "name": "Nidhi Niharika Socion",
                      "phoneNumber": "8080653700",
                      "district": "Hyderabad District",
                      "programId": 27,
                      "latitude": "17.4128880000001",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "78.4778680000001",
                      "badgeDetails": [],
                      "traineeData": [
                        {
                          "topicName": "PDA - Program App",
                          "issuedDate": "2021-04-05T10:40:59.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": [],
                      "qualificationTitle": [
                        "Qual 5",
                        "Qual8"
                      ]
                    }
                  }
                },
                {
                  "practitionerId": "bc2d9734-3b59-4c4d-a8c2-9e543180c372",
                  "data": {
                    "userDetailData": {
                      "userId": "bc2d9734-3b59-4c4d-a8c2-9e543180c372",
                      "emailId": "pavan@yopmail.com",
                      "country": "India",
                      "state": "Karnataka",
                      "city": "Bengaluru",
                      "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/bc2d9734-3b59-4c4d-a8c2-9e543180c372e8b4d2d4-9c91-4a09-b029-3b1a537ab6a3",
                      "name": "Pavan The",
                      "phoneNumber": "9731115666",
                      "district": "Bengaluru Urban District",
                      "programId": 27,
                      "latitude": "12.8822382",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "77.5799022",
                      "badgeDetails": [],
                      "traineeData": [
                        {
                          "topicName": "PDA - Participant App",
                          "issuedDate": "2019-12-26T11:27:23+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": [],
                      "qualificationTitle": [
                        "Btech"
                      ]
                    }
                  }
                },
                {
                  "practitionerId": "8610e640-1fe5-43f1-8552-f0964cc48dc4",
                  "data": {
                    "userDetailData": {
                      "userId": "8610e640-1fe5-43f1-8552-f0964cc48dc4",
                      "emailId": "rk_kalluri@yahoo.com",
                      "country": "India",
                      "state": "Karnataka",
                      "city": "Bengaluru",
                      "photo": "https://socion-prod.s3.amazonaws.com/profile-picture/8610e640-1fe5-43f1-8552-f0964cc48dc4.JPG",
                      "name": "Ramakrishna Kalluri",
                      "phoneNumber": "9900221154",
                      "district": "Bengaluru Urban District",
                      "programId": 27,
                      "latitude": "12.874412536621094",
                      "userRole": [
                        "TRAINER",
                        "TRAINEE"
                      ],
                      "longitude": "77.59061321365782",
                      "badgeDetails": [],
                      "traineeData": [
                        {
                          "topicName": "PDA - Trainer App",
                          "issuedDate": "2020-01-08T08:53:55+0000"
                        }
                      ],
                      "trainerData": [
                        {
                          "topicName": "ಅಂತರ್ಜಲ ಚೇತನ - ಮಾದರಿ ವಿಷಯ (Antarajala Chetana - Example Topic)",
                          "issuedDate": "2021-09-02T09:30:00.000+0000"
                        }
                      ],
                      "roleTitle": [],
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "02281e30-0e4c-458d-babd-715fc3c4e388",
                  "data": {
                    "userDetailData": {
                      "userId": "02281e30-0e4c-458d-babd-715fc3c4e388",
                      "emailId": "saddam.sarif786@gmail.com",
                      "country": "India",
                      "state": "Assam",
                      "city": "Silchar",
                      "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/02281e30-0e4c-458d-babd-715fc3c4e3881a35a1a3-7f81-4b93-b98c-8b5afcc1a407",
                      "name": "Ahmad Sarif Laskar",
                      "phoneNumber": "7002795427",
                      "district": "Cachar District",
                      "programId": 27,
                      "latitude": "24.777008056640625",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "92.7929290643077",
                      "badgeDetails": [
                        {
                          "badgeId": 1674,
                          "badgeName": "Block Trainer SSA"
                        },
                        {
                          "badgeId": 1706,
                          "badgeName": "Man"
                        },
                        {
                          "badgeId": 1672,
                          "badgeName": "aztec1"
                        },
                        {
                          "badgeId": 1671,
                          "badgeName": "Assam Trainer"
                        },
                        {
                          "badgeId": 1673,
                          "badgeName": "aztec2"
                        },
                        {
                          "badgeId": 1672,
                          "badgeName": "aztec1"
                        },
                        {
                          "badgeId": 1673,
                          "badgeName": "aztec2"
                        }
                      ],
                      "traineeData": [
                        {
                          "topicName": "Execution of the JalShala",
                          "issuedDate": "2023-06-08T09:21:56.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": null,
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "2fac0fb6-ae1c-4ac0-a53d-df0a9d98d176",
                  "data": {
                    "userDetailData": {
                      "userId": "2fac0fb6-ae1c-4ac0-a53d-df0a9d98d176",
                      "emailId": "",
                      "country": "India",
                      "state": "Karnataka",
                      "city": "Bengaluru",
                      "photo": "",
                      "name": "Aditya Maruvada",
                      "phoneNumber": "8095801505",
                      "district": "Bengaluru Urban District",
                      "programId": 27,
                      "latitude": "12.94336",
                      "userRole": [
                        null,
                        "TRAINEE"
                      ],
                      "longitude": "77.627392",
                      "badgeDetails": [
                        {
                          "badgeId": 1674,
                          "badgeName": "Block Trainer SSA"
                        },
                        {
                          "badgeId": 1672,
                          "badgeName": "aztec1"
                        },
                        {
                          "badgeId": 1673,
                          "badgeName": "aztec2"
                        },
                        {
                          "badgeId": 1671,
                          "badgeName": "Assam Trainer"
                        },
                        {
                          "badgeId": 211,
                          "badgeName": "Expert Hindi Trainers"
                        },
                        {
                          "badgeId": 1706,
                          "badgeName": "Man"
                        }
                      ],
                      "traineeData": [
                        {
                          "topicName": "PDA Hand-on",
                          "issuedDate": "2023-08-17T11:11:22.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": null,
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "4f8fe22c-8dae-468f-b003-f41e1abdf485",
                  "data": {
                    "userDetailData": {
                      "userId": "4f8fe22c-8dae-468f-b003-f41e1abdf485",
                      "emailId": "",
                      "country": "India",
                      "state": "Assam",
                      "city": "Karimganj",
                      "photo": "",
                      "name": "APU SUKLA",
                      "phoneNumber": "9859479423",
                      "district": "Karimganj District",
                      "programId": 27,
                      "latitude": "24.8671652",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "92.3481865",
                      "badgeDetails": [
                        {
                          "badgeId": 1674,
                          "badgeName": "Block Trainer SSA"
                        },
                        {
                          "badgeId": 1671,
                          "badgeName": "Assam Trainer"
                        },
                        {
                          "badgeId": 1706,
                          "badgeName": "Man"
                        },
                        {
                          "badgeId": 1672,
                          "badgeName": "aztec1"
                        }
                      ],
                      "traineeData": [
                        {
                          "topicName": "Execution of the JalShala",
                          "issuedDate": "2023-05-29T10:57:02.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": null,
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "e348c20d-c7e1-460f-9ec7-15f268dc9d11",
                  "data": {
                    "userDetailData": {
                      "userId": "e348c20d-c7e1-460f-9ec7-15f268dc9d11",
                      "emailId": "NA",
                      "country": "India",
                      "state": "Assam",
                      "city": "Binnakandi Part 2",
                      "photo": "",
                      "name": "Sayan Das",
                      "phoneNumber": "NA",
                      "district": "Cachar District",
                      "programId": 27,
                      "latitude": "24.7872127870802",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "92.9872071548686",
                      "badgeDetails": [],
                      "traineeData": [
                        {
                          "topicName": "Execution of the JalShala",
                          "issuedDate": "2023-06-08T09:22:57.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": null,
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "7e5a3a66-53b3-4a9e-a4af-da77b6e53614",
                  "data": {
                    "userDetailData": {
                      "userId": "7e5a3a66-53b3-4a9e-a4af-da77b6e53614",
                      "emailId": "",
                      "country": "India",
                      "state": "Maharashtra",
                      "city": "Pune",
                      "photo": "",
                      "name": "Sachin",
                      "phoneNumber": "8097098187",
                      "district": "Pune District",
                      "programId": 27,
                      "latitude": "18.5110038",
                      "userRole": [
                        null,
                        "TRAINEE"
                      ],
                      "longitude": "73.7903484",
                      "badgeDetails": [
                        {
                          "badgeId": 407,
                          "badgeName": "BFT"
                        }
                      ],
                      "traineeData": [
                        {
                          "topicName": "Well Monitoring - Example",
                          "issuedDate": "2022-11-29T10:08:33.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": null,
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "fa1e92ec-093e-4b52-b2df-8f16d10203e2",
                  "data": {
                    "userDetailData": {
                      "userId": "fa1e92ec-093e-4b52-b2df-8f16d10203e2",
                      "emailId": "jampanitulasidevi@gmail.com",
                      "country": "India",
                      "state": "Karnataka",
                      "city": "Chintamani",
                      "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/WhatsApp Image 2022-06-09 at 11.33.32 AM.jpeg",
                      "name": "J Tulasi Devi",
                      "phoneNumber": "9441941921",
                      "district": "Chikkaballapur District",
                      "programId": 27,
                      "latitude": "13.400853",
                      "userRole": [
                        "TRAINER",
                        "TRAINEE"
                      ],
                      "longitude": "78.053478",
                      "badgeDetails": [],
                      "traineeData": [
                        {
                          "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for         r different groups_BFTs_GKMs",
                          "issuedDate": "2022-12-21T10:00:31.000+0000"
                        }
                      ],
                      "trainerData": [
                        {
                          "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ   ಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
                          "issuedDate": "2022-12-21T12:40:00.000+0000"
                        }
                      ],
                      "roleTitle": null,
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "3ca8d21c-b8f7-412b-85d0-77aa2d49a81a",
                  "data": {
                    "userDetailData": {
                      "userId": "3ca8d21c-b8f7-412b-85d0-77aa2d49a81a",
                      "emailId": "prakashlambi24@gmail.com",
                      "country": "India",
                      "state": "Karnataka",
                      "city": "Davanagere",
                      "photo": "",
                      "name": "Prakash Lambi",
                      "phoneNumber": "9620658996",
                      "district": "Davanagere District",
                      "programId": 27,
                      "latitude": "14.4369283",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "75.922325",
                      "badgeDetails": [],
                      "traineeData": [
                        {
                          "topicName": "ವಿವಿಧ ಗುಂಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for d         different groups_BFTs_GKMs",
                          "issuedDate": "2022-12-21T10:00:38.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": null,
                      "qualificationTitle": []
                    }
                  }
                },
                {
                  "practitionerId": "2cbf38a3-d533-4451-9b58-f523baba5fe5",
                  "data": {
                    "userDetailData": {
                      "userId": "2cbf38a3-d533-4451-9b58-f523baba5fe5",
                      "emailId": "",
                      "country": "India",
                      "state": "Karnataka",
                      "city": "Bengaluru",
                      "photo": "",
                      "name": "Abhi",
                      "phoneNumber": "9448176887",
                      "district": "Bengaluru Urban District",
                      "programId": 27,
                      "latitude": "13.1044196",
                      "userRole": [
                        "TRAINEE"
                      ],
                      "longitude": "77.568333",
                      "badgeDetails": [
                        {
                          "badgeId": 46,
                          "badgeName": "Npali Trns"
                        },
                        {
                          "badgeId": 53,
                          "badgeName": "Nepali Master Trainers"
                        },
                        {
                          "badgeId": 154,
                          "badgeName": "Water Expert"
                        },
                        {
                          "badgeId": 19,
                          "badgeName": "Support Solution Team"
                        },
                        {
                          "badgeId": 34,
                          "badgeName": "Nepali Trainers"
                        },
                        {
                          "badgeId": 189,
                          "badgeName": "Jal Mirta"
                        }
                      ],
                      "traineeData": [
                        {
                          "topicName": "Scripts Review | T1.02 | DG",
                          "issuedDate": "2020-12-02T11:40:31.000+0000"
                        },
                        {
                          "topicName": "Crop Water Budgeting",
                          "issuedDate": "2020-12-15T10:36:02.000+0000"
                        },
                        {
                          "topicName": "PDA Dash Board checks",
                          "issuedDate": "2020-12-02T08:57:08.000+0000"
                        }
                      ],
                      "trainerData": [],
                      "roleTitle": [],
                      "qualificationTitle": []
                    }
                  }
                },
                
                  {
                    "practitionerId": "c0072a31-91df-48a0-b79e-5d19c7d29d27",
                    "data": {
                      "userDetailData": {
                        "userId": "c0072a31-91df-48a0-b79e-5d19c7d29d27",
                        "emailId": "soumya@socion.io",
                        "country": "India",
                        "state": "Chhattisgarh",
                        "city": "Raipur",
                        "photo": "",
                        "name": "Soumya Ranjan",
                        "phoneNumber": "8249993233",
                        "district": "Raipur District",
                        "programId": 27,
                        "latitude": "21.256567",
                        "userRole": [
                          "TRAINEE"
                        ],
                        "longitude": "81.636232",
                        "badgeDetails": [],
                        "traineeData": [
                          {
                            "topicName": "Virtual Interactions - Best Practices - Smitha Murthy",
                            "issuedDate": "2020-10-14T06:59:28.000+0000"
                          },
                          {
                            "topicName": "Virtual Training Format",
                            "issuedDate": "2020-10-14T06:56:55.000+0000"
                          },
                          {
                            "topicName": "Virtual Interactions - Needs and Benefits",
                            "issuedDate": "2020-10-14T05:52:46.000+0000"
                          },
                          {
                            "topicName": "Guided Mentoring Format",
                            "issuedDate": "2020-10-14T06:49:28.000+0000"
                          },
                          {
                            "topicName": "Virtual Interactions - Needs and Benefits",
                            "issuedDate": "2020-11-04T10:07:06.000+0000"
                          },
                          {
                            "topicName": "Guided Mentoring Format",
                            "issuedDate": "2020-11-04T10:39:04.000+0000"
                          }
                        ],
                        "trainerData": [],
                        "roleTitle": [],
                        "qualificationTitle": []
                      }
                    }
                  },
                  {
                    "practitionerId": "0a59ae48-1c58-4c3f-9d94-5339ab7c0944",
                    "data": {
                      "userDetailData": {
                        "userId": "0a59ae48-1c58-4c3f-9d94-5339ab7c0944",
                        "emailId": "",
                        "country": "India",
                        "state": "Jharkhand",
                        "city": "Khuntitola",
                        "photo": "https://socion-prod.s3.amazonaws.com/profile-picture%2F0a59ae48-1c58-4c3f-9d94-5339ab7c0944.jpg",
                        "name": "Rakesh kumar",
                        "phoneNumber": "9504918417",
                        "district": "Khunti District",
                        "programId": 27,
                        "latitude": "23.0600406",
                        "userRole": [
                          "TRAINEE"
                        ],
                        "longitude": "85.2707726",
                        "badgeDetails": [],
                        "traineeData": [
                          {
                            "topicName": "PDA Hands-on",
                            "issuedDate": "2021-03-16T08:21:47.000+0000"
                          }
                        ],
                        "trainerData": [],
                        "roleTitle": [],
                        "qualificationTitle": []
                      }
                    }
                  },
                  {
                    "practitionerId": "e3867d63-96d5-4a20-9b9a-844063372dd5",
                    "data": {
                      "userDetailData": {
                        "userId": "e3867d63-96d5-4a20-9b9a-844063372dd5",
                        "emailId": "khadar.1686@gmail.com",
                        "country": "India",
                        "state": "Karnataka",
                        "city": "Raichur",
                        "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/e3867d63-96d5-4a20-9b9a-844063372dd55496f1d2-c38f-40ed-a689-7b3236d4c714",
                        "name": "Khadar Basha",
                        "phoneNumber": "8088176313",
                        "district": "Raichur District",
                        "programId": 27,
                        "latitude": "16.2047372",
                        "userRole": [
                          "TRAINEE"
                        ],
                        "longitude": "77.3412965",
                        "badgeDetails": [
                          {
                            "badgeId": 1674,
                            "badgeName": "Block Trainer SSA"
                          },
                          {
                            "badgeId": 1706,
                            "badgeName": "Man"
                          },
                          {
                            "badgeId": 1671,
                            "badgeName": "Assam Trainer"
                          },
                          {
                            "badgeId": 1672,
                            "badgeName": "aztec1"
                          },
                          {
                            "badgeId": 1673,
                            "badgeName": "aztec2"
                          }
                        ],
                        "traineeData": [
                          {
                            "topicName": "ವಿವಿಧ ಗುಂಪ ಪುಗಳಿಗೆ ಜಲ ಸಂಜೀವಿನಿ ಕುರಿತು ಒರಿಯೆಂಟೇಶನ್_BFTs_GKMs/ Orientation on Jala Sanjeevini for different groups_BFTs_GKMs",
                            "issuedDate": "2022-12-21T10:02:57.000+0000"
                          }
                        ],
                        "trainerData": [],
                        "roleTitle": null,
                        "qualificationTitle": []
                      }
                    }
                  },
                  {
                    "practitionerId": "0b7a3d60-9092-40ff-9c4b-73daede8e2b9",
                    "data": {
                      "userDetailData": {
                        "userId": "0b7a3d60-9092-40ff-9c4b-73daede8e2b9",
                        "emailId": "nidhi@socion.io",
                        "country": "India",
                        "state": "Telangana",
                        "city": "Hyderabad",
                        "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/0b7a3d60-9092-40ff-9c4b-73daede8e2b98b908d46-8963-4745-b248-a247f9e3b1c8",
                        "name": "Nidhi Niharika Socion",
                        "phoneNumber": "8080653700",
                        "district": "Hyderabad District",
                        "programId": 27,
                        "latitude": "17.4128880000001",
                        "userRole": [
                          "TRAINEE"
                        ],
                        "longitude": "78.4778680000001",
                        "badgeDetails": [],
                        "traineeData": [
                          {
                            "topicName": "PDA - Program App",
                            "issuedDate": "2021-04-05T10:40:59.000+0000"
                          }
                        ],
                        "trainerData": [],
                        "roleTitle": [],
                        "qualificationTitle": [
                          "Qual 5",
                          "Qual8"
                        ]
                      }
                    }
                  },
                  {
                    "practitionerId": "bc2d9734-3b59-4c4d-a8c2-9e543180c372",
                    "data": {
                      "userDetailData": {
                        "userId": "bc2d9734-3b59-4c4d-a8c2-9e543180c372",
                        "emailId": "pavan@yopmail.com",
                        "country": "India",
                        "state": "Karnataka",
                        "city": "Bengaluru",
                        "photo": "https://socion-prod.s3.ap-south-1.amazonaws.com/profile-picture/bc2d9734-3b59-4c4d-a8c2-9e543180c372e8b4d2d4-9c91-4a09-b029-3b1a537ab6a3",
                        "name": "Pavan The",
                        "phoneNumber": "9731115666",
                        "district": "Bengaluru Urban District",
                        "programId": 27,
                        "latitude": "12.8822382",
                        "userRole": [
                          "TRAINEE"
                        ],
                        "longitude": "77.5799022",
                        "badgeDetails": [],
                        "traineeData": [
                          {
                            "topicName": "PDA - Participant App",
                            "issuedDate": "2019-12-26T11:27:23+0000"
                          }
                        ],
                        "trainerData": [],
                        "roleTitle": [],
                        "qualificationTitle": [
                          "Btech"
                        ]
                      }
                    }
                  },
                  {
                    "practitionerId": "8610e640-1fe5-43f1-8552-f0964cc48dc4",
                    "data": {
                      "userDetailData": {
                        "userId": "8610e640-1fe5-43f1-8552-f0964cc48dc4",
                        "emailId": "rk_kalluri@yahoo.com",
                        "country": "India",
                        "state": "Karnataka",
                        "city": "Bengaluru",
                        "photo": "https://socion-prod.s3.amazonaws.com/profile-picture/8610e640-1fe5-43f1-8552-f0964cc48dc4.JPG",
                        "name": "Ramakrishna Kalluri",
                        "phoneNumber": "9900221154",
                        "district": "Bengaluru Urban District",
                        "programId": 27,
                        "latitude": "12.874412536621094",
                        "userRole": [
                          "TRAINER",
                          "TRAINEE"
                        ],
                        "longitude": "77.59061321365782",
                        "badgeDetails": [],
                        "traineeData": [
                          {
                            "topicName": "PDA - Trainer App",
                            "issuedDate": "2020-01-08T08:53:55+0000"
                          }
                        ],
                        "trainerData": [
                          {
                            "topicName": "ಅಂತರ್ಜಲ ಚೇತನ - ಮಾದರಿ ವಿಷಯ (Antarajala Chetana - Example Topic)",
                            "issuedDate": "2021-09-02T09:30:00.000+0000"
                          }
                        ],
                        "roleTitle": [],
                        "qualificationTitle": []
                      }
                    }
                  }
                ]           
                
  
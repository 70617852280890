import React from 'react';

const Ping = () => {
  return (
    <div>
      pong
    </div>
  );
};

export default Ping;
